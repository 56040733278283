let defaultState = {
  availableCompanies: [],
};

const availableCompanies = (state = defaultState, action) => {
  if (action.type === "SET_SEARCH_AVAILABLE_COMPANIES") {
    return {
      ...state,
      availableCompanies: action.payload,
    };
  } else {
    return { ...state };
  }
};

export default availableCompanies;
