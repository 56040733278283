import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import "./compoundUnitsPrices.css";
//-------------------------------------------------------------------------------------//
const CompoundUnitsPrices = ({ compoundUnitsPrices, id }) => {
  //----------------------------------------------------------------------------------//
  const english = useSelector((state) => state.english.active);
  //----------------------------------------------------------------------------------//
  function getTagName(tag) {
    if (!english) {
      switch (tag) {
        case "Duplex Villas":
          return "فيلات دوبلكس";
        case "Apartments":
          return "شقق سكنية";
        case "Detached Villas":
          return "فيلات منفصلة";
        case "Twin Houses":
          return "توين هاوس";
        case "Studios":
          return "ستوديوهات";
        case "Hotel Units":
          return "وحدات فندقية";
        default:
          return "";
      }
    } else return tag;
  }
  //----------------------------------------------------------------------------------//
  return (
    <div className="compound-units-prices-section-wrapper mt-5">
      <div className={english ? "compound-units-prices-section-inner" : "compound-units-prices-section-inner-arabic"}>
        <h1 className="text-darkblue text-bold">{english ? "Compound Units Prices" : "أسعار وحدات الكمبوند"}</h1>
        <div className={english ? "compound-units-prices-wrapper" : "compound-units-prices-wrapper-arabic"}>
          {compoundUnitsPrices.map((elem, index) => (
            <div key={index} className="m-1">
              <Link to={`/compound/${elem.tag.toLowerCase()}/${id}`}>
                <img src={elem.image} className="clickable" width="120" height="120" alt=""></img>
              </Link>
              <Link to={`/compound/${elem.tag.toLowerCase()}/${id}`} className="text-darkblue text-bold underline-none">
                <h5>{getTagName(elem.tag)}</h5>
              </Link>
              <p className="text-secondary text-center">
                {english ? "Prices start from" : "الأسعار تتراوح بين"}
                <br></br>
                {english && (
                  <>
                    {elem.min}
                    {elem.type}
                  </>
                )}{" "}
                {!english && (
                  <>
                    {elem.min?.toLocaleString("ar-u-nu-arab")} {elem.typeArabic}{" "}
                  </>
                )}
                {english ? "To" : "الى"}{" "}
                {english && (
                  <>
                    {elem.max}
                    {elem.type}
                  </>
                )}
                {!english && (
                  <>
                    {elem.max?.toLocaleString("ar-u-nu-arab")} {elem.typeArabic}{" "}
                  </>
                )}
                <br></br>
                {english ? " EGP" : "جنيه مصري"}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CompoundUnitsPrices;
