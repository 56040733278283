import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import "./mallUnitsPrices.css";
//-------------------------------------------------------------------------------------//
const MallUnitsPrices = ({ mallUnitsPrices, id }) => {
  //----------------------------------------------------------------------------------//
  const english = useSelector((state) => state.english.active);
  //----------------------------------------------------------------------------------//
  function getTagName(tag) {
    if (!english) {
      switch (tag) {
        case "Commercial":
          return "تجاري";
        case "Medical":
          return "طبي";
        case "Administrative":
          return "اداري";
        case "Hotel":
          return "فندقي";
        default:
          return "";
      }
    } else return tag;
  }
  //----------------------------------------------------------------------------------//
  return (
    <div className="mall-units-prices-section-wrapper mt-5">
      <div className={english ? "mall-units-prices-section-inner" : "mall-units-prices-section-inner-arabic"}>
        <h1 className="text-darkblue text-bold">{english ? "Mall Units Prices" : "أسعار وحدات المول"}</h1>
        <div className={english ? "mall-units-prices-wrapper" : "mall-units-prices-wrapper-arabic"}>
          {mallUnitsPrices.map((elem, index) => (
            <div key={index} className="m-1">
              <Link to={`/mall/${elem.tag.toLowerCase()}/${id}`}>
                <img src={elem.image} className="clickable" alt=""></img>
              </Link>

              <Link to={`/mall/${elem.tag.toLowerCase()}/${id}`} className="text-darkblue text-bold underline-none">
                <h5>{getTagName(elem.tag)}</h5>
              </Link>
              <p className="text-secondary text-center">
                {english ? "Prices start from" : "الأسعار تتراوح بين"}
                <br></br>
                {english && (
                  <>
                    {elem.min}
                    {elem.type}{" "}
                  </>
                )}
                {!english && (
                  <>
                    {elem.min?.toLocaleString("ar-u-nu-arab")} {elem.typeArabic}{" "}
                  </>
                )}
                {english ? "To" : "الى"}{" "}
                {english && (
                  <>
                    {elem.max}
                    {elem.type}
                  </>
                )}
                {!english && (
                  <>
                    {elem.max?.toLocaleString("ar-u-nu-arab")} {elem.typeArabic}{" "}
                  </>
                )}
                <br></br>
                {english ? " EGP" : "جنيه مصري"}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MallUnitsPrices;
