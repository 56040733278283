import React from "react";
import { useSelector } from "react-redux";
import "./locationMainFeatures.css";
//---------------------------------------------------------------------------------------------//
const LocationMainFeatures = ({ mainFeaturesImage, intro, mainFeatures }) => {
  //---------------------------------------------------------------------------------//
  const english = useSelector((state) => state.english.active);
  //---------------------------------------------------------------------------------//
  return (
    <div className="location-main-features-section-wrapper mt-5">
      <div className="location-main-features-section-inner">
        <div className="location-main-features-left">
          <img src={mainFeaturesImage} alt=""></img>
        </div>
        <div
          className={
            english
              ? "location-main-features-right"
              : "location-main-features-right-arabic"
          }
        >
          {english && (
            <>
              <h1 className="text-bold text-darkblue">What About Location's</h1>
              <h1 className="text-bold text-darkblue">Main Features</h1>
            </>
          )}
          {!english && (
            <>
              <h1 className="text-bold text-darkblue">ماذا عن مميزات</h1>
              <h1 className="text-bold text-darkblue">الموقع الرئيسية</h1>
            </>
          )}
          <p className="location-main-features-right-intro text-secondary">
            {intro} <br></br>{" "}
            {english ? "It will include:" : ": وسوف تحتوي على"}
          </p>
          <div>
            {mainFeatures.map((elem) => (
              <div
                className={
                  english
                    ? "location-main-features-right-list-item"
                    : "location-main-features-right-list-item-arabic"
                }
              >
                <p className="location-main-features-right-list-item-text text-secondary">
                  <i
                    className={
                      english
                        ? "custom-badge-blue fa fa-check text-primary me-2"
                        : "custom-badge-blue fa fa-check text-primary ms-2"
                    }
                  ></i>
                  {elem}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LocationMainFeatures;
