import React from "react";
import { useSelector } from "react-redux";
import "./compoundMainFeatures.css";
//---------------------------------------------------------------------------------------------//
const CompoundMainFeatures = ({
  name,
  mainFeaturesImage,
  intro,
  importantAreas,
}) => {
  //----------------------------------------------------------------------------------//
  const english = useSelector((state) => state.english.active);
  //----------------------------------------------------------------------------------//
  return (
    <div className="compound-main-features-section-wrapper mt-5">
      <div
        className={
          english
            ? "compound-main-features-section-inner"
            : "compound-main-features-section-inner-arabic"
        }
      >
        <div className="compound-main-features-left">
          <img src={mainFeaturesImage} alt=""></img>
        </div>
        <div
          className={
            english
              ? "compound-main-features-right"
              : "compound-main-features-right-arabic"
          }
        >
          {english && (
            <>
              <h1 className="text-bold text-darkblue">About {name},</h1>
              <h1 className="text-bold text-darkblue">History & Features</h1>
            </>
          )}
          {!english && (
            <>
              <h1 className="text-bold text-darkblue">نبذة عن تاريخ وخصائص</h1>
              <h1 className="text-bold text-darkblue">{name}</h1>
            </>
          )}
          <p className="compound-main-features-right-intro text-secondary">
            {intro} <br></br>{" "}
            {english
              ? "It's close to many important areas:"
              : ":يقع بالقرب من مناطق هامة"}
          </p>
          <div className="compound-main-features-right-list">
            {importantAreas.map((elem, index) => (
              <div
                key={index}
                className={
                  english
                    ? "compound-main-features-right-list-item"
                    : "compound-main-features-right-list-item-arabic"
                }
              >
                <p className="compound-main-features-right-list-item-text text-secondary">
                  <i
                    className={
                      english
                        ? "custom-badge-blue fa fa-check text-primary me-2"
                        : "custom-badge-blue fa fa-check text-primary ms-2"
                    }
                  ></i>
                  {elem}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompoundMainFeatures;
