import coffeeShops from "../assets/investmentProperties/coffeeShops.svg";
import restaurants from "../assets/investmentProperties/restaurants.svg";
import shops from "../assets/investmentProperties/shops.svg";
import offices from "../assets/investmentProperties/administrativeOffices.png";
import partitions from "../assets/investmentProperties/partitions.svg";
import pharmacies from "../assets/investmentProperties/pharmacies.svg";
import clinics from "../assets/investmentProperties/clinics.png";
import hotels from "../assets/investmentProperties/hotels.svg";
//---------------------------------------------------------------------------------//
let defaultState = {
  investmentPropertiesList: [
    { category: "coffee shops", source: coffeeShops, results: 200 },
    { category: "restaurants", source: restaurants, results: 40 },
    { category: "shops", source: shops, results: 560 },
    { category: "offices", source: offices, results: 123 },
    { category: "partitions", source: partitions, results: 500 },
    { category: "pharmacies", source: pharmacies, results: 40 },
    { category: "clinics", source: clinics, results: 60 },
    { category: "hotel units", source: hotels, results: 201 },
  ],
};

const investmentPropertiesList = (state = defaultState, action) => {
  if (action.type === "UPDATE_HOME") {
    // action.payload.investment_properties_list
    let payload = action.payload.investmentPropertiesList;
    payload.forEach((item) => (item.source = ""));
    // eslint-disable-next-line array-callback-return
    payload.map((item) => {
      if (item.category === "coffee shops") {
        item.source = coffeeShops;
      } else if (item.category === "restaurants") {
        item.source = restaurants;
      } else if (item.category === "shops") {
        item.source = shops;
      } else if (item.category === "offices") {
        item.source = offices;
      } else if (item.category === "partitions") {
        item.source = partitions;
      } else if (item.category === "pharmacies") {
        item.source = pharmacies;
      } else if (item.category === "clinics") {
        item.source = clinics;
      } else if (item.category === "hotel units") {
        item.source = hotels;
      }
    });
    return { ...state, investmentPropertiesList: payload };
  } else {
    return { ...state };
  }
};

export default investmentPropertiesList;
