import { useSelector } from "react-redux";
import SearchFilters from "./SubComponents/searchFilters";
import CardItem from "../../../CardItem/cardItem";
import { Spinner } from "react-bootstrap";
import "./searchResults.css";
//----------------------------------------------------------------------------------------------//
const SearchResults = ({ viewPropertyDetails, searchResults, availableLocations }) => {
  //--------------------------------------------------------------------------------------------//
  const loading = useSelector((state) => state.searchResults.loading);
  //--------------------------------------------------------------------------------------------//
  const english = useSelector((state) => state.english.active);
  //--------------------------------------------------------------------------------------------//
  return (
    <div className="custom-search-results-wrapper mt-5">
      {/* ------------------------------------------Navbar---------------------------------------- */}
      {/* ---------------------------------------------------------------------------------------- */}
      {/* ---------------------------------------------------------------------------------------- */}
      <nav
        className={
          english ? "navbar navbar-primary bg-light custom-search-results-badge" : "navbar navbar-primary bg-light custom-search-results-badge-arabic"
        }
      >
        <div className="container-fluid">
          <h1 className="text-darkblue text-bold">{english ? "Search Results" : "نتائج البحث"}</h1>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasDarkNavbar"
            aria-controls="offcanvasDarkNavbar"
          >
            {english ? "Filter" : "استخدم فلتر"}
            <i className="fa fa-sliders mx-2"></i>
          </button>
          <div
            className="offcanvas offcanvas-end text-bg-light"
            data-bs-scroll="true"
            tabindex="-1"
            id="offcanvasDarkNavbar"
            aria-labelledby="offcanvasDarkNavbarLabel"
          >
            <div className="offcanvas-header">
              <h5 className="offcanvas-title" id="offcanvasDarkNavbarLabel">
                {english ? "Filters" : "محركات البحث"}
              </h5>
              <button type="button" className="btn-close btn-close-dark" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <div className="offcanvas-body">
              <SearchFilters availableLocations={availableLocations} />
            </div>
          </div>
        </div>
      </nav>
      {/* ----------------------------------------Results----------------------------------------- */}
      {/* ---------------------------------------------------------------------------------------- */}
      {/* ---------------------------------------------------------------------------------------- */}
      {!loading && (
        <>
          {searchResults.length > 0 && (
            <div className="custom-pagination">
              <div className={english ? "custom-search-results-container" : "custom-search-results-container-arabic"}>
                {searchResults.map((element, index) => (
                  <CardItem
                    index={index} //used in view-details-modal
                    key={element.id}
                    id={element.id}
                    name={element.name}
                    nameArabic={element.nameArabic}
                    projectName={element.projectName}
                    projectType={element.projectType}
                    projectId={element.projectId}
                    unitImage={element.unitImage}
                    projectImage={element.projectImage}
                    blockNumber={element.blockNumber}
                    propertyCAD={element.propertyCAD}
                    buildingFloors={element.buildingFloors}
                    propertyFloor={element.propertyFloor}
                    tags={element.tags}
                    address={element.address}
                    addressArabic={element.addressArabic}
                    distance={element.distance}
                    area={element.area}
                    pricePerMeter={element.pricePerMeter}
                    totalPrice={element.totalPrice}
                    paymentSchedule={element.paymentSchedule}
                    implementingCompanyLogo={element.implementingCompanyLogo}
                    type={element.type}
                    category={element.category}
                    status={element.status}
                    viewDetails="true"
                    viewPropertyDetails={viewPropertyDetails}
                  />
                ))}
              </div>
              <div className="text-center m-4">
                <button className={english ? "btn btn-primary btn-lg show-more-button" : "btn btn-primary btn-lg show-more-button-arabic"}>
                  {english ? "Show More" : "اعرض المزيد"}
                  <i className="fa fa-angle-double-down angle-double-text m-2"></i>
                </button>
              </div>
            </div>
          )}
          {searchResults.length === 0 && <h3 className="text-darkblue">{english ? "No units found" : "لا توجد وحدات"}</h3>}
        </>
      )}
      {/* ----------------------------------------Loader------------------------------------------ */}
      {/* ---------------------------------------------------------------------------------------- */}
      {/* ---------------------------------------------------------------------------------------- */}
      {loading && (
        <div
          className="mt-5"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Spinner className="mb-2" variant="primary" />
          {<h4 className="text-darkblue">{english ? "Loading" : "جاري التحميل"}</h4>}
        </div>
      )}
    </div>
  );
};

export default SearchResults;
