import { toast } from "react-toastify";
import axios from "../axios.js";
//------------------------------//
export function deleteMyAccount(user, navigate) {
  return async (dispatch) => {
    try {
      await axios.delete(`/users/${user.id}/delete/`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });
      dispatch({
        type: "SET_USER_LOGOUT",
        payload: {},
      });
      localStorage.removeItem("capitalRefreshToken");
      toast.success("User deleted successfully");
      navigate("/");
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message || "Deletion failed");
    }
  };
}
