import React from 'react';
//-------------------------------------------------//
import ReactDOM from 'react-dom/client';
//-------------------------------------------------//
import { RouterProvider } from "react-router-dom";
// -------------------------------------------------//
import { router } from './App';
//-------------------------------------------------//
import thunk from "redux-thunk";
//-------------------------------------------------//
import { Provider } from "react-redux";
//-------------------------------------------------//
import { createStore, applyMiddleware } from "redux";
//-------------------------------------------------//
import allReducers from "./reducers/index";
//-------------------------------------------------//
import reportWebVitals from './reportWebVitals';
//-------------------------------------------------//
import "bootstrap/dist/js/bootstrap.bundle.min";
//-------------------------------------------------//
import "bootstrap/dist/css/bootstrap.min.css";
// -------------------------------------------------//
import "font-awesome/css/font-awesome.min.css";
//--------------------------------------------------//
import "react-toastify/dist/ReactToastify.css";
//-------------------------------------------------//
import "./index.css";
//-----------------------------------------------------------------------------------------//
const store = createStore(allReducers, applyMiddleware(thunk));
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
      <RouterProvider router={router} />
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
