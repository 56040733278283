import { toast } from "react-toastify";
import axios from "../axios.js";
//------------------------------//
export function updateUser(key, value, user) {
  return async (dispatch) => {
    try {
      const formData = new FormData();
      formData.append(key, value);
      const response = await axios.put(`/users/${user.id}/update/`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${user.token}`,
        },
      });
      dispatch({
        type: "UPDATE_USER",
        payload: response.data,
      });
      toast.success("User updated successfully");
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message || "Update failed");
    }
  };
}
