import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import SinglePropertyDetails from "../../singlePropertyDetails/singlePropertyDetails";
import "./mallFloorView.css";
//---------------------------------------------------------------------------------------------//
const MallFloorView = ({ mallData, activeFloors, setSelectedUnit, selectedUnit, currentFloorNumber, setCurrentFloorNumber }) => {
  //------------------------------------------------------------------------------------------------//
  const english = useSelector((state) => state.english.active);
  //------------------------------------------------------------------------------------------------//
  function handleUnitSelectionInMallFloor(e) {
    const unit = activeFloors[currentFloorNumber].units[e.currentTarget.value];
    setSelectedUnit({
      ...unit,
      propertyFloor: activeFloors[currentFloorNumber].number,
      //attributes to be added from mall object to construct shop as an investment property
      projectName: mallData.name,
      projectNameArabic: mallData.nameArabic,
      projectType: mallData.type,
      projectId: mallData.id,
      projectImage: mallData.projectImage,
      address: mallData.address,
      addressArabic: mallData.addressArabic,
      distance: mallData.distance,
      buildingFloors: mallData.buildingFloors,
    });
  }
  //------------------------------------------------------------------------------------------------//
  return (
    <Fragment>
      {/* -------------------------------------------------------------------------------- */}
      {/* ---------------------------------Mall info section------------------------------ */}
      {/* -------------------------------------------------------------------------------- */}
      <div className="mall-floor-section-wrapper mt-5">
        <div className="mall-floor-section-inner">
          {/* ------------------------------------------------------------ */}
          {/* -------------------------Mall 2D CAD------------------------ */}
          {/* ------------------------------------------------------------ */}
          <div className="mall-floor-left">
            <img src={activeFloors[currentFloorNumber].layout} alt=""></img>
            <div className="floors-slider">
              <button
                className="btn btn-link"
                disabled={currentFloorNumber === 0 ? true : false}
                onClick={() => {
                  if (currentFloorNumber > 0) {
                    setCurrentFloorNumber(currentFloorNumber - 1);
                    setSelectedUnit({});
                    document.getElementsByClassName("select-mall-floor-unit")[0].value = english ? "Select unit" : "اختر وحدة";
                  }
                }}
              >
                <i className="fa fa-chevron-left me-1"></i>
                {english ? "Previous floor" : "الطابق السابق"}
              </button>
              {english ? activeFloors[currentFloorNumber].number : activeFloors[currentFloorNumber].number?.toLocaleString("ar-u-nu-arab")}
              <button
                className="btn btn-link"
                disabled={currentFloorNumber === activeFloors.length - 1 ? true : false}
                onClick={() => {
                  if (currentFloorNumber < activeFloors.length - 1) {
                    setCurrentFloorNumber(currentFloorNumber + 1);
                    setSelectedUnit({});
                    document.getElementsByClassName("select-mall-floor-unit")[0].value = english ? "Select unit" : "اختر وحدة";
                  }
                }}
              >
                {english ? "Next floor" : "الطابق التالي"}
                <i className="fa fa-chevron-right ms-1"></i>
              </button>
            </div>
          </div>
          {/* ------------------------------------------------------------ */}
          {/* ----------------------No unit selected---------------------- */}
          {/* ------------------------------------------------------------ */}
          {!selectedUnit.id && (
            <div className="mall-floor-right">
              <div className={english ? "mall-floor-right-first-section" : "mall-floor-right-first-section-arabic"}>
                <div>
                  <h4 className="text-darkblue">{english ? "Mall Name" : "اسم المول"}</h4>
                  <h3 className="text-bold text-darkblue">{english ? mallData.name : mallData.nameArabic}</h3>
                </div>
                <Link to={`/mall/info/${mallData.id}`} className="btn btn-link">
                  <i className="fa fa-search me-2"></i>
                  {english ? "View Mall Details" : "اعرض تفاصيل عن المول"}
                </Link>
              </div>
              <div className={english ? "mall-floor-right-second-section" : "mall-floor-right-second-section-arabic"}>
                <p className="text-secondary">
                  <i className="fa fa-building text-primary me-2"></i>
                  {english ? "Floor number" : "رقم الطابق"}
                </p>
                <p className="text-bold text-dark">
                  {english ? activeFloors[currentFloorNumber].number : activeFloors[currentFloorNumber].number?.toLocaleString("ar-u-nu-arab")}
                </p>
              </div>
              <div className={english ? "mall-floor-right-third-section" : "mall-floor-right-third-section-arabic"}>
                <p className="text-secondary">
                  <i className="fa fa-pie-chart text-primary me-2"></i>
                  {english ? "Floor area" : "مساحة الطابق"}
                </p>
                <p className="text-bold text-dark">
                  {english ? activeFloors[currentFloorNumber].area : activeFloors[currentFloorNumber].area?.toLocaleString("ar-u-nu-arab")}{" "}
                  {english ? "Meter" : "متر"}
                </p>
              </div>
              <div className={english ? "mall-floor-right-fourth-section" : "mall-floor-right-fourth-section-arabic"}>
                <p className="text-secondary">
                  <i className="fa fa-star-o me-2 text-primary"></i>
                  {english ? "Floor features" : "مزايا الطابق"}
                </p>
                {english ? (
                  <p className="text-center">
                    {activeFloors[currentFloorNumber].features.map((elem, index) => (
                      <span key={index} className="text-dark text-bold">
                        {elem}
                        {index === activeFloors[currentFloorNumber].features.length - 1 ? "." : ","}
                      </span>
                    ))}
                  </p>
                ) : (
                  <p className="text-center">
                    {activeFloors[currentFloorNumber].featuresArabic.map((elem, index) => (
                      <span key={index} className="text-dark text-bold">
                        {elem}
                        {index === activeFloors[currentFloorNumber].featuresArabic.length - 1 ? "" : ", "}
                      </span>
                    ))}
                  </p>
                )}
                <p className="text-danger text-center text-bold clickable">
                  {english ? (
                    <>
                      <span>For any inquiries</span>
                      <a href="tel:+201017762142" className="btn btn-link btn-sm">
                        Call Us<i className="fa fa-phone ms-1"></i>
                      </a>
                    </>
                  ) : (
                    <>
                      <span>محتار و محتاج استشارة مجانيةّ, رن علينا و هنتصل بيك</span>
                      <p>
                        <a href="tel:+201017762142" className="btn btn-link btn-sm">
                          <i className="fa fa-phone me-1"></i>
                          اتصل بنا
                        </a>
                      </p>
                    </>
                  )}
                </p>
              </div>
            </div>
          )}
          {/* ------------------------------------------------------------ */}
          {/* -----------------------unit selected------------------------ */}
          {/* ------------------------------------------------------------ */}
          {selectedUnit.id && <SinglePropertyDetails property={selectedUnit} />}
        </div>
      </div>
      {/* -------------------------------------------------------------------------------- */}
      {/* ----------------------------------Selecting unit-------------------------------- */}
      {/* -------------------------------------------------------------------------------- */}
      <hr></hr>
      <div className="mall-floor-search-filter mt-5">
        <h4 className="text-darkblue">{english ? "Select Unit From Current Floor" : "اختر وحدة من الطابق الحالي"}</h4>
        <div>
          <select
            className="form-select select-mall-floor-unit"
            id={english ? "custom-form-select" : "custom-form-select-arabic"}
            defaultValue={english ? "Select unit" : "اختر وحدة"}
            onChange={(e) => {
              handleUnitSelectionInMallFloor(e);
            }}
          >
            <>
              <option disabled>{english ? "Select unit" : "اختر وحدة"}</option>
              {activeFloors[currentFloorNumber].units.map((unit, index) => (
                <option value={index} disabled={unit.status !== "free"} className={unit.status !== "free" ? "text-secondary" : "text-dark"}>
                  {unit.status === "free"
                    ? english
                      ? unit.name
                      : unit.nameArabic
                    : english
                    ? unit.name + "(Reserved)"
                    : unit.nameArabic + "(محجوز)"}
                </option>
              ))}
            </>
          </select>
        </div>
      </div>
    </Fragment>
  );
};

export default MallFloorView;
