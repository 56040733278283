import React, { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateUser } from "../../../../actions/updateUser";
import "./preferences.css";
//---------------------------------------------------------------------------------------------//
const Preferences = ({
  loggedUserData,
  editingCurrency,
  setEditingCurrency,
  editingLanguage,
  setEditingLanguage,
  editingAccessibility,
  setEditingAccessibility,
}) => {
  const dispatch = useDispatch();
  //---------------------------------------------------------------//
  const [currency, setCurrency] = useState(loggedUserData.preferedCurrency);
  //---------------------------------------------------------------//
  const language = useState(loggedUserData.preferedLanguage);
  //---------------------------------------------------------------//
  const english = useSelector((state) => state.english.active);
  //---------------------------------------------------------------//
  async function handleUpdate(e) {
    if (e.target.name === "currency") {
      dispatch(updateUser("preferedCurrency", currency, loggedUserData));
      setEditingCurrency(!editingCurrency);
    }
    if (e.target.name === "language") {
      dispatch(updateUser("preferedLanguage", language, loggedUserData));
      setEditingLanguage(!editingLanguage);
    }
  }
  //---------------------------------------------------------------//
  return (
    <Fragment>
      <div>
        <div className={english ? "preferences-header-wrapper" : "preferences-header-wrapper-arabic"}>
          <h1 className="text-darkblue text-bold">{english ? "Preferences" : "التفضيلات"}</h1>
          <p className="text-secondary">
            {english
              ? "Change your language, currency, and accessibility requirements."
              : "يمكنك تغيير اللغة، العملة، متطلبات الوصول"}
          </p>
        </div>
      </div>
      <div>
        {/* --------------------------------------------- */}
        {/* -------------------currency------------------ */}
        {/* --------------------------------------------- */}
        <div
          className={english ? "preferences-input-field-wrapper mb-4" : "preferences-input-field-wrapper-arabic mb-4"}
        >
          <span className="text-darkblue text-bold preferences-field-label">{english ? " Currency" : "العملة"}</span>
          <select
            disabled={!editingCurrency}
            onChange={(e) => {
              setCurrency(e.target.value);
            }}
          >
            <option selected={loggedUserData.preferedCurrency === "egp" ? true : false} value="egp">
              {english ? "Egyptian Pound (Egp)" : "جنيه مصري"}
            </option>
            <option selected={loggedUserData.preferedCurrency === "usd" ? true : false} value="usd">
              {english ? "US (Dollar)" : "دولار أمريكي"}
            </option>
          </select>
          <div>
            {!editingCurrency && (
              <button
                className="btn btn-link"
                onClick={() => {
                  setEditingCurrency(!editingCurrency);
                }}
              >
                {english && (
                  <>
                    Edit <i className="fa fa-edit"></i>
                  </>
                )}
                {!english && (
                  <>
                    <i className="fa fa-edit"></i>
                    تعديل
                  </>
                )}
              </button>
            )}
            {editingCurrency && (
              <Fragment>
                <span
                  className="text-danger me-2"
                  role="button"
                  onClick={() => {
                    setEditingCurrency(!editingCurrency);
                  }}
                >
                  {english && (
                    <>
                      Cancel <i className="fa fa-close"></i>
                    </>
                  )}
                  {!english && (
                    <>
                      <i className="fa fa-close ms-1"></i>
                      الغاء
                    </>
                  )}
                </span>
                <button
                  name="currency"
                  className="btn btn-primary btn-sm preferences-save-button"
                  onClick={(e) => {
                    handleUpdate(e);
                  }}
                >
                  {english ? "Save" : "حفظ"}
                </button>
              </Fragment>
            )}
          </div>
        </div>
        {/* --------------------------------------------- */}
        {/* ------------------language------------------- */}
        {/* --------------------------------------------- */}
        {/* <div
          className={
            english
              ? "preferences-input-field-wrapper mb-4"
              : "preferences-input-field-wrapper-arabic mb-4"
          }
        >
          <span className="text-darkblue text-bold preferences-field-label">
            {english ? "Language" : "اللغة"}
          </span>
          <select
            disabled={!editingLanguage}
            onChange={(e) => {
              setLanguage(e.target.value);
            }}
          >
            <option
              selected={
                loggedUserData.preferedLanguage === "eng" ? true : false
              }
              value="eng"
            >
              English
            </option>
            <option
              selected={loggedUserData.preferedLanguage === "ar" ? true : false}
              value="ar"
            >
              العربية
            </option>
          </select>
          <div>
            {!editingLanguage && (
              <button
                className="btn btn-link"
                onClick={() => {
                  setEditingLanguage(!editingLanguage);
                }}
              >
                {english && (
                  <>
                    Edit <i className="fa fa-edit"></i>
                  </>
                )}
                {!english && (
                  <>
                    <i className="fa fa-edit"></i>
                    تعديل
                  </>
                )}
              </button>
            )}
            {editingLanguage && (
              <Fragment>
                <span
                  className="text-danger me-2"
                  role="button"
                  onClick={() => {
                    setEditingLanguage(!editingLanguage);
                  }}
                >
                  {english && (
                    <>
                      Cancel <i className="fa fa-close"></i>
                    </>
                  )}
                  {!english && (
                    <>
                      <i className="fa fa-close ms-1"></i>
                      الغاء
                    </>
                  )}
                </span>
                <button
                  name="language"
                  className="btn btn-primary btn-sm preferences-save-button"
                  onClick={(e) => {
                    handleUpdate(e);
                  }}
                >
                  {english ? "Save" : "حفظ"}
                </button>
              </Fragment>
            )}
          </div>
        </div> */}
        {/* --------------------------------------------- */}
        {/* ----------accessibility requirements--------- */}
        {/* --------------------------------------------- */}
        {/* <div
          className={
            english
              ? "preferences-input-field-wrapper mb-4"
              : "preferences-input-field-wrapper-arabic mb-4"
          }
        >
          <span className="text-darkblue text-bold preferences-field-label">
            {english ? "Accessibility Requirements" : "متطلبات الوصول"}
          </span>
          <input
            placeholder={
              english
                ? "Find out properties that don't meet your needs"
                : "ما هي الوحدات التي لا تتناسب مع احتياجاتك"
            }
            disabled={!editingAccessibility}
          ></input>
          <div>
            {!editingAccessibility && (
              <button
                className="btn btn-link"
                onClick={() => {
                  setEditingAccessibility(!editingAccessibility);
                }}
              >
                {english && (
                  <>
                    Edit <i className="fa fa-edit"></i>
                  </>
                )}
                {!english && (
                  <>
                    <i className="fa fa-edit"></i>
                    تعديل
                  </>
                )}
              </button>
            )}
            {editingAccessibility && (
              <Fragment>
                <span
                  className="text-danger me-2"
                  role="button"
                  onClick={() => setEditingAccessibility(!editingAccessibility)}
                >
                  {english && (
                    <>
                      Cancel <i className="fa fa-close"></i>
                    </>
                  )}
                  {!english && (
                    <>
                      <i className="fa fa-close ms-1"></i>
                      الغاء
                    </>
                  )}
                </span>
                <button className="btn btn-primary btn-sm preferences-save-button">
                  {english ? "Save" : "حفظ"}
                </button>
              </Fragment>
            )}
          </div>
        </div> */}
      </div>
    </Fragment>
  );
};

export default Preferences;
