import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import DeleteUserModal from "../deleteUserModal/deleteUserModal";
import "./security.css";
//---------------------------------------------------------------------------------------------//
const Security = ({
  loggedUserData,
  editingPassword,
  setEditingPassword,
  deletingAccount,
  setDeletingAccount,
}) => {
  //---------------------------------------------------------------//
  const english = useSelector((state) => state.english.active);
  //---------------------------------------------------------------//
  return (
    <Fragment>
      {/* -------------------------------------------------------------------- */}
      <div>
        <div
          className={
            english
              ? "security-header-wrapper"
              : "security-header-wrapper-arabic"
          }
        >
          <h1 className="text-darkblue text-bold">
            {english ? "Security" : "الأمن"}
          </h1>
          <p className="text-secondary">
            {english
              ? "Adjust your security settings and set up two-factor authentication."
              : "قم بتعديل اعدادات الأمن وانشاء توثيق ذو عاملين"}
          </p>
        </div>
      </div>
      {/* -------------------------------------------------------------------- */}
      <div>
        {/* --------------------------------------------- */}
        {/* -------------------password------------------ */}
        {/* --------------------------------------------- */}
        <div
          className={
            english
              ? "security-input-field-wrapper mb-4"
              : "security-input-field-wrapper-arabic mb-4"
          }
        >
          <div
            className={
              english ? "security-field-label" : "security-field-label-arabic"
            }
          >
            <span className="text-darkblue text-bold">
              {english ? "Password" : "كلمة السر"}
            </span>
          </div>
          {!editingPassword && (
            <div
              className={
                english
                  ? "security-text-wrapper text-secondary"
                  : "security-text-wrapper-arabic text-secondary"
              }
            >
              {english && (
                <>
                  Reset Your Password Regularly To Keep<br></br>Your Account
                  Secure
                </>
              )}
              {!english && (
                <>
                  قم بتغيير كلمة السر بانتظام حتى تحتفظ
                  <br></br>بأمن و سرية حسابك
                </>
              )}
            </div>
          )}
          {editingPassword && (
            <div
              className={
                english
                  ? "security-text-wrapper text-secondary"
                  : "security-text-wrapper-arabic text-secondary"
              }
            >
              {english && (
                <>
                  To Change Your Password, We Need To<br></br>Send A Reset Link
                  To Your Email Address
                </>
              )}
              {!english && (
                <>
                  لتغيير كلمة السر، سوف نقوم بارسال
                  <br></br>رابط اعادة تعيين الى البريد الالكتروني المسجل
                </>
              )}
            </div>
          )}
          <div
            className={
              english
                ? "security-buttons-wrapper"
                : "security-buttons-wrapper-arabic"
            }
          >
            {!editingPassword && (
              <span
                role="button"
                className="text-primary"
                onClick={() => {
                  setEditingPassword(!editingPassword);
                }}
              >
                {english && (
                  <>
                    Reset <i className="fa fa-repeat"></i>
                  </>
                )}
                {!english && (
                  <>
                    <i className="fa fa-repeat me-1"></i>
                    اعادة تعيين
                  </>
                )}
              </span>
            )}
            {editingPassword && (
              <Fragment>
                <span
                  role="button"
                  className="text-danger me-2"
                  onClick={() => {
                    setEditingPassword(!editingPassword);
                  }}
                >
                  {english && (
                    <>
                      Cancel <i className="fa fa-close"></i>
                    </>
                  )}
                  {!english && (
                    <>
                      <i className="fa fa-close ms-1"></i>
                      الغاء
                    </>
                  )}
                </span>
                <span
                  role="button"
                  className="btn btn-primary send-email-button"
                  onClick={() => {}}
                >
                  {english ? "Send Email" : "ارسال بريد"}
                </span>
              </Fragment>
            )}
          </div>
        </div>
        {/* --------------------------------------------- */}
        {/* ------------------Deleting------------------- */}
        {/* --------------------------------------------- */}
        <div
          className={
            english
              ? "security-input-field-wrapper mb-4"
              : "security-input-field-wrapper-arabic mb-4"
          }
        >
          <div
            className={
              english ? "security-field-label" : "security-field-label-arabic"
            }
          >
            <span className="text-darkblue text-bold">
              {english && (
                <>
                  Delete <br></br> Account
                </>
              )}
              {!english && (
                <>
                  حذف
                  <br></br> الحساب
                </>
              )}
            </span>
          </div>
          <div
            className={
              english
                ? "security-text-wrapper text-secondary"
                : "security-text-wrapper-arabic text-secondary"
            }
          >
            {english
              ? "Permanently Delete Your capitalAgent.com Account"
              : "حذف حساب كابيتال ايجنت نهائيا"}
          </div>
          <div
            className={
              english
                ? "security-buttons-wrapper"
                : "security-buttons-wrapper-arabic"
            }
          >
            {!deletingAccount && (
              <span
                role="button"
                className="text-primary"
                onClick={() => {
                  setDeletingAccount(!deletingAccount);
                }}
              >
                {english && (
                  <>
                    Delete <i className="fa fa-trash-o"></i>
                  </>
                )}
                {!english && (
                  <>
                    <i className="fa fa-trash-o me-1"></i>
                    حذف
                  </>
                )}
              </span>
            )}
            {deletingAccount && (
              <span
                role="button"
                data-bs-toggle="modal"
                data-bs-target="#deleteUserModal"
                className="text-danger"
              >
                {english && (
                  <>
                    Delete Account <i className="fa fa-trash-o"></i>
                  </>
                )}
                {!english && (
                  <>
                    <i className="fa fa-trash-o me-1"></i>
                    حذف الحساب
                  </>
                )}
              </span>
            )}
          </div>
        </div>
      </div>
      <DeleteUserModal
        loggedUserData={loggedUserData}
        setDeletingAccount={setDeletingAccount}
      />
    </Fragment>
  );
};

export default Security;
