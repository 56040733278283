import React from "react";
import { useSelector } from "react-redux";
import "./footer.css";
const Footer = () => {
  //------------------------------------------------------------------------------------------------//
  const english = useSelector((state) => state.english.active);
  //-------------------------------------------------------------------------------------------------//
  return (
    <footer className="footer custom-footer-wrapper mt-4">
      <div className={english ? "custom-footer-inner" : "custom-footer-inner-arabic"}>
        <div>
          {english ? (
            <span className="text-darkblue">
              Copyright <i className="fa fa-copyright" aria-hidden="true"></i> {new Date().getFullYear()} Alassema
            </span>
          ) : (
            <span className="text-darkblue">
              <i className="fa fa-copyright" aria-hidden="true"></i>
              العاصمة ٢٠٢٣
            </span>
          )}
        </div>
        <div className={english ? "footer-social-icons" : "footer-social-icons-arabic"}>
          <span className="text-darkblue">
            <i className="fa fa-facebook-square me-2" aria-hidden="true"></i>
            <i className="fa fa-instagram me-2" aria-hidden="true"></i>
            <i className="fa fa-linkedin-square" aria-hidden="true"></i>
          </span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
