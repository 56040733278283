let defaultState = {
  latestProjects: [],
};

const latestProjects = (state = defaultState, action) => {
  if (action.type === "UPDATE_HOME") {
    return {
      ...state,
      latestProjects: action.payload.latestProjects,
    };
  } else {
    return { ...state };
  }
};

export default latestProjects;
