import React from "react";
import SpinnerButton from "../Spinner/spinner";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { addToFavourite } from "../../actions/favourite";
import { addToBooking } from "../../actions/booking";
import { toast } from "react-toastify";
import "./singlePropertyDetails.css";
//----------------------------------------------------------------------------------------//
const SinglePropertyDetails = ({ property }) => {
  const dispatch = useDispatch();
  //--------------------------------------------------------------------------------------//
  const user = useSelector((state) => state.user.user);
  //--------------------------------------------------------------------------------------//
  const favouriteButtonLoader = useSelector((state) => state.user.user.favouriteButtonLoader);
  //--------------------------------------------------------------------------------------//
  const bookingButtonLoader = useSelector((state) => state.user.user.bookingButtonLoader);
  //--------------------------------------------------------------------------------------//
  const location = useLocation();
  //--------------------------------------------------------------------------------------//
  const navigate = useNavigate();
  //--------------------------------------------------------------------------------------//
  const english = useSelector((state) => state.english.active);
  //--------------------------------------------------------------------------------------//
  function getProjectTypeName(type) {
    switch (type) {
      case "mall":
        return "المول";
      case "compound":
        return "الكمبوند";
      default:
        return;
    }
  }
  //--------------------------------------------------------------------------------------//
  async function handleBooking() {
    if (!user.id) {
      toast.warning("Please, login first");
    } else {
      dispatch(addToBooking(user, property));
    }
  }
  //--------------------------------------------------------------------------------------//
  async function handleFavourite() {
    if (!user.id) {
      toast.warning("Please, login first");
    } else {
      dispatch(addToFavourite(user, property));
    }
  }
  //--------------------------------------------------------------------------------------//
  return (
    <div className="property-details-right">
      {/* --------------------------------------------- */}
      <div className={english ? "first-section mb-3" : "first-section-arabic mb-3"}>
        <div>
          {english ? (
            <h5 className="text-darkblue">{property.projectType[0].toUpperCase() + property.projectType.slice(1)} Name</h5>
          ) : (
            <h5 className="text-darkblue">اسم {getProjectTypeName(property.projectType)}</h5>
          )}
          <h4 className="text-darkblue text-bold">{english ? property.projectName : property.projectNameArabic}</h4>
        </div>
        {english ? (
          <div>
            <Link
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                navigate(`/${property.projectType}/info/${property.projectId}`);
              }}
            >
              <i className="fa fa-file-text-o text-primary view-details-search-icon me-2"></i>
              View {property.projectType} Details
            </Link>
          </div>
        ) : (
          <div>
            <Link
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                navigate(`/${property.projectType}/info/${property.projectId}`);
              }}
            >
              <i className="fa fa-file-text-o text-primary view-details-search-icon me-2"></i>
              اعرض تفاصيل عن {getProjectTypeName(property.projectType)}
            </Link>
          </div>
        )}
      </div>
      {/* -----------------Block number---------------- */}
      {property.blockNumber !== undefined && (
        <div className={english ? "property-details-right-single-attribute mb-2" : "property-details-right-single-attribute-arabic mb-2"}>
          <div className="text-secondary view-details-modal-label">
            {english && <i className="fa fa-object-ungroup text-primary"></i>}
            {english ? "Block number" : "مبنى رقم"}
            {!english && <i className="fa fa-object-ungroup text-primary"></i>}
          </div>
          <div className="text-darkblue text-bold">{english ? property.blockNumber : property.blockNumberArabic}</div>
        </div>
      )}
      {/* -----------------Floor number---------------- */}
      {property.propertyFloor !== undefined && property.propertyFloor !== 0 && (
        <div className={english ? "property-details-right-single-attribute mb-2" : "property-details-right-single-attribute-arabic mb-2"}>
          <div className="text-secondary view-details-modal-label">
            {english && <i className="fa fa-building text-primary"></i>}
            {english ? "Floor number" : "طابق رقم"}
            {!english && <i className="fa fa-building text-primary"></i>}
          </div>
          <div className="text-darkblue text-bold">{english ? property.propertyFloor : property.propertyFloor?.toLocaleString("ar-u-nu-arab")}</div>
        </div>
      )}
      {/* -----------------Unit Number---------------- */}
      {property.name !== undefined && (
        <div className={english ? "property-details-right-single-attribute mb-2" : "property-details-right-single-attribute-arabic mb-2"}>
          <div className="text-secondary view-details-modal-label">
            {english && <i className="fa fa-cube text-primary"></i>}
            {english ? "Unit number" : "رقم الوحدة"}
            {!english && <i className="fa fa-cube text-primary"></i>}
          </div>
          <div className="text-darkblue text-bold">{english ? property.name : property.nameArabic}</div>
        </div>
      )}
      {/* -------------------Unit Area----------------- */}
      {property.area !== undefined && (
        <div className={english ? "property-details-right-single-attribute mb-2" : "property-details-right-single-attribute-arabic mb-2"}>
          <div className="text-secondary view-details-modal-label">
            {english && <i className="fa fa-pie-chart text-primary"></i>}
            {english ? "Unit area" : "مساحة الوحدة"}
            {!english && <i className="fa fa-pie-chart text-primary"></i>}
          </div>
          <div className="text-darkblue text-bold">
            {english ? property.area : property.area?.toLocaleString("ar-u-nu-arab")} {english ? "Meter" : "متر"}
          </div>
        </div>
      )}
      {/* ----------------Price/Meter------------------ */}
      {property.pricePerMeter !== undefined && (
        <div className={english ? "property-details-right-single-attribute mb-2" : "property-details-right-single-attribute-arabic mb-2"}>
          <div className="text-secondary view-details-modal-label">
            {english && <i className="fa fa-tag text-primary"></i>}
            {english ? "Price/meter" : "سعر المتر"}
            {!english && <i className="fa fa-tag text-primary"></i>}
          </div>
          <div className="text-darkblue text-bold">
            {english ? property.pricePerMeter : property.pricePerMeter?.toLocaleString("ar-u-nu-arab")} {english ? "EGP" : "جنيه مصري"}
          </div>
        </div>
      )}
      {/* ----------------Total Price------------------ */}
      {property.totalPrice !== undefined && (
        <div className={english ? "property-details-right-single-attribute mb-2" : "property-details-right-single-attribute-arabic mb-2"}>
          <div className="text-secondary view-details-modal-label">
            {english && <i className="fa fa-money text-primary "></i>}
            {english ? "Total price" : "السعر الكلي"}
            {!english && <i className="fa fa-money text-primary "></i>}
          </div>
          <div className="text-darkblue text-bold">
            {english ? property.totalPrice : property.totalPrice?.toLocaleString("ar-u-nu-arab")} {english ? "EGP" : "جنيه مصري"}
          </div>
        </div>
      )}
      {/* ----------------Confirm button--------------- */}
      {!location.pathname.includes("/mall/") && !location.pathname.includes("/compound/") && !location.pathname.includes("/search") && (
        <div className="d-grid gap-2 mt-2">
          <button className="btn btn-primary btn-lg" data-bs-dismiss="modal">
            {english ? "Confirm" : "تأكيد"}
          </button>
        </div>
      )}
      {/* ----------------Book button--------------- */}
      {(location.pathname.includes("/mall/") || location.pathname.includes("/compound/") || location.pathname.includes("/search")) && (
        <div className="d-grid gap-2 mt-2">
          <button className="btn btn-primary btn-lg" onClick={handleBooking}>
            {bookingButtonLoader && <SpinnerButton />}
            {english ? "Book Now" : "احجز الآن"}
          </button>
          <button className="btn btn-primary btn-lg" onClick={handleFavourite}>
            {favouriteButtonLoader && <SpinnerButton />}
            {english ? "Add to favourites" : "اضف الى القائمة المفضلة"}
          </button>
        </div>
      )}
    </div>
  );
};

export default SinglePropertyDetails;
