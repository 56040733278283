import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import Carousel from "react-bootstrap/Carousel";
import Slide from "./slide";
import slide1 from "../../../../assets/homePage/1.png";
import slide2 from "../../../../assets/homePage/2.jfif";
import slide3 from "../../../../assets/homePage/3.png";
import slide4 from "../../../../assets/homePage/4.jfif";
import "./carousel.css";
//-------------------------------------------------------------------------------------------------//
const CarouselComponent = () => {
  //-------------------------------------------------------------------------------------------------//
  const [current_slide, set_current_slide] = useState(1);
  //-------------------------------------------------------------------------------------------------//
  const english = useSelector((state) => state.english.active);
  //-------------------------------------------------------------------------------------------------//
  const text = english ? (
    <p className="text-light mb-3">
      Your Best opportunity<br></br>
      in New capital city<br></br>
      with Alassema
    </p>
  ) : (
    <p className="text-light mb-3">
      أفضل فرصة لك <br></br>
      في العاصمة الجديدة<br></br>
      مع العاصمة <br></br>
    </p>
  );
  //-------------------------------------------------------------------------------------------------//
  const contact = english ? (
    <p className="text-light mb-3">
      Contact Us Now<br></br>
      in New capital city<br></br>
      with Alassema
    </p>
  ) : (
    <p className="text-light mb-3">
      اتصل بنا الآن <br></br>
      في العاصمة الجديدة<br></br>
      مع العاصمة <br></br>
    </p>
  );
  //-------------------------------------------------------------------------------------------------//
  const exploreButton = (
    <Link to="/investment/search/coffee shops" className="custom-carousel-button btn btn-sm btn-primary">
      {english ? "Explore Now" : "أكتشف الآن"}
    </Link>
  );
  //-------------------------------------------------------------------------------------------------//
  const callButton = (
    <a className="custom-carousel-button btn btn-sm btn-primary" href="tel:+201017762142">
      {!english && <i className="fa fa-phone me-2"></i>}
      {english ? "Call Us" : "اتصل بنا"}
      {english && <i className="fa fa-phone ms-2"></i>}
    </a>
  );
  //-------------------------------------------------------------------------------------------------//
  useEffect(() => {
    const interval = setInterval(() => {
      if (current_slide < 4) {
        set_current_slide(current_slide + 1);
      } else {
        set_current_slide(1);
      }
    }, 5000);
    return () => clearInterval(interval);
  }, [current_slide]);
  //-------------------------------------------------------------------------------------------------//
  return (
    <div className="custom-carousel-wrapper">
      <div className="custom-carousel-inner">
        <div className="custom-carousel-inner-social">
          <div>
            <span className="text-darkblue">Facebook</span>
          </div>
          <div>
            <span className="text-darkblue">Instagram</span>
          </div>
          <div>
            <span className="text-darkblue">Twitter</span>
          </div>
        </div>
        <Carousel controls={false} indicators={false} interval={5000} activeIndex={current_slide - 1}>
          <Carousel.Item>
            <Slide text={text} button={exploreButton} slide={slide1} alt="First slide" />
          </Carousel.Item>
          <Carousel.Item>
            <Slide text={text} button={exploreButton} slide={slide2} alt="Seconde slide" />
          </Carousel.Item>
          <Carousel.Item>
            <Slide text={text} button={exploreButton} slide={slide3} alt="Third slide" />
          </Carousel.Item>
          <Carousel.Item>
            <Slide text={contact} button={callButton} slide={slide4} alt="Fourth slide" />
          </Carousel.Item>
        </Carousel>
      </div>
      <div className="text-center mt-1">
        <i
          className={current_slide === 1 ? "fa fa-circle me-1 text-primary fa-lg" : "fa fa-circle me-1 text-secondary"}
          onClick={() => set_current_slide(1)}
          aria-hidden="true"
          role="button"
        ></i>
        <i
          className={current_slide === 2 ? "fa fa-circle me-1 text-primary fa-lg" : "fa fa-circle me-1 text-secondary"}
          onClick={() => set_current_slide(2)}
          aria-hidden="true"
          role="button"
        ></i>
        <i
          className={current_slide === 3 ? "fa fa-circle me-1 text-primary fa-lg" : "fa fa-circle me-1 text-secondary"}
          onClick={() => set_current_slide(3)}
          aria-hidden="true"
          role="button"
        ></i>
        <i
          className={current_slide === 4 ? "fa fa-circle text-primary fa-lg" : "fa fa-circle text-secondary"}
          onClick={() => set_current_slide(4)}
          aria-hidden="true"
          role="button"
        ></i>
      </div>
    </div>
  );
};

export default CarouselComponent;
