
import { useSelector } from "react-redux";
const Slide = ({ text,button, slide, alt }) => {
  //-------------------------------------------------------------------------------------------------//
  const english = useSelector((state) => state.english.active);
  return (
    <>
      <img src={slide} className="d-block w-100" alt={alt}></img>
      <div
        className={
          english ? "custom-carousel-text" : "custom-carousel-text-arabic"
        }
      >
        {text}
        {button}
      </div>
    </>
  );
};

export default Slide;
