import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { deleteMyAccount } from "../../../../actions/deleteMyAccount";
import deleteUserModal from "../../../../assets/deleteUserModal/deleteUserModal.jpg";
import "./deleteUserModal.css";
//----------------------------------------------------------------------------------------------------//
const DeleteUSerModal = ({ setDeletingAccount, loggedUserData }) => {
  const dispatch = useDispatch();
  //---------------------------------------------------------------//
  const navigate = useNavigate();
  //---------------------------------------------------------------//
  const english = useSelector((state) => state.english.active);
  //---------------------------------------------------------------//
  async function handleAccountDelete() {
    dispatch(deleteMyAccount(loggedUserData, navigate));
  }
  //---------------------------------------------------------------//
  return (
    <div id="deleteUserModal" className="modal" tabindex="-1">
      <div className="modal-dialog modal-dialog-centered delete-user-modal-dialog">
        <div className="modal-content delete-user-modal-content">
          <div className="modal-header delete-user-modal-header">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => setDeletingAccount(false)}
            ></button>
          </div>
          <div className="modal-body delete-user-modal-body text-center">
            <img src={deleteUserModal} alt=""></img>
            <h4 className="text-darkblue">
              {english ? "Are you sure, you want to delete this account ?" : "هل تريد حذف حسابك ؟"}
            </h4>
            <p className="text-secondary">
              {english
                ? "Deleting account will expose you to the loss of the data"
                : "حذف الحساب سوف يعرضك لفقد بياناتك"}
            </p>
            <div className="delete-user-button-wrapper mb-5">
              <button className="btn btn-outline-primary" data-bs-dismiss="modal" onClick={handleAccountDelete}>
                {english ? "Yes, Remove" : "نعم ، احذف"}
              </button>
              <button className="btn btn-primary" data-bs-dismiss="modal" onClick={() => setDeletingAccount(false)}>
                {english ? "No, Cancel" : "لا ، الغاء"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteUSerModal;
