import React, { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { companyData } from "../../dummy";
import { getSingleCompound } from "../../actions/getSingleCompound";
import { useParams } from "react-router-dom";
import CompoundLayoutView from "./compoundLayoutView/compoundLayoutView";
import PaymentSystemCalculator from "../paymentSystemCalculator/paymentSystemCalculator";
import SuccessBooking from "../successBooking/successBooking";
import SuccessFavourite from "../successFavourite/successFavourite";
import WhatsAppWidget from "react-whatsapp-chat-widget";
//---------------------------------------------------------------------------------------------//
const ViewCompoundUnits = () => {
  const dispatch = useDispatch();
  //---------------------------------------------------------------------------------------------//
  const params = useParams();
  //---------------------------------------------------------------------------------------------//
  const [selectedUnit, setSelectedUnit] = useState({});
  //---------------------------------------------------------------------------------------------//
  const english = useSelector((state) => state.english.active);
  //---------------------------------------------------------------------------------------------//
  const selectedCompound = useSelector((state) => state.selectedCompound.selectedCompound);
  //---------------------------------------------------------------------------------------------//
  const loading = useSelector((state) => state.selectedCompound.loading);
  //----------------------------------------------------------------------------------------------//
  function loadPage() {
    dispatch(getSingleCompound(params.id, english));
  }
  //---------------------------------------------------------------------------------------------//
  useEffect(() => {
    if (!selectedCompound.id) {
      //TODO: fetch compound based on params.id
      //if everythings is ok then dispatch loading:false, selectedCompound:res.data
      //otherwise dispatch loading:false, selectedCompound:{}
      loadPage();
      // dispatch({
      //   type: "SET_SELECTED_COMPOUND",
      //   payload: {
      //     selectedCompound: compoundData,
      //     loading: false,
      //   },
      // });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //---------------------------------------------------------------------------------------------//
  return (
    <Fragment>
      {selectedCompound.id && !loading && (
        <>
          <CompoundLayoutView
            compoundData={selectedCompound}
            setSelectedUnit={setSelectedUnit}
            selectedUnit={selectedUnit}
          ></CompoundLayoutView>
          <PaymentSystemCalculator property={selectedUnit} />
          <SuccessBooking property={selectedUnit} />
          <SuccessFavourite property={selectedUnit} />
          <WhatsAppWidget
            phoneNo="+201009244362"
            position={english ? "right" : "left"}
            autoOpen={true}
            autoOpenTimer={5000}
            iconSize="60"
            iconColor="white"
            iconBgColor="#25D366"
            headerIcon={companyData.logo}
            headerTitle={english ? "Alassema" : "العاصمة"}
            btnTxt={english ? "Start Chat" : "ابدأ الدردشة"}
            messageBoxTxt={
              english
                ? `I want to know more about ${selectedCompound.name}`
                : `أرجو معرفة المزيد عن ${selectedCompound.nameArabic}`
            }
            chatMessage={
              english ? (
                <>
                  Hi there 👋 <br />
                  <br /> How can I help you?
                </>
              ) : (
                <>
                  مرحبا 👋 <br />
                  <br />
                  كيف تريد ان نساعدك؟
                </>
              )
            }
            messageBox={false}
          ></WhatsAppWidget>
        </>
      )}
      {loading && <h3 className="text-center">Loading...</h3>}
      {!loading && !selectedCompound.id && <h3 className="text-center">No matching result</h3>}
    </Fragment>
  );
};
export default ViewCompoundUnits;
