import React from "react";
import { useSelector } from "react-redux";
import "./locationDetails.css";
//-------------------------------------------------------------------------------------//
const LocationDetails = ({ location, compoundLocationMap }) => {
  //----------------------------------------------------------------------------------//
  const english = useSelector((state) => state.english.active);
  //----------------------------------------------------------------------------------//
  return (
    <div className="location-details-section-wrapper mt-5">
      <div className={english ? "location-details-section-inner" : "location-details-section-inner-arabic"}>
        <div className="location-details-section-text">
          <h1 className="text-darkblue mb-3">{english ? "Location Details" : "تفاصيل عن الموقع"}</h1>
          <p className="text-secondary">{english ? location.aboutLocation : location.aboutLocationArabic}</p>
        </div>
        <div className="location-details-section-image">
          {/* <img src={compoundLocationMap}></img> */}
          <iframe
            title="location"
            src={compoundLocationMap}
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default LocationDetails;
