import axios from "../axios.js";
//------------------------------//
const translations = {
  "Compound not found": "كمبوند غير موجود",
};
//------------------------------//
export function getSingleCompound(id, english) {
  return async (dispatch) => {
    try {
      const response = await axios.get(`/compounds/${id}/`);
      dispatch({
        type: "SET_SELECTED_COMPOUND",
        payload: {
          selectedCompound: response.data, //compoundData
          loading: false,
        },
      });
    } catch (error) {
      let errorMessage = "";
      if (english) {
        errorMessage = error.response?.data?.message || error.message;
      } else {
        errorMessage = translations[error.response?.data?.message] || "خطأ غير معروف ، تحقق من الإتصال بالإنترنت";
      }
      dispatch({
        type: "SET_SELECTED_COMPOUND",
        payload: {
          selectedCompound: {}, //compoundData
          loading: false,
          errorMessage: errorMessage,
        },
      });
    }
  };
}
