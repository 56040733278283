import { toast } from "react-toastify";
import axios from "../axios.js";
import { getSingleCompany } from "./getSingleCompany.js";
//------------------------------//
export function addFeedbackToCompany(userToken, selectedCompany, feedback) {
  return async (dispatch) => {
    try {
      const response = await axios.post(
        `/feedbacks/`,
        { company: selectedCompany.id, text: feedback },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      dispatch(getSingleCompany(selectedCompany.id));
      const token = localStorage.getItem("capitalAccessToken");
      if (token) {
        dispatch({ type: "RENEW_ACCESS_TOKEN", payload: token });
        localStorage.removeItem("capitalAccessToken");
      }
      console.log(response);
      toast.success(response?.data?.message);
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message || "Failed to add feedback");
    }
  };
}
//------------------------------//
export function replyToFeedback(userToken, selectedCompany, feedbackId, reply) {
  return async (dispatch) => {
    try {
      const response = await axios.post(
        `/feedbacks/${feedbackId}/reply/`,
        { company: selectedCompany.id, text: reply },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      console.log(response.data);
      dispatch(getSingleCompany(selectedCompany.id));
      const token = localStorage.getItem("capitalAccessToken");
      if (token) {
        dispatch({ type: "RENEW_ACCESS_TOKEN", payload: token });
        localStorage.removeItem("capitalAccessToken");
      }
      console.log(response);
      toast.success(response?.data?.message);
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message || "Failed to replt to feedback");
    }
  };
}
//------------------------------//
export function likeFeedback(userToken, selectedCompany, feedbackId) {
  return async (dispatch) => {
    try {
      const response = await axios.post(
        `/feedbacks/${feedbackId}/like/`,
        { id: feedbackId },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      console.log(response.data);
      dispatch(getSingleCompany(selectedCompany.id));
      const token = localStorage.getItem("capitalAccessToken");
      if (token) {
        dispatch({ type: "RENEW_ACCESS_TOKEN", payload: token });
        localStorage.removeItem("capitalAccessToken");
      }
      toast.success(response?.data?.message);
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message || "Failed to like feedback");
    }
  };
}
//------------------------------//
export function dislikeFeedback(userToken, selectedCompany, feedbackId) {
  return async (dispatch) => {
    try {
      const response = await axios.delete(`/feedbacks/${feedbackId}/like/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      });
      console.log(response.data);
      dispatch(getSingleCompany(selectedCompany.id));
      const token = localStorage.getItem("capitalAccessToken");
      if (token) {
        dispatch({ type: "RENEW_ACCESS_TOKEN", payload: token });
        localStorage.removeItem("capitalAccessToken");
      }
      toast.success(response?.data?.message);
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message || "Failed to dislike feedback");
    }
  };
}
//------------------------------//
export function deleteFeedback(userToken, selectedCompany, feedbackId) {
  return async (dispatch) => {
    try {
      const response = await axios.delete(`/feedbacks/${feedbackId}/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      });
      console.log(response.data);
      dispatch(getSingleCompany(selectedCompany.id));
      const token = localStorage.getItem("capitalAccessToken");
      if (token) {
        dispatch({ type: "RENEW_ACCESS_TOKEN", payload: token });
        localStorage.removeItem("capitalAccessToken");
      }
      toast.success(response?.data?.message);
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message || "Failed to dislike feedback");
    }
  };
}
