import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addFeedbackToCompany,
  deleteFeedback,
  dislikeFeedback,
  likeFeedback,
  replyToFeedback,
} from "../../../../actions/addFeebackToCompany";
import "./clientsFeedBack.css";
//-----------------------------------------------------------------------------------------------//
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
import ar from "javascript-time-ago/locale/ar";
import { toast } from "react-toastify";
TimeAgo.addLocale(en);
TimeAgo.addLocale(ar);
//----------------------------------------------------------------------------------------------//
const ClientsFeedBack = ({ selectedCompany }) => {
  const dispatch = useDispatch();
  //--------------------------------------------------------------------------------------------//
  const english = useSelector((state) => state.english.active);
  //--------------------------------------------------------------------------------------------//
  const user = useSelector((state) => state.user.user);
  //--------------------------------------------------------------------------------------------//
  const [feedback, setFeedback] = useState("");
  //--------------------------------------------------------------------------------------------//
  const timeAgo = new TimeAgo(english ? "en-US" : "ar-arab");
  //--------------------------------------------------------------------------------------------//
  function handleFeedbackSubmit() {
    if (user.id) {
      dispatch(addFeedbackToCompany(user.token, selectedCompany, feedback));
    } else {
      toast.error("You must login first");
    }
  }
  //--------------------------------------------------------------------------------------------//
  async function handleFeedbackLike(feedback) {
    if (user.id) {
      if (feedback.likersIds.includes(user.id)) {
        dispatch(dislikeFeedback(user.token, selectedCompany, feedback.id));
      } else {
        dispatch(likeFeedback(user.token, selectedCompany, feedback.id));
      }
    } else {
      toast.error("You must login first");
    }
  }
  //--------------------------------------------------------------------------------------------//
  function handleFeedbackReply(feedback) {
    if (user.id) {
      const reply = prompt("Write your reply");
      if (!reply) {
        return;
      }
      dispatch(replyToFeedback(user.token, selectedCompany, feedback.id, reply));
    } else {
      toast.error("You must login first");
    }
  }
  //--------------------------------------------------------------------------------------------//
  function handleFeedbackDelete(feedback) {
    if (user.id) {
      const ok = window.confirm("Are you sure you want to delete feedback?");
      if (!ok) {
        return;
      }
      dispatch(deleteFeedback(user.token, selectedCompany, feedback.id));
    } else {
      toast.error("You must login first");
    }
  }
  //--------------------------------------------------------------------------------------------//
  // function handleFeedbackDelete(feedback) {
  //   if (user.id) {
  //     const ok = prompt("Are you sure you want to delete feedback?");
  //     if (!ok) {
  //       return;
  //     }
  //     dispatch(deleteFeedback(user.token, selectedCompany, feedback.id));
  //   } else {
  //     toast.error("You must login first");
  //   }
  // }
  //--------------------------------------------------------------------------------------------//
  return (
    <div className="custom-company-profile-feedback-section-wrapper mt-5">
      <div className="custom-company-profile-feedback-section-inner">
        <div
          className={
            english ? "custom-company-profile-feedback-section" : "custom-company-profile-feedback-section-arabic"
          }
        >
          {/* -------------header-------------- */}
          <div>
            <h1 className="text-darkblue mb-3">{english ? "Clients Feedback" : "آراء العملاء"}</h1>
          </div>
          {/* ------------share your feedback input------------ */}
          {/* ------------share your feedback input------------ */}
          {/* ------------share your feedback input------------ */}
          <textarea
            type="text"
            placeholder={english ? "share your feedback" : "شاركنا رأيك"}
            maxLength="200"
            className="mb-4 p-4"
            onChange={(e) => {
              setFeedback(e.target.value);
            }}
          ></textarea>
          <div>
            <button className="btn btn-lg btn-primary mb-5" onClick={handleFeedbackSubmit}>
              {english ? "Submit" : "إرسال"}
            </button>
          </div>
          {/*-------------------Feedbacks-----------------------*/}
          {/*-------------------Feedbacks-----------------------*/}
          {/*-------------------Feedbacks-----------------------*/}
          {selectedCompany.feedbacks.map((feedback) => (
            <div
              key={feedback.id}
              className={
                english
                  ? "custom-company-profile-single-feedback-wrapper mb-4"
                  : "custom-company-profile-single-feedback-wrapper-arabic mb-4"
              }
            >
              {/* --------------------image----------------------- */}
              {/* --------------------image----------------------- */}
              {/* --------------------image----------------------- */}
              <img src={feedback.creatorPhoto} alt=""></img>
              {/* -------------------content---------------------- */}
              {/* -------------------content---------------------- */}
              {/* -------------------content---------------------- */}
              <div style={{ width: "100%" }}>
                {/* -------------Name--------------- */}
                {/* -------------Name--------------- */}
                {/* -------------Name--------------- */}
                <h6 className="text-darkblue text-bold mb-2">{feedback.creatorName}</h6>
                {/* -------------Text--------------- */}
                {/* -------------Text--------------- */}
                {/* -------------Text--------------- */}
                <p className="text-secondary">{feedback.text}</p>
                {/* -------year/like/comment-------- */}
                {/* -------year/like/comment-------- */}
                {/* -------year/like/comment-------- */}
                <div className={english ? "" : "year-like-comment-arabic"}>
                  {/* -------------date------------ */}
                  {/* ----------------------------- */}
                  <span className={english ? "text-secondary me-4" : "text-secondary ms-4"}>
                    {timeAgo.format(feedback.date * 1000)}
                  </span>
                  {/* -------------like------------ */}
                  {/* ----------------------------- */}
                  <span className="text-secondary m-1">
                    <i
                      className={
                        feedback.likersIds.includes(user.id) ? "fa fa-heart me-1 text-danger" : "fa fa-heart-o me-1"
                      }
                      role="button"
                      onClick={() => handleFeedbackLike(feedback)}
                    ></i>
                  </span>
                  {/* -----------comment----------- */}
                  {/* ----------------------------- */}
                  <span className="text-secondary m-1">
                    <i
                      className="fa fa-comment-o me-1"
                      role="button"
                      onClick={() => {
                        handleFeedbackReply(feedback);
                      }}
                    ></i>
                  </span>
                  {/* -----------delete------------ */}
                  {/* ----------------------------- */}
                  {feedback.creatorId === user.id && (
                    <span className="text-secondary m-1">
                      <i
                        className="fa fa-trash me-1"
                        role="button"
                        onClick={() => {
                          handleFeedbackDelete(feedback);
                        }}
                      ></i>
                    </span>
                  )}
                  {/* ----------------------------- */}
                  {/* ----------------------------- */}
                </div>
                {/* -------------replies------------ */}
                {/* -------------replies------------ */}
                {/* -------------replies------------ */}
                {feedback.replies.length > 0 && (
                  <>
                    {feedback.replies.map((reply) => (
                      <div
                        className={
                          english
                            ? "custom-company-profile-single-feedback-replies mt-4"
                            : "custom-company-profile-single-feedback-replies-arabic mt-4"
                        }
                      >
                        <img src={reply.creatorPhoto} alt=""></img>
                        <div key={reply.id}>
                          <h6 className="text-darkblue text-bold mb-2">{reply.creatorName}</h6>
                          <p className="text-secondary">{reply.text}</p>
                        </div>
                        {/* {reply.creatorId === user.id && (
                          <i
                            className="fa fa-trash text-danger"
                            style={{ position: "absolute", left: "1%", top: "10%" }}
                            role="button"
                            onClick={() => handleReplyDelete(reply)}
                          ></i>
                        )} */}
                      </div>
                    ))}
                  </>
                )}
              </div>
              {/* ------------------------------------------------ */}
              {/* ------------------------------------------------ */}
              {/* ------------------------------------------------ */}
            </div>
          ))}
          {/*---------------------------------------------------*/}
          {/*---------------------------------------------------*/}
          {/*---------------------------------------------------*/}
        </div>
      </div>
    </div>
  );
};

export default ClientsFeedBack;
