import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import "./availableLocations.css";
//------------------------------------------------------------------------//
const AvailableLocations = ({ availableLocations }) => {
  //------------------------------------------------------------------------//
  const english = useSelector((state) => state.english.active);
  //------------------------------------------------------------------------//
  return (
    <div className="custom-available-locations-wrapper">
      {/* ---------------------------------------------- */}
      {/* ---------------------------------------------- */}
      <div
        className={
          english
            ? "custom-available-locations-inner"
            : "custom-available-locations-inner-arabic"
        }
      >
        {/* ------------------------------ */}
        {/* ------------------------------ */}
        <h1 className="text-darkblue text-bold mb-4 mt-5">
          {english ? "Search With Location" : "أبحث عن المواقع"}
        </h1>
        {/* ------------------------------ */}
        {/* ------------------------------ */}
        {availableLocations.length > 0 && (
          <div
            className={
              english
                ? "custom-available-locations"
                : "custom-available-locations-arabic"
            }
          >
            {availableLocations.map((element) => (
              <div className="custom-single-location mb-2">
                <Link to={`/location/info/${element.id}`}>
                  <img src={element.locationCardImage} alt="..."></img>
                </Link>
                <Link
                  className="text-darkblue text-bold mt-1"
                  to={`/location/info/${element.id}`}
                >
                  {english ? element.name : element.nameArabic}
                </Link>
              </div>
            ))}
          </div>
        )}
        {/* ------------------------------ */}
        {/* ------------------------------ */}
        {availableLocations.length === 0 && (
          <h3 className="text-darkblue text-center">
            {english ? "No Locations found" : "لا يوجد مواقع"}
          </h3>
        )}
        {/* ------------------------------ */}
        {/* ------------------------------ */}
      </div>
      {/* ---------------------------------------------- */}
      {/* ---------------------------------------------- */}
      {availableLocations.length > 0 && (
        <div className="text-center m-4">
          <button
            className={
              english
                ? "btn btn-primary btn-lg show-more-button"
                : "btn btn-primary btn-lg show-more-button-arabic"
            }
          >
            {english ? "Show More" : "اعرض المزيد"}
            <i className="fa fa-angle-double-down angle-double-text m-2"></i>
          </button>
        </div>
      )}
      {/* ---------------------------------------------- */}
      {/* ---------------------------------------------- */}
    </div>
  );
};

export default AvailableLocations;
