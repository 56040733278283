import React from "react";
import { useSelector } from "react-redux";
import "./topImage.css";
const TopImage = ({ image, imageHeader, imageFirstLine, imageSecondLine }) => {
  //------------------------------------------------------------------------------------------//
  const english = useSelector((state) => state.english.active);
  //------------------------------------------------------------------------------------------//
  return (
    <div className="custom-top-image-wrapper">
      <div className="custom-top-image-inner">
        <div className="custom-top-image-slide">
          <img src={image} className="" alt="..."></img>
          <div className="custom-top-image-text-wrapper text-light text-center">
            <p className="custom-top-image-header-text">{imageHeader}</p>
            <p className="custom-top-image-body-text mb-1">
              {imageFirstLine}
              <br></br>
              {imageSecondLine}
            </p>
            <button className="custom-top-image-button btn btn-primary text-center">
              {english ? "Show List" : "أعرض القائمة"}
              <i
                className="fa fa-angle-double-down angle-double-text ms-2"
                aria-hidden="true"
              ></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopImage;
