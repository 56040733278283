import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { Radio, RadioGroup } from "react-radio-group";
import { useDispatch, useSelector } from "react-redux";
import { searchByFilters } from "../../../../../actions/searchPage";
import "./searchFilters.css";
import Slider from "./slider";
//--------------------------------------------------------------------------------------------------//
const SearchFilters = ({ availableLocations }) => {
  const investmentPropertiesList = [
    "coffee shops",
    "restaurants",
    "shops",
    "offices",
    "partitions",
    "pharmacies",
    "clinics",
    "hotel units",
  ];
  //--------------------------------------------------------------------------------------------//
  const residentialPropertiesList = [
    "apartment",
    "duplex villas",
    "detached villas",
    "twin house",
    "studio",
    "hotel units",
  ];
  //--------------------------------------------------------------------------------------------//
  const dispatch = useDispatch();
  //--------------------------------------------------------------------------------------------//
  const location = useLocation();
  //--------------------------------------------------------------------------------------------//
  const [minInstallment, setMinInstallment] = useState(1);
  const [maxInstallment, setMaxInstallment] = useState(10);
  //--------------------------------------------------------------------------------------------//
  const [roomsNumber, setRoomsNumber] = useState(0);
  //--------------------------------------------------------------------------------------------//
  const [floorNumber, setFloorNumber] = useState(null);
  //--------------------------------------------------------------------------------------------//
  const [minPrice, setMinPrice] = useState(100000);
  const [maxPrice, setMaxPrice] = useState(8000000);
  //--------------------------------------------------------------------------------------------//
  const [minArea, setMinArea] = useState(10);
  const [maxArea, setMaxArea] = useState(500);
  //--------------------------------------------------------------------------------------------//
  const [minReservationDeposit, setMinReservationDeposit] = useState(0);
  const [maxReservationDeposit, setMaxReservationDeposit] = useState(50);
  //--------------------------------------------------------------------------------------------//
  const [finishing, setFinishing] = useState("full");
  //--------------------------------------------------------------------------------------------//
  const [categoryIndex, setCategoryIndex] = useState(0);
  //--------------------------------------------------------------------------------------------//
  const [locationIndex, setLocationIndex] = useState(0);
  //--------------------------------------------------------------------------------------------//
  const english = useSelector((state) => state.english.active);
  //--------------------------------------------------------------------------------------------//
  const handleArea = (e) => {
    setMinArea(e.minValue);
    setMaxArea(e.maxValue);
  };
  //--------------------------------------------------------------------------------------------//
  const handleReservationDeposit = (e) => {
    setMinReservationDeposit(e.minValue);
    setMaxReservationDeposit(e.maxValue);
  };
  //--------------------------------------------------------------------------------------------//
  function getCategoryName(category) {
    if (!english) {
      switch (category) {
        case "coffee shops":
          return "كافيهات";
        case "restaurants":
          return "مطاعم";
        case "shops":
          return "محلات";
        case "offices":
          return "مكاتب ادارية";
        case "partitions":
          return "بارتشن";
        case "pharmacies":
          return "صيدليات";
        case "clinics":
          return "عيادات";
        case "hotel units":
          return "وحدات فندقية";
        case "apartment":
          return "شقق سكنية";
        case "duplex villas":
          return "فيلات دوبلكس";
        case "detached villas":
          return "فيلات منفصلة";
        case "twin house":
          return "توين هاوس";
        case "studio":
          return "ستوديو";
        default:
          return;
      }
    } else return category.charAt(0).toUpperCase() + category.slice(1);
  }
  //--------------------------------------------------------------------------------------------//
  async function handleSearch() {
    const category = location.pathname.includes("/residential")
      ? residentialPropertiesList[categoryIndex]
      : investmentPropertiesList[categoryIndex];
    const propertyLocation = availableLocations[locationIndex]?.name;
    const url = location.pathname.includes("/residential")
      ? "/search-residential-unit/"
      : "/search-investment-unit/";
    dispatch(
      searchByFilters(
        category,
        minInstallment,
        maxInstallment,
        roomsNumber,
        floorNumber,
        minPrice,
        maxPrice,
        minArea,
        maxArea,
        minReservationDeposit,
        maxReservationDeposit,
        finishing,
        propertyLocation,
        url
      )
    );
  }
  //--------------------------------------------------------------------------------------------//
  return (
    <div className="custom-search-page-engine-inner">
      {/* ----------------------------------------------------------------- */}
      {/* ----------------------------------------------------------------- */}
      <div className="custom-sliders">
        {/* -------------------------Type-------------------------- */}
        <div className="label-slider-wrapper">
          <h6 className="text-darkblue text-bold">
            {english ? "Type" : "النوع"}
          </h6>
          <div
            className={english ? "choices-wrapper" : "choices-wrapper-arabic"}
          >
            {location.pathname.includes("/investment/search") &&
              investmentPropertiesList.map((element, index) => (
                <div
                  role="button"
                  className={
                    categoryIndex === index ? "choices-active" : "choices"
                  }
                  key={element}
                  onClick={() => setCategoryIndex(index)}
                >
                  {getCategoryName(element)}
                </div>
              ))}
            {location.pathname.includes("/residential/search") &&
              residentialPropertiesList.map((element, index) => (
                <div
                  role="button"
                  className={
                    categoryIndex === index ? "choices-active" : "choices"
                  }
                  key={element}
                  onClick={() => setCategoryIndex(index)}
                >
                  {getCategoryName(element)}
                </div>
              ))}
          </div>
        </div>
        {/* -------------------------Location-------------------------- */}
        <div className="label-slider-wrapper">
          <h6 className="text-darkblue text-bold">
            {english ? "Location" : "الموقع"}
          </h6>
          <div
            className={english ? "choices-wrapper" : "choices-wrapper-arabic"}
          >
            {availableLocations.map((location, index) => (
              <div
                role="button"
                className={
                  locationIndex === index ? "choices-active" : "choices"
                }
                key={location.id}
                onClick={() => setLocationIndex(index)}
              >
                {english ? location.name : location.nameArabic}
              </div>
            ))}
          </div>
        </div>
        {/* -----------------------Finishing------------------------ */}
        <div className="label-slider-wrapper">
          <h6 className="text-darkblue text-bold mb-2">
            {english ? "Finishing" : "التشطيب"}
          </h6>
          <RadioGroup
            className="custom-radio-group"
            name="finishing"
            selectedValue={finishing}
            onChange={(value) => {
              setFinishing(value);
            }}
          >
            <div className={english ? "radio-choice" : "radio-choice-arabic"}>
              <Radio value="none" />
              {english ? "Unfinished" : "بدون تشطيب"}
            </div>
            <div className={english ? "radio-choice" : "radio-choice-arabic"}>
              <Radio value="half" />
              {english ? "Half Finished" : "نصف تشطيب"}
            </div>
            <div className={english ? "radio-choice" : "radio-choice-arabic"}>
              <Radio value="full" />
              {english ? "Fully Finished" : "تشطيب كامل"}
            </div>
          </RadioGroup>
        </div>
        {/* -------------------------Price-------------------------- */}
        <div className="label-slider-wrapper">
          <h6 className="text-darkblue text-bold">
            {english ? "Price" : "السعر"}
          </h6>
          <div
            className={
              english ? "from-to-input-wrapper" : "from-to-input-wrapper-arabic"
            }
          >
            <div
              className={
                english
                  ? "label-and-input-wrapper"
                  : "label-and-input-wrapper-arabic"
              }
            >
              <label htmlFor="min-price">{english ? "From" : "من"}</label>
              <input
                id="min-price"
                type="text"
                pattern="[0-9]"
                value={isNaN(minPrice) ? 0 : minPrice}
                onChange={(e) => {
                  setMinPrice(parseInt(e.currentTarget.value));
                }}
              ></input>
            </div>
            <div
              className={
                english
                  ? "label-and-input-wrapper"
                  : "label-and-input-wrapper-arabic"
              }
            >
              <label htmlFor="max-price">{english ? "To" : "الى"}</label>
              <input
                id="max-price"
                type="text"
                pattern="[0-9]"
                value={isNaN(maxPrice) ? 0 : maxPrice}
                onChange={(e) => {
                  setMaxPrice(parseInt(e.currentTarget.value));
                }}
              ></input>
            </div>
          </div>
        </div>
        {/* -------------------------Area-------------------------- */}
        <Slider
          sliderLabel={
            english ? (
              <>
                Area M<sup>2</sup>
              </>
            ) : (
              <>
                المساحة م<sup>٢</sup>
              </>
            )
          }
          labelArray={
            english
              ? ["0", "100", "200", "300", "400", "500"]
              : ["٠", "١٠٠", "٢٠٠", "٣٠٠", "٤٠٠", "٥٠٠"]
          }
          leastValue={0}
          highestValue={500}
          step={50}
          minValue={minArea}
          maxValue={maxArea}
          handleInput={handleArea}
        ></Slider>
        {/* ------------------------deposit------------------------- */}
        <Slider
          sliderLabel={english ? "deposit %" : "% مقدم حجز"}
          leastValue={0}
          labelArray={
            english
              ? ["0", "10", "20", "30", "40", "50"]
              : ["٠", "١٠", "٢٠", "٣٠", "٤٠", "٥٠"]
          }
          highestValue={50}
          step={10}
          minValue={minReservationDeposit}
          maxValue={maxReservationDeposit}
          handleInput={handleReservationDeposit}
        ></Slider>
        {/* ----------------------Installment----------------------- */}
        <div className="label-slider-wrapper">
          <h6 className="text-darkblue text-bold">
            {english ? "Installment" : "القسط"}
          </h6>
          <div
            className={
              english ? "from-to-input-wrapper" : "from-to-input-wrapper-arabic"
            }
          >
            <div
              className={
                english
                  ? "label-and-input-wrapper"
                  : "label-and-input-wrapper-arabic"
              }
            >
              <label htmlFor="min-installment">
                {english ? "From (years)" : "من(سنين)"}
              </label>
              <input
                id="min-installment"
                type="text"
                pattern="[0-9]"
                value={isNaN(minInstallment) ? 0 : minInstallment}
                onChange={(e) => {
                  setMinInstallment(parseInt(e.currentTarget.value));
                }}
              ></input>
            </div>
            <div
              className={
                english
                  ? "label-and-input-wrapper"
                  : "label-and-input-wrapper-arabic"
              }
            >
              <label htmlFor="max-installment">
                {english ? "To (years)" : "الى(سنين)"}
              </label>
              <input
                id="max-installment"
                type="text"
                pattern="[0-9]"
                value={isNaN(maxInstallment) ? 0 : maxInstallment}
                onChange={(e) => {
                  setMaxInstallment(parseInt(e.currentTarget.value));
                }}
              ></input>
            </div>
          </div>
        </div>
        {/* --------------------floor/rooms number------------------- */}
        <div className="label-slider-wrapper">
          {location.pathname === "/search" && (
            <h6 className="text-darkblue text-bold">
              {english ? "Floor" : "الطابق"}
            </h6>
          )}
          {location.pathname.includes("/residential/search") && (
            <h6 className="text-darkblue text-bold">
              {english ? "Floor/Rooms" : "الطابق/الغرف"}
            </h6>
          )}
          <div
            className={
              english ? "from-to-input-wrapper" : "from-to-input-wrapper-arabic"
            }
          >
            <div
              className={
                english
                  ? "label-and-input-wrapper"
                  : "label-and-input-wrapper-arabic"
              }
            >
              <label htmlFor="floor-number">
                {english ? "Floor number" : "رقم الطابق"}
              </label>
              <input
                id="floor-number"
                type="text"
                pattern="[0-9]"
                value={
                  isNaN(floorNumber) ? (english ? "all" : "الكل") : floorNumber
                }
                onChange={(e) => {
                  setFloorNumber(parseInt(e.currentTarget.value));
                }}
              ></input>
            </div>
            {location.pathname.includes("/residential/search") && (
              <div
                className={
                  english
                    ? "label-and-input-wrapper"
                    : "label-and-input-wrapper-arabic"
                }
              >
                <label htmlFor="rooms-number">
                  {english ? "Rooms number" : "عدد الغرف"}
                </label>
                <input
                  id="rooms-number"
                  type="text"
                  pattern="[0-9]"
                  value={isNaN(roomsNumber) ? 0 : roomsNumber}
                  onChange={(e) => {
                    setRoomsNumber(parseInt(e.currentTarget.value));
                  }}
                ></input>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* ----------------------------------------------------------- */}
      {/* ----------------------------------------------------------- */}
      {/* ----------------------------------------------------------- */}
      <button
        className="btn btn-primary btn-lg search-button-width mt-4"
        data-bs-dismiss="offcanvas"
        onClick={() => {
          handleSearch();
        }}
      >
        {english && (
          <>
            Search
            <i className="fa fa-search ms-2"></i>
          </>
        )}
        {!english && (
          <>
            <i className="fa fa-search me-2"></i>
            البحث الآن
          </>
        )}
      </button>
      {/* ----------------------------------------------------------------- */}
      {/* ----------------------------------------------------------------- */}
      {/* ----------------------------------------------------------------- */}
      {/* ----------------------------------------------------------------- */}
    </div>
  );
};

export default SearchFilters;
