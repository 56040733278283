import axios from "../axios.js";
//------------------------------//
const translations = {
  "Company not found": "شركة غير موجودة",
};
//------------------------------//
export function getSingleCompany(id, english) {
  return async (dispatch) => {
    try {
      const response = await axios.get(`/companies/${id}/`);
      dispatch({
        type: "SET_SELECTED_COMPANY",
        payload: {
          selectedCompany: response.data, //companyData
          loading: false,
        },
      });
    } catch (error) {
      let errorMessage = "";
      if (english) {
        errorMessage = error.response?.data?.message || error.message;
      } else {
        errorMessage = translations[error.response?.data?.message] || "خطأ غير معروف ، تحقق من الإتصال بالإنترنت";
      }
      dispatch({
        type: "SET_SELECTED_COMPANY",
        payload: {
          selectedCompany: {}, //companyData
          loading: false,
          errorMessage: errorMessage,
        },
      });
    }
  };
}
