import React, { Fragment, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { searchPage } from "../../actions/searchPage";
import SearchPageImage from "./SubComponents/SearchPageImage/searchPageImage";
import AvailableMalls from "./SubComponents/AvailableMalls/availableMalls";
import AvailableCompounds from "./SubComponents/AvailableCompounds/availableCompounds";
import AvailableCompanies from "./SubComponents/AvailableCompanies/availableCompanies";
import AvailableLocations from "./SubComponents/AvailableLocations/availableLocations";
import ViewDetailsModal from "../FavouriteAndBookingPages/SubComponents/viewDetailsModal/viewDetailsModal";
import SearchResults from "./SubComponents/SearchResults/searchResults";
import SuccessBooking from "../successBooking/successBooking";
import SuccessFavourite from "../successFavourite/successFavourite";
import Loader from "../Loader/loader";
import slide1 from "../../assets/favouritePage/slide1.jpg";
import * as bootstrap from "bootstrap";
//------------------------------------------------------------------------------------------------//
const SearchPage = () => {
  const params = useParams();
  //---------------------------------------------------------------------------------------------//
  const location = useLocation();
  //---------------------------------------------------------------------------------------------//
  const dispatch = useDispatch();
  //---------------------------------------------------------------------------------------------//
  const [selectedUnit, setSelectedUnit] = useState({});
  //---------------------------------------------------------------------------------------------//
  const english = useSelector((state) => state.english.active);
  //---------------------------------------------------------------------------------------------//
  const loading = useSelector((state) => state.generalPurposeLoader.loading);
  //---------------------------------------------------------------------------------------------//
  const searchResults = useSelector((state) => state.searchResults.searchResults);
  //---------------------------------------------------------------------------------------------//
  const availableCompanies = useSelector((state) => state.availableCompanies.availableCompanies);
  //---------------------------------------------------------------------------------------------//
  const availableMalls = useSelector((state) => state.availableMalls.availableMalls);
  //---------------------------------------------------------------------------------------------//
  const availableCompounds = useSelector((state) => state.availableCompounds.availableCompounds);
  //---------------------------------------------------------------------------------------------//
  const availableLocations = useSelector((state) => state.availableLocations.availableLocations);
  //---------------------------------------------------------------------------------------------//
  //fetching more data based on (route parameters) or (filters)
  // function showMore() {
  //   //TODO:fetch new data based on (params.category) or (filters applied) && and updated searchResults property in pagniation
  //   if (searchResults.filters === false) {
  //   } else if (searchResults.filters === true) {
  //   }
  // }
  //---------------------------------------------------------------------------------------------//
  function viewPropertyDetails(index) {
    setSelectedUnit(searchResults[index]);
    const myModal = new bootstrap.Modal(document.getElementById("view-details-modal"));
    myModal.show();
  }
  //----------------------------------------------------------------------------------//
  async function loadPage() {
    dispatch(searchPage(params.category, location));
  }
  //---------------------------------------------------------------------------------------------//
  useEffect(() => {
    loadPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //---------------------------------------------------------------------------------------------//
  return (
    <Fragment>
      <>
        <SearchPageImage
          image={slide1}
          imageHeader1={english ? "One step to Achieve" : "خطوة واحدة لتحقيق"}
          imageHeader2={english ? "your dream. Start Now" : "حلمك. ابدأ الآن"}
          imageText={
            english
              ? "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been."
              : ""
          }
        ></SearchPageImage>
        <SearchResults
          viewPropertyDetails={viewPropertyDetails}
          searchResults={searchResults}
          availableLocations={availableLocations}
        ></SearchResults>
        {location.pathname.includes("/investment/search") && <AvailableMalls availableMalls={availableMalls} />}
        {location.pathname.includes("/residential/search") && (
          <AvailableCompounds availableCompounds={availableCompounds} />
        )}
        <AvailableCompanies
          availableCompanies={availableCompanies}
          text={english ? "Search With Companies" : "أبحث عن الشركات"}
        ></AvailableCompanies>
        <AvailableLocations availableLocations={availableLocations} />
        <ViewDetailsModal
          property={!selectedUnit.id ? undefined : selectedUnit} //returns undefined if not found
        ></ViewDetailsModal>
        <SuccessBooking property={selectedUnit} />
        <SuccessFavourite property={selectedUnit} />
      </>

      {loading && <Loader />}
    </Fragment>
  );
};
export default SearchPage;
