import { useNavigate } from "react-router-dom";
import errorImage from "../../assets/error/illustration_500.svg";
//--------------------------------------------------------------//
//--------------------------------------------------------------//
const Error = ({ message }) => {
  const navigate = useNavigate();
  return (
    <div
      className="w-100 d-flex flex-column justify-content-center align-items-center mt-5"
      style={{ height: "100vh" }}
    >
      <h3 className="mb-2 text-secondary">{message}</h3>
      <img src={errorImage} width="300" height="300" alt=""></img>
      <button
        className="btn btn-primary"
        onClick={() => {
          navigate(-1);
        }}
      >
        Go back
      </button>
    </div>
  );
};

export default Error;
