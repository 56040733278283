import React from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import "./successBooking.css";
//-----------------------------------------------------------------------------------------//
const SuccessBooking = ({ property }) => {
  const navigate = useNavigate();
  const location = useLocation();
  //--------------------------------------------------------------------------------------//
  const english = useSelector((state) => state.english.active);
  return (
    <div
      id="success-booking-modal"
      className="modal"
      tabIndex="-1"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered success-booking-modal-dialog">
        <div className="modal-content success-booking-modal-content">
          <div className="modal-header success-booking-modal-header">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body text-center">
            <div className="mb-4">
              <CheckCircleOutlineIcon
                className="text-success"
                style={{
                  width: "20%",
                  height: "20%",
                  margin: "auto",
                }}
              />
            </div>
            <h4 className="text-darkblue">
              {english
                ? "You have successfully booked this unit"
                : "تم حجز الوحدة بنجاح"}
            </h4>
            {english ? (
              <p className="text-secondary">
                {/* ------------- */}
                You have successfully booked unit number ({property.name})
                <br></br>
                {/* ------------- */}
                {property.projectType === "compound" &&
                  property.blockNumber &&
                  `in block ${property.blockNumber}`}
                {/* ------------- */}
                {property.propertyFloor === 0
                  ? ``
                  : ` (floor ${property.propertyFloor}) `}
                {/* ------------- */}
                in ({property.projectName})<br></br>
                {/* ------------- */}
                you can confirm booking by checking your booked list.
              </p>
            ) : (
              <p className="text-secondary">
                {/* ------------- */}
                لقد قمت بحجز الوحدة رقم ({property.nameArabic})<br></br>
                {/* ------------- */}
                {property.projectType === "compound" &&
                  property.blockNumber &&
                  `فى مبنى ${property.blockNumberArabic}`}
                {/* ------------- */}
                {property.propertyFloor === 0
                  ? ""
                  : ` (طابق ${property.propertyFloor?.toLocaleString(
                      "ar-u-nu-arab"
                    )})`}
                {/* ------------- */}
                <br></br>
                في ({property.projectNameArabic})<br></br>
                {/* ------------- */}
                يمكنك تأكيد الحجز عن طريق مراجعة قائمة الحجز
                {/* ------------- */}
              </p>
            )}
            {!location.pathname.includes("/search") && (
              <Link
                className="btn btn-outline-primary btn-lg"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  navigate("/booking");
                }}
              >
                {english ? "View Booked List" : "عرض قائمة الحجز"}
              </Link>
            )}
            {location.pathname.includes("/search") && (
              <button
                className="btn btn-outline-primary btn-lg"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                {english ? "Confirm" : "تأكيد"}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuccessBooking;
