import english from "./english";
import searchResults from "./searchResults";
import residentialPropertiesList from "./residentialPropertiesList";
import investmentPropertiesList from "./investmentPropertiesList";
import availableMalls from "./availableMalls";
import availableCompounds from "./availableCompounds";
import availableCompanies from "./availableCompanies";
import availableLocations from "./availableLocations";
import latestProjects from "./latestProjects";
import bestClientReviews from "./bestClientReviews";
import selectedMall from "./selectedMall";
import selectedCompound from "./selectedCompound";
import selectedCompany from "./selectedCompany";
import selectedLocation from "./selectedLocation";
import generalPurposeLoader from "./generalPurposeLoader";
import user from "./user";
import { combineReducers } from "redux";
//----------------------------------------------------//
const allReducers = combineReducers({
  english: english,
  searchResults,
  residentialPropertiesList,
  investmentPropertiesList,
  latestProjects,
  availableMalls,
  bestClientReviews,
  availableCompounds,
  availableCompanies,
  availableLocations,
  selectedMall,
  selectedCompound,
  selectedCompany,
  selectedLocation,
  generalPurposeLoader,
  user,
});

export default allReducers;
