let defaultState = {
  selectedCompany: {},
  loading: true,
  errorMessage: "",
};

const selectedCompany = (state = defaultState, action) => {
  if (action.type === "SET_SELECTED_COMPANY") {
    return {
      ...state,
      selectedCompany: action.payload.selectedCompany,
      loading: action.payload.loading,
      errorMessage: action.payload.errorMessage,
    };
  } else {
    return { ...state };
  }
};

export default selectedCompany;
