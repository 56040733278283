import React, { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateUser } from "../../../../actions/updateUser";
import "./personalDetailsTab.css";
//---------------------------------------------------------------------------------------------//
const PersonalDetailsTab = ({
  loggedUserData,
  editingName,
  setEditingName,
  editingEmail,
  setEditingEmail,
  editingPhone,
  setEditingPhone,
  editingGender,
  setEditingGender,
}) => {
  const dispatch = useDispatch();
  //---------------------------------------------------------------//
  const [name, setName] = useState(loggedUserData.name);
  //---------------------------------------------------------------//
  const [phone, setPhone] = useState(loggedUserData.phone);
  //---------------------------------------------------------------//
  const [gender, setGender] = useState(loggedUserData.gender);
  //---------------------------------------------------------------//
  const english = useSelector((state) => state.english.active);
  //---------------------------------------------------------------//
  async function handleUpdate(e) {
    if (e.target.name === "name") {
      dispatch(updateUser("name", name, loggedUserData));
      setEditingName(!editingName);
    }
    if (e.target.name === "phone") {
      dispatch(updateUser("phone", phone, loggedUserData));
      setEditingPhone(!editingPhone);
    }
    if (e.target.name === "gender") {
      dispatch(updateUser("gender", gender, loggedUserData));
      setEditingGender(!editingGender);
    }
  }
  //---------------------------------------------------------------//
  return (
    <Fragment>
      <div className={english ? "personal-details-first-section" : "personal-details-first-section-arabic"}>
        <div className="personal-details-header-wrapper">
          <h1 className="text-darkblue text-bold ">{english ? "Personal Details" : "البيانات الشخصية"}</h1>
          {english && <p className="text-secondary">Update your info and find out how it's used.</p>}
          {!english && <p className="text-secondary">حدث بياناتك واكتشف كيف يتم استخدامها</p>}
        </div>
        <div className="personal-details-profile-picture-wrapper">
          <img src={loggedUserData.profilePicture} alt=""></img>
        </div>
      </div>
      <div>
        {/* --------------------------------------------- */}
        {/* ---------------------name-------------------- */}
        {/* --------------------------------------------- */}
        <div
          className={
            english ? "personal-details-input-field-wrapper mb-4" : "personal-details-input-field-wrapper-arabic mb-4"
          }
        >
          <span className="text-darkblue text-bold personal-details-field-label">{english ? " Name" : "الاسم"}</span>
          <input
            placeholder={loggedUserData.name}
            disabled={!editingName}
            onChange={(e) => {
              setName(e.target.value);
            }}
          ></input>
          <div>
            {!editingName && (
              <button
                className="btn btn-link"
                onClick={() => {
                  setEditingName(!editingName);
                }}
              >
                {english && (
                  <>
                    {english && (
                      <>
                        Edit <i className="fa fa-edit"></i>
                      </>
                    )}
                    {!english && (
                      <>
                        <i className="fa fa-edit"></i>
                        تعديل
                      </>
                    )}
                  </>
                )}
                {!english && (
                  <>
                    <i className="fa fa-edit"></i>
                    تعديل
                  </>
                )}
              </button>
            )}
            {editingName && (
              <Fragment>
                <span
                  className="text-danger me-2"
                  role="button"
                  onClick={() => {
                    setEditingName(!editingName);
                  }}
                >
                  {english && (
                    <>
                      Cancel <i className="fa fa-close"></i>
                    </>
                  )}
                  {!english && (
                    <>
                      <i className="fa fa-close ms-1"></i>
                      الغاء
                    </>
                  )}
                </span>
                <button
                  name="name"
                  className="btn btn-primary btn-sm personal-details-save-button"
                  onClick={(e) => {
                    handleUpdate(e);
                  }}
                >
                  {english ? "Save" : "حفظ"}
                </button>
              </Fragment>
            )}
          </div>
        </div>
        {/* --------------------------------------------- */}
        {/* --------------------email-------------------- */}
        {/* --------------------------------------------- */}
        <div
          className={
            english ? "personal-details-input-field-wrapper mb-4" : "personal-details-input-field-wrapper-arabic mb-4"
          }
        >
          <span className="text-darkblue text-bold personal-details-field-label">
            {english ? "Email" : "البريد الالكتروني"}
          </span>
          <input placeholder={loggedUserData.email} disabled={!editingEmail}></input>
          <div>
            {!editingEmail && (
              <button
                className="btn btn-link"
                disabled
                // onClick={() => {
                //   setEditingEmail(!editingEmail);
                // }}
              >
                {english && (
                  <>
                    Edit <i className="fa fa-edit"></i>
                  </>
                )}
                {!english && (
                  <>
                    <i className="fa fa-edit"></i>
                    تعديل
                  </>
                )}
              </button>
            )}
            {editingEmail && (
              <Fragment>
                <span
                  className="text-danger me-2"
                  role="button"
                  onClick={() => {
                    setEditingEmail(!editingEmail);
                  }}
                >
                  {english && (
                    <>
                      Cancel <i className="fa fa-close"></i>
                    </>
                  )}
                  {!english && (
                    <>
                      <i className="fa fa-close ms-1"></i>
                      الغاء
                    </>
                  )}
                </span>
                <button className="btn btn-primary btn-sm personal-details-save-button">
                  {english ? "Save" : "حفظ"}
                </button>
              </Fragment>
            )}
          </div>
        </div>
        {/* --------------------------------------------- */}
        {/* ---------------------phone------------------- */}
        {/* --------------------------------------------- */}
        <div
          className={
            english ? "personal-details-input-field-wrapper mb-4" : "personal-details-input-field-wrapper-arabic mb-4"
          }
        >
          <span className="text-darkblue text-bold personal-details-field-label">{english ? "Phone" : "الهاتف"}</span>
          <input
            placeholder={loggedUserData.phone}
            disabled={!editingPhone}
            onChange={(e) => {
              setPhone(e.target.value);
            }}
          ></input>
          <div>
            {!editingPhone && (
              <button
                className="btn btn-link"
                onClick={() => {
                  setEditingPhone(!editingPhone);
                }}
              >
                {english && (
                  <>
                    Edit <i className="fa fa-edit"></i>
                  </>
                )}
                {!english && (
                  <>
                    <i className="fa fa-edit"></i>
                    تعديل
                  </>
                )}
              </button>
            )}
            {editingPhone && (
              <Fragment>
                <span className="text-danger me-2" role="button" onClick={() => setEditingPhone(!editingPhone)}>
                  {english && (
                    <>
                      Cancel <i className="fa fa-close"></i>
                    </>
                  )}
                  {!english && (
                    <>
                      <i className="fa fa-close ms-1"></i>
                      الغاء
                    </>
                  )}
                </span>
                <button
                  name="phone"
                  className="btn btn-primary btn-sm personal-details-save-button"
                  onClick={(e) => {
                    handleUpdate(e);
                  }}
                >
                  {english ? "Save" : "حفظ"}
                </button>
              </Fragment>
            )}
          </div>
        </div>
        {/* --------------------------------------------- */}
        {/* --------------------gender------------------- */}
        {/* --------------------------------------------- */}
        <div
          className={
            english ? "personal-details-input-field-wrapper mb-4" : "personal-details-input-field-wrapper-arabic mb-4"
          }
        >
          <span className="text-darkblue text-bold personal-details-field-label">{english ? "Gender" : "الجنس"}</span>
          <select
            disabled={!editingGender}
            onChange={(e) => {
              setGender(e.target.value);
            }}
          >
            <option selected={loggedUserData.gender === "male" ? true : false} value="male">
              {english ? "Male" : "ذكر"}
            </option>
            <option selected={loggedUserData.gender === "female" ? true : false} value="female">
              {english ? "Female" : "انثى"}
            </option>
          </select>
          <div>
            {!editingGender && (
              <button
                className="btn btn-link"
                onClick={() => {
                  setEditingGender(!editingGender);
                }}
              >
                {english && (
                  <>
                    Edit <i className="fa fa-edit"></i>
                  </>
                )}
                {!english && (
                  <>
                    <i className="fa fa-edit"></i>
                    تعديل
                  </>
                )}
              </button>
            )}
            {editingGender && (
              <Fragment>
                <span
                  className="text-danger me-2"
                  role="button"
                  onClick={() => {
                    setEditingGender(!editingGender);
                  }}
                >
                  {english && (
                    <>
                      Cancel <i className="fa fa-close"></i>
                    </>
                  )}
                  {!english && (
                    <>
                      <i className="fa fa-close ms-1"></i>
                      الغاء
                    </>
                  )}
                </span>
                <button
                  name="gender"
                  className="btn btn-primary btn-sm personal-details-save-button"
                  onClick={(e) => {
                    handleUpdate(e);
                  }}
                >
                  {english ? "Save" : "حفظ"}
                </button>
              </Fragment>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default PersonalDetailsTab;
