import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import "./residentialProperties.css";
//-------------------------------------------------------------------------------------------------------//
const ResidentialProperties = () => {
  //-------------------------------------------------------------------------------------------------------//
  const english = useSelector((state) => state.english.active);
  //-------------------------------------------------------------------------------------------------------//
  const residentialPropertiesList = useSelector((state) => state.residentialPropertiesList.residentialPropertiesList);
  //-------------------------------------------------------------------------------------------------------//
  function getCategoryName(category) {
    if (!english) {
      switch (category) {
        case "apartments":
          return "شقق سكنية";
        case "duplex villas":
          return "فيلات دوبلكس";
        case "detached villas":
          return "فيلات منفصلة";
        case "twin house":
          return "توين هاوس";
        case "studio":
          return "ستوديو";
        case "hotel units":
          return "وحدات فندقية";
        default:
          return "";
      }
    } else return category.charAt(0).toUpperCase() + category.slice(1);
  }
  //---------------------------------------------------------------------------------------------//
  useEffect(() => {
    //fetch residential properties list
    //payload is array as follows returned from backend
    // dispatch({
    //   type: "UPDATE_HOME_RESIDENTIAL_LIST",
    //   payload: [
    //     { category: "apartments", results: 500 },
    //     { category: "duplex villas", results: 704 },
    //     { category: "detached villas", results: 1300 },
    //     { category: "twin house", results: 130 },
    //     { category: "studio", results: 30 },
    //     { category: "hotel units", results: 100 },
    //   ],
    // });
  }, []);
  //-------------------------------------------------------------------------------------------------------//
  return (
    <div className="custom-residential-properties-wrapper">
      <div className={english ? "custom-residential-properties" : "custom-residential-properties-arabic"}>
        <h2 className="text-darkblue text-bold mb-4 mt-5">{english ? "Residential Properties" : "وحدات سكنية"}</h2>
        <div className={english ? "custom-residential-properties-container" : "custom-residential-properties-container-arabic"}>
          {residentialPropertiesList.map((element) => (
            <div className={english ? "custom-residential-properties-element" : "custom-residential-properties-element-arabic"}>
              <div className="custom-residential-properties-image-button">
                <img className="trial" src={element.source} alt=""></img>
                <Link to={`/residential/search/${element.category}`} className="btn btn-primary residential-absolute-more-button">
                  {english ? "More" : "المزيد"}
                </Link>
              </div>
              <span className="text-darkblue text-bold">{getCategoryName(element.category)}</span>
              {english ? (
                <span className="text-secondary">
                  <i className="fa fa-search me-2"></i>
                  {element.results} result
                </span>
              ) : (
                <span className="text-secondary">
                  {element.results?.toLocaleString("ar-u-nu-arab")} {"نتيجة"} <i className="fa fa-search me-2"></i>
                </span>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ResidentialProperties;
