import apartments from "../assets/residentialProperties/residentialApartments.png";
import duplexVillas from "../assets/residentialProperties/duplexVillas.jfif";
import detachedVillas from "../assets/residentialProperties/detachedVillas.jfif";
import twinHouse from "../assets/residentialProperties/twinHouse.png";
import studio from "../assets/residentialProperties/studio.jfif";
import hotelUnits from "../assets/residentialProperties/hotelUnits.png";
//---------------------------------------------------------------------------------//
let defaultState = {
  residentialPropertiesList: [
    { category: "apartments", source: apartments, results: 0 },
    { category: "duplex villas", source: duplexVillas, results: 0 },
    { category: "detached villas", source: detachedVillas, results: 0 },
    { category: "twin house", source: twinHouse, results: 0 },
    { category: "studio", source: studio, results: 0 },
    { category: "hotel units", source: hotelUnits, results: 0 },
  ],
};

const residentialPropertiesList = (state = defaultState, action) => {
  if (action.type === "UPDATE_HOME") {
    let payload = action.payload.residentialPropertiesList;
    payload.forEach((item) => (item.source = ""));
    // eslint-disable-next-line array-callback-return
    payload.map((item) => {
      if (item.category === "apartments") {
        item.source = apartments;
      } else if (item.category === "duplex villas") {
        item.source = duplexVillas;
      } else if (item.category === "detached villas") {
        item.source = detachedVillas;
      } else if (item.category === "twin house") {
        item.source = twinHouse;
      } else if (item.category === "studio") {
        item.source = studio;
      } else if (item.category === "hotel units") {
        item.source = hotelUnits;
      }
    });
    return { ...state, residentialPropertiesList: payload };
  } else {
    return { ...state };
  }
};

export default residentialPropertiesList;
