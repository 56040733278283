let defaultState = {
  selectedLocation: {},
  loading: true,
  errorMessage: "",
};

const selectedLocation = (state = defaultState, action) => {
  if (action.type === "SET_SELECTED_LOCATION") {
    return {
      ...state,
      selectedLocation: action.payload.selectedLocation,
      loading: action.payload.loading,
      errorMessage: action.payload.errorMessage,
    };
  } else {
    return { ...state };
  }
};

export default selectedLocation;
