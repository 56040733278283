let defaultState = {
  active: localStorage.getItem("english") === "true" ? true : false,
};

const english = (state = defaultState, action) => {
  if (action.type === "CHANGE_LANGUAGE") {
    localStorage.setItem("english", action.payload);
    return {
      ...state,
      active: action.payload,
    };
  } else {
    return { ...state };
  }
};

export default english;
