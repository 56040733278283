import React from "react";
import { useSelector } from "react-redux";
import companyProfileBackgroundImage from "../../../../assets/companyProfile/background.jpg";
import "./topImage.css";
//---------------------------------------------------------------------------------------------//
const CompanyProfileTopImage = ({ logo, locationName, acres, propertiesNumber, companies, map }) => {
  //---------------------------------------------------------------------------------//
  const english = useSelector((state) => state.english.active);
  //---------------------------------------------------------------------------------//
  return (
    <div className="location-top-image-wrapper">
      <div className="location-top-image-inner">
        <div className="location-top-image-slide">
          <img src={companyProfileBackgroundImage} className="location-top-image" alt="..."></img>
          <div className="location-top-image-container text-light text-center">
            <div className={english ? "location-top-image-subcontainer" : "location-top-image-subcontainer-arabic"}>
              {/* -------------------------------------------------- */}
              <img src={logo} className="location-logo" width="150px" alt=""></img>
              {/* -------------------------------------------------- */}
              <div className={english ? "location-data" : "location-data-arabic"}>
                <div className="location-name text-bold">{locationName}</div>
                <div className={english ? "location-acres-properties-companies" : "location-acres-properties-companies-arabic"}>
                  <span>
                    {english && <i className="fa fa-pie-chart me-1"></i>}
                    {english ? <>{acres} Acres</> : <>{acres?.toLocaleString("ar-u-nu-arab")} فدان</>}
                    {!english && <i className="fa fa-pie-chart ms-1"></i>}
                  </span>
                  <span>
                    {english && <i className="fa fa-building-o me-2"></i>}
                    {propertiesNumber >= 1000 && (
                      <>
                        {english ? (
                          <>+{propertiesNumber}</>
                        ) : (
                          <>
                            أكثر من
                            {propertiesNumber?.toLocaleString("ar-u-nu-arab")}
                          </>
                        )}
                      </>
                    )}
                    {propertiesNumber < 1000 && <>{english ? propertiesNumber : propertiesNumber?.toLocaleString("ar-u-nu-arab")}</>}
                    {english ? "Property" : " وحدة"}
                    {!english && <i className="fa fa-building-o ms-2"></i>}
                  </span>
                  <span>
                    {english && <i className="fa fa-building-o me-2"></i>}
                    {english && <>{companies} Companies</>}
                    {!english && <>{companies?.toLocaleString("ar-u-nu-arab")} شركات</>}
                    {!english && <i className="fa fa-building-o ms-2"></i>}
                  </span>
                </div>
              </div>
              {/* -------------------------------------------------- */}
              <div className="location-map">
                <span role="button">
                  {english && (
                    <>
                      <i className="fa fa-map-o me-2 "></i> View In Map
                    </>
                  )}
                  {!english && (
                    <>
                      <i className="fa fa-map-o me-2 "></i>عرض في الخريطة
                    </>
                  )}
                </span>
              </div>
              {/* -------------------------------------------------- */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyProfileTopImage;
