let defaultState = {
  user: {
    userLoader: true,
    favouriteButtonLoader: false,
    bookingButtonLoader: false,
  },
};

const user = (state = defaultState, action) => {
  if (action.type === "SET_USER_REGISTER") {
    localStorage.setItem("capitalRefreshToken", action.payload.refresh);
    return {
      user: { ...state.user, ...action.payload, token: action.payload.access },
    };
  } else if (action.type === "SET_USER_LOGIN") {
    localStorage.setItem("capitalRefreshToken", action.payload.refresh);
    return {
      user: {
        ...state.user,
        ...action.payload.user_profile,
        token: action.payload.access,
      },
    };
  } else if (action.type === "GET_USER_FROM_ACCESS_TOKEN") {
    console.log({ ...state.user, ...action.payload });
    return {
      user: {
        ...state.user,
        ...action.payload,
      },
    };
  } else if (action.type === "SET_USER_LOGOUT") {
    return {
      user: {},
    };
  } else if (action.type === "RENEW_ACCESS_TOKEN") {
    return {
      user: {
        ...state.user,
        token: action.payload,
      },
    };
  } else if (action.type === "SET_TOKENS") {
    localStorage.setItem("capitalRefreshToken", action.payload.refresh);
    return {
      user: {
        ...state.user,
        token: action.payload.access,
      },
    };
  } else if (action.type === "UPDATE_USER") {
    return {
      user: {
        ...state.user,
        ...action.payload,
        token: state.user.token,
      },
    };
  } else if (action.type === "SET_USER_FAVOURITE_LIST") {
    return {
      user: {
        ...state.user,
        favourite: [...action.payload],
      },
    };
  } else if (action.type === "SET_USER_BOOKING_LIST") {
    return {
      user: {
        ...state.user,
        booking: [...action.payload],
      },
    };
  } else if (action.type === "SET_FAVOURITE_BUTTON_LOADER") {
    return {
      user: {
        ...state.user,
        favouriteButtonLoader: action.payload,
      },
    };
  } else if (action.type === "SET_BOOKING_BUTTON_LOADER") {
    return {
      user: {
        ...state.user,
        bookingButtonLoader: action.payload,
      },
    };
  } else if (action.type === "SET_USER_LOADER") {
    return {
      user: {
        ...state.user,
        userLoader: action.payload,
      },
    };
  } else {
    return { ...state };
  }
};

export default user;
