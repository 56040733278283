import React from "react";
import { useSelector } from "react-redux";
import "./aboutSection.css";
//---------------------------------------------------------------------------------------------//
const AboutSection = ({
  aboutLocationImage,
  futureVisionImage,
  aboutLocationText,
  futureVisionText,
}) => {
  //---------------------------------------------------------------------------------//
  const english = useSelector((state) => state.english.active);
  //---------------------------------------------------------------------------------//
  return (
    <div className="location-about-section-wrapper mt-5">
      <div
        className={
          english
            ? "location-about-section-inner"
            : "location-about-section-inner-arabic"
        }
      >
        <div>
          <img
            src={aboutLocationImage}
            alt=""
            width="80"
            className="mb-3"
          ></img>
          <h4 className="text-bold text-darkblue">
            {english ? "About Location" : "نبذة عن الموقع"}
          </h4>
          <p className="text-secondary">{aboutLocationText}</p>
        </div>
        <div>
          <img src={futureVisionImage} alt="" width="80" className="mb-3"></img>
          <h4 className="text-bold text-darkblue">
            {english ? "Future Vision" : "الرؤية المستقبلية"}
          </h4>
          <p className="text-secondary">{futureVisionText}</p>
        </div>
      </div>
    </div>
  );
};

export default AboutSection;
