let defaultState = {
  bestClientReviews: [],
};

const bestClientReviews = (state = defaultState, action) => {
  if (action.type === "UPDATE_HOME") {
    return {
      ...state,
      bestClientReviews: action.payload.best_client_reviews,
    };
  } else {
    return { ...state };
  }
};

export default bestClientReviews;
