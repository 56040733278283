import { createBrowserRouter } from "react-router-dom";
import Root from "./components/RootPage/Root";
import HomePage from "./components/HomePage/HomePage";
import SignUp from "./components/SignUp/SignUp";
import FavouriteAndBookingPages from "./components/FavouriteAndBookingPages/index";
import SearchPage from "./components/SearchPage/searchPage";
import CompanyProfilePage from "./components/CompanyProfilePage/companyProfilePage";
import SingleLocationPage from "./components/SingleLocationPage/singleLocationPage";
import SingleMallPage from "./components/SingleMallPage/singleMallPage";
import SingleCompoundPage from "./components/SingleCompoundPage/singleCompoundPage";
import ViewMallUnits from "./components/viewMallUnits/viewMallUnits";
import ViewCompoundUnits from "./components/viewCompoundUnits/viewCompoundUnits";
import Profile from "./components/profile/profile";
import NotFound from "./components/ErrorPages/404";
import "./App.css";
import SignIn from "./components/SignIn/SignIn";
// ------------------------------------------------------------------------------------------//
export const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <NotFound />,
    children: [
      {
        path: "/",
        element: <HomePage />,
      },
      {
        path: "/home",
        element: <HomePage />,
      },
      {
        path: "/favourite",
        element: <FavouriteAndBookingPages />,
      },
      {
        path: "/booking",
        element: <FavouriteAndBookingPages />,
      },
      {
        path: "/residential/search/:category",
        element: <SearchPage />,
      },
      {
        path: "/investment/search/:category",
        element: <SearchPage />,
      },
      {
        path: "/company/profile/:id",
        element: <CompanyProfilePage />,
      },
      {
        path: "/location/info/:id",
        element: <SingleLocationPage />,
      },
      {
        path: "/mall/info/:id",
        element: <SingleMallPage />,
      },
      {
        path: "/compound/info/:id",
        element: <SingleCompoundPage />,
      },
      {
        path: "/mall/:units/:id",
        element: <ViewMallUnits />,
      },
      {
        path: "/compound/:units/:id",
        element: <ViewCompoundUnits />,
      },
      {
        path: "/me",
        element: <Profile />,
      },
    ],
  },
  {
    path: "/signup",
    element: <SignUp />,
  },
  {
    path: "/signin",
    element: <SignIn />,
  },
]);
