import React from "react";
import { useSelector } from "react-redux";
import CardItem from "../../../CardItem/cardItem";
import "./availableMalls.css";
//----------------------------------------------------------------------------------------------//
const AvailableMalls = ({ availableMalls }) => {
  //--------------------------------------------------------------------------------------------//
  const english = useSelector((state) => state.english.active);
  //--------------------------------------------------------------------------------------------//
  return (
    <div className="custom-available-malls-wrapper">
      {/* ---------------------------------------------- */}
      {/* ---------------------------------------------- */}
      <div
        className={
          english ? "custom-available-malls" : "custom-available-malls-arabic"
        }
      >
        {/* -------------------------------- */}
        {/* -------------------------------- */}
        <h1 className="text-darkblue text-bold mb-4 mt-5">
          {english ? "Search With Malls" : "أبحث عن المولات"}
        </h1>
        {/* -------------------------------- */}
        {/* -------------------------------- */}
        {availableMalls.length > 0 && (
          <div
            className={
              english
                ? "custom-available-malls-container"
                : "custom-available-malls-container-arabic"
            }
          >
            {availableMalls.map((element) => (
              <CardItem
                id={element.id}
                name={element.name}
                nameArabic={element.nameArabic}
                projectImage={element.projectImage}
                buildingFloors={element.buildingFloors}
                tags={element.tags}
                address={element.address}
                addressArabic={element.addressArabic}
                distance={element.distance}
                type="mall"
                buttonText={english ? "View Details" : "اعرض التفاصيل"}
                viewDetails="false"
              />
            ))}
          </div>
        )}
        {/* -------------------------------- */}
        {/* -------------------------------- */}
        {availableMalls.length === 0 && (
          <h3 className="text-darkblue text-center">
            {english ? "No Malls found" : "لا يوجد مولات"}
          </h3>
        )}
        {/* -------------------------------- */}
        {/* -------------------------------- */}
      </div>
      {/* ---------------------------------------------- */}
      {/* ---------------------------------------------- */}
      {availableMalls.length > 0 && (
        <div className="text-center m-4">
          <button
            className={
              english
                ? "btn btn-primary btn-lg show-more-button"
                : "btn btn-primary btn-lg show-more-button-arabic"
            }
          >
            {english ? "Show More" : "اعرض المزيد"}
            <i className="fa fa-angle-double-down angle-double-text m-2"></i>
          </button>
        </div>
      )}
      {/* ---------------------------------------------- */}
      {/* ---------------------------------------------- */}
    </div>
  );
};

export default AvailableMalls;
