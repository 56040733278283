import { toast } from "react-toastify";
import axios from "../axios.js";
//------------------------------//
const translations = {
  "User matching query does not exist.": "المستخدم غير موجود",
};
//------------------------------//
export function getAccessToken(refresh, english) {
  return async (dispatch) => {
    try {
      const response = await axios.post(`/token/refresh/`, { refresh });
      dispatch({ type: "SET_TOKENS", payload: response.data });
      return response;
    } catch (error) {
      console.log(error);
      //assuming when reftesh token is expired , response status code is 401
      if (error?.response?.status === 401) {
        alert("Session expired, please login again");
        localStorage.removeItem("capitalRefreshToken");
        window.location.reload();
      } else {
        localStorage.removeItem("capitalRefreshToken");
        if (english) {
          toast.error(error?.response?.data?.message || "Failed to get access token");
        } else {
          toast.error(translations[error?.response?.data?.message] || "قشل في الحصول على توثيق");
        }
      }
    }
  };
}
