import { toast } from "react-toastify";
import * as bootstrap from "bootstrap";
import axios from "../axios.js";
//------------------------------//
export function addToFavourite(user, property) {
  return async (dispatch) => {
    try {
      dispatch({
        type: "SET_FAVOURITE_BUTTON_LOADER",
        payload: true,
      });
      await axios.post(
        `/favourites/`,
        { unit: property.id },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      const myModal = new bootstrap.Modal(document.getElementById("success-favourite-modal"));
      myModal.show();
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data["message"] || "Failed to add unit to your favourite list");
    } finally {
      dispatch({
        type: "SET_FAVOURITE_BUTTON_LOADER",
        payload: false,
      });
    }
  };
}
//------------------------------//
export function getFavourite(user) {
  return async (dispatch) => {
    dispatch({
      type: "SET_LOADING",
      payload: true,
    });
    try {
      const response = await axios.get(`/favourites/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      });
      console.log(response.data);
      dispatch({
        type: "SET_USER_FAVOURITE_LIST",
        payload: response.data,
      });
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data["message"] || "Failed to get your favourite list");
    } finally {
      dispatch({
        type: "SET_LOADING",
        payload: false,
      });
    }
  };
}
//------------------------------//
export function deleteFavourite(user, id) {
  return async (dispatch) => {
    try {
      await axios.delete(`/favourites/${id}/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      });
      dispatch({
        type: "SET_USER_FAVOURITE_LIST",
        payload: user.favourite.filter((unit) => unit.id !== id),
      });
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data["message"] || "Failed to remove unit from your favourite list");
    }
  };
}
