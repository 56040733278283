import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import "./topImage.css";
//---------------------------------------------------------------------------------------------//
const SingleCompoundTopImage = ({ id, image, name, acres, propertiesNumber, compoundUnitsPrices, implementingCompany }) => {
  function getTagName(tag) {
    if (!english) {
      switch (tag) {
        case "Duplex Villas":
          return "فيلات دوبلكس";
        case "Apartments":
          return "شقق سكنية";
        case "Detached Villas":
          return "فيلات منفصلة";
        case "Twin Houses":
          return "توين هاوس";
        case "Studios":
          return "ستوديوهات";
        case "Hotel Units":
          return "وحدات فندقية";
        default:
          return "";
      }
    } else return tag;
  }
  //----------------------------------------------------------------------------------//
  const english = useSelector((state) => state.english.active);
  //----------------------------------------------------------------------------------//
  return (
    <div className="compound-top-image-wrapper">
      <div className="compound-top-image-inner">
        <div className="compound-top-image-slide">
          <img src={image} className="compound-background-image" alt="..."></img>
          <div className="compound-top-image-container text-light text-center">
            <div className="compound-top-image-subcontainer">
              <div className="compound-implemented-company-wrapper">
                <div className={english ? "compound-implemented-company" : "compound-implemented-company-arabic"}>
                  <img src={implementingCompany.logo} className="m-1" alt=""></img>
                  <div>
                    <span>{english ? "Implementing Company" : "الشركة المنفذة"}</span>
                    <span>
                      <Link to={`/company/profile/${implementingCompany.companyId}`} className="implemented-company-underline text-white">
                        {english ? implementingCompany.name : implementingCompany.nameArabic}
                      </Link>
                    </span>
                  </div>
                </div>
              </div>
              <div className="compound-data">
                {/* -------------------------------------------- */}
                <span className="compound-name text-bold">{name}</span>
                {/* -------------------------------------------- */}
                <div className="compound-acres-floors">
                  <div className="m-1">
                    <span>
                      {english && <i className="fa fa-pie-chart me-1"></i>}
                      {english && <>{acres} Acres </>}
                      {!english && <> {acres?.toLocaleString("ar-u-nu-arab")} فدان</>}
                      {!english && <i className="fa fa-pie-chart ms-1"></i>}
                    </span>
                  </div>
                  <div className="m-1">
                    <span>
                      {english && <i className="fa fa-building-o me-2"></i>}
                      {english && <>{propertiesNumber} property</>}
                      {!english && <>{propertiesNumber?.toLocaleString("ar-u-nu-arab")} وحدة</>}
                      {!english && <i className="fa fa-building-o ms-2"></i>}
                    </span>
                  </div>
                </div>
                {/* -------------------------------------------- */}
                <div className="compound-tags">
                  {compoundUnitsPrices.map((element, index) => (
                    <span className="badge bg-white text-primary me-2" key={index}>
                      {getTagName(element.tag)}
                    </span>
                  ))}
                </div>
                {/* -------------------------------------------- */}
                {/* <div className="compound-explore-now">
                  <Link to={`/compound/units/${id}`} className="btn btn-primary">
                    {!english && (
                      <i className="fa fa-angle-double-left me-1"></i>
                    )}
                    {english ? "Explore Units" : "اكتشف الوحدات"}
                    {english && (
                      <i className="fa fa-angle-double-right ms-1"></i>
                    )}
                  </Link>
                </div> */}
                {/* -------------------------------------------- */}
              </div>
              <div className={english ? "compound-map" : "compound-map-arabic"}>
                <span role="button">
                  <i className="fa fa-map-o me-2 "></i> {english ? "View In Map" : "عرض في الخريطة"}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleCompoundTopImage;
