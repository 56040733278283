import React, { Fragment, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import MallFloorView from "./mallFloorView/mallFloorView";
import PaymentSystemCalculator from "../paymentSystemCalculator/paymentSystemCalculator";
import SuccessBooking from "../successBooking/successBooking";
import SuccessFavourite from "../successFavourite/successFavourite";
import { useDispatch, useSelector } from "react-redux";
import { companyData } from "../../dummy";
import { getSingleMall } from "../../actions/getSingleMall";
import WhatsAppWidget from "react-whatsapp-chat-widget";
//----------------------------------------------------------------------------------//
const ViewMallUnits = () => {
  const params = useParams();
  //----------------------------------------------------------------------------------//
  const dispatch = useDispatch();
  //----------------------------------------------------------------------------------//
  const [currentFloorNumber, setCurrentFloorNumber] = useState(0);
  //----------------------------------------------------------------------------------//
  const [activeFloors, setActiveFloors] = useState(undefined);
  //----------------------------------------------------------------------------------//
  const [selectedUnit, setSelectedUnit] = useState({});
  //----------------------------------------------------------------------------------//
  const english = useSelector((state) => state.english.active);
  //----------------------------------------------------------------------------------//
  const selectedMall = useSelector((state) => state.selectedMall.selectedMall);
  //----------------------------------------------------------------------------------//
  const loading = useSelector((state) => state.selectedMall.loading);
  //----------------------------------------------------------------------------------//
  function getTagName(tag) {
    if (!english) {
      switch (tag) {
        case "commercial":
          return "تجارية";
        case "medical":
          return "طبية";
        case "administrative":
          return "ادارية";
        case "hotel":
          return "فندقية";
        default:
          return "";
      }
    } else return tag;
  }
  //----------------------------------------------------------------------------------//
  function loadPage() {
    dispatch(getSingleMall(params.id, english));
  }
  //----------------------------------------------------------------------------------//
  useEffect(() => {
    if (!selectedMall.id) {
      loadPage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //----------------------------------------------------------------------------------------------//
  useEffect(() => {
    if (selectedMall?.floorsFeatures?.length > 0) {
      setActiveFloors([...selectedMall?.floorsFeatures?.filter((floor) => floor.tag.toLowerCase() === params.units.toLowerCase())]);
    }
  }, [selectedMall, params]);
  //----------------------------------------------------------------------------------------------//
  return (
    <Fragment>
      {selectedMall.id && !loading && activeFloors?.length > 0 && (
        <>
          <MallFloorView
            mallData={selectedMall}
            activeFloors={activeFloors}
            setSelectedUnit={setSelectedUnit}
            selectedUnit={selectedUnit}
            currentFloorNumber={currentFloorNumber}
            setCurrentFloorNumber={setCurrentFloorNumber}
          ></MallFloorView>
          <PaymentSystemCalculator property={selectedUnit} />
          <SuccessBooking property={selectedUnit} />
          <SuccessFavourite property={selectedUnit} />
          <WhatsAppWidget
            phoneNo="+201009244362"
            position={english ? "right" : "left"}
            autoOpen={true}
            autoOpenTimer={5000}
            iconSize="60"
            iconColor="white"
            iconBgColor="#25D366"
            headerIcon={companyData.logo} //should be replaced by Alassema logo
            headerTitle={english ? "Alassema" : "العاصمة"}
            btnTxt={english ? "Start Chat" : "ابدأ الدردشة"}
            messageBoxTxt={english ? `I want to know more about ${selectedMall.name}` : `أرجو معرفة المزيد عن ${selectedMall.nameArabic}`}
            chatMessage={
              english ? (
                <>
                  Hi there 👋 <br />
                  <br /> How can I help you?
                </>
              ) : (
                <>
                  مرحبا 👋 <br />
                  <br />
                  كيف تريد ان نساعدك؟
                </>
              )
            }
            messageBox={false}
          ></WhatsAppWidget>
        </>
      )}
      {selectedMall.id && !loading && activeFloors?.length <= 0 && (
        <h3 className="text-center">{english ? `No ${getTagName(params.units)} units found ` : `لا توجد وحدات ${getTagName(params.units)}`}</h3>
      )}
      {(loading || (selectedMall.id && !loading && !activeFloors)) && <h3 className="text-center">Loading...</h3>}
      {!loading && !selectedMall.id && <h3 className="text-center">No matching result</h3>}
    </Fragment>
  );
};
export default ViewMallUnits;
