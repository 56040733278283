import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import "./investmentProperties.css";
//----------------------------------------------------------------------------------------------------//
const InvestmentProperties = () => {
  //-------------------------------------------------------------------------------------------------//
  const english = useSelector((state) => state.english.active);
  //-------------------------------------------------------------------------------------------------------//
  const investmentPropertiesList = useSelector((state) => state.investmentPropertiesList.investmentPropertiesList);
  //-------------------------------------------------------------------------------------------------//
  function getCategoryName(category) {
    if (!english) {
      switch (category) {
        case "coffee shops":
          return "كافيهات";
        case "restaurants":
          return "مطاعم";
        case "shops":
          return "محلات";
        case "offices":
          return "مكاتب ادارية";
        case "partitions":
          return "بارتشن";
        case "pharmacies":
          return "صيدليات";
        case "clinics":
          return "عيادات";
        case "hotel units":
          return "وحدات فندقية";
        default:
          return "";
      }
    } else return category.charAt(0).toUpperCase() + category.slice(1);
  }
  //---------------------------------------------------------------------------------------------//
  useEffect(() => {
    //fetch residemtial properties list
    //payload is array as follows returned from backend
    // dispatch({
    //   type: "UPDATE_HOME_INVESTMENT_LIST",
    //   payload: [
    //     { category: "coffee shops", results: 200 },
    //     { category: "restaurants", results: 40 },
    //     { category: "shops", results: 560 },
    //     { category: "offices", results: 123 },
    //     { category: "partitions", results: 500 },
    //     { category: "pharmacies", results: 40 },
    //     { category: "clinics", results: 60 },
    //     { category: "hotel units", results: 201 },
    //   ],
    // });
  }, []);
  //-------------------------------------------------------------------------------------------------//
  return (
    <div className="custom-investment-properties-wrapper">
      <div className={english ? "custom-investment-properties" : "custom-investment-properties-arabic"}>
        <h2 className="text-darkblue text-bold mb-4 mt-5">{english ? "Investment Properties" : "وحدات استثمارية"}</h2>
        <div className={english ? "custom-investment-properties-container" : "custom-investment-properties-container-arabic"}>
          {investmentPropertiesList.map((element) => (
            <div className={english ? "custom-investment-properties-element" : "custom-investment-properties-element-arabic"} key={element.id}>
              <div className="custom-investment-properties-image-button">
                <img className="trial" src={element.source} alt=""></img>
                <Link to={`/investment/search/${element.category}`} className="btn btn-primary absolute-more-button">
                  {english ? "More" : "المزيد"}
                </Link>
              </div>
              <span className="text-darkblue text-bold">{getCategoryName(element.category)}</span>
              {english ? (
                <span className="text-secondary">
                  <i className="fa fa-search me-1"></i>
                  {element.results} result
                </span>
              ) : (
                <span className="text-secondary">
                  {element.results?.toLocaleString("ar-u-nu-arab")} {"نتيجة"} <i className="fa fa-search ms-1"></i>
                </span>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default InvestmentProperties;
