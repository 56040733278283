import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import calculator from "../../assets/paymentCalculator/calculator.jpg";
import "./paymentSystemCalculator.css";
//------------------------------------------------------------------------//
const PaymentSystemCalculator = ({ property }) => {
  //------------------------------------------------------------------------------------------------//
  const english = useSelector((state) => state.english.active);
  const [planIndex, setPlanIndex] = useState(0);
  //------------------------------------------------------------------------------------------------//
  useEffect(() => {
    setPlanIndex(0);
  }, [property]);
  //------------------------------------------------------------------------------------------------//
  return (
    <div className="payment-system-calculator-wrapper mt-5">
      <div className={english ? "payment-system-calculator-inner" : "payment-system-calculator-inner-arabic"}>
        {/* ------------------------------------------------------------------------- */}
        {/* ------------------------------------------------------------------------- */}
        {/* ------------------------------------------------------------------------- */}
        <h3 className="text-darkblue text-bold">
          {english && <i className="fa fa-calculator me-1 text-primary"></i>}
          {english ? "Payment System Calculator" : "الآلة الحاسبة"}
          {!english && <i className="fa fa-calculator ms-1 text-primary"></i>}
        </h3>
        {/* ------------------------------------------------------------------------- */}
        {/* ------------------------------------------------------------------------- */}
        {/* ------------------------------------------------------------------------- */}
        <p className="text-secondary ms-4">
          {english ? "Check out your payment schedule with our smart calculator." : "راجع جدول المدفوعات باستخدام آلتنا الحاسبة الذكية"}
        </p>
        {/* ------------------------------------------------------------------------- */}
        {/* ------------------------------------------------------------------------- */}
        {/* ------------------------------------------------------------------------- */}
        {!property.id && (
          <div className="text-center">
            <img src={calculator} alt=""></img>
            <h4 className="text-darkblue text-bold">{english ? "No data found" : "لا يوجد بيانات"}</h4>
            <p className="text-secondary">
              {english ? "Please start calculating by selecting unit from the current floor" : "ابدأ حساباتك عن طريق اختيار وحدة من الطابق الحالي"}
            </p>
          </div>
        )}
        {/* ------------------------------------------------------------------------- */}
        {/* ------------------------------------------------------------------------- */}
        {/* ------------------------------------------------------------------------- */}
        {property.id && (
          <table className="table payment-system-calculator-table">
            <thead>
              <tr>
                <th scope="col" className="text-darkblue align-middle">
                  {english ? "Deposit %" : "المقدم %"}
                </th>
                <th scope="col" className="text-darkblue align-middle">
                  {english ? "Installment Years" : "عدد السنين"}
                </th>
                <th scope="col" className="text-darkblue align-middle">
                  {english ? (
                    <>
                      Yearly <br></br> Installment
                    </>
                  ) : (
                    "قسط سنوي"
                  )}
                </th>
                <th scope="col" className="text-darkblue align-middle">
                  {english ? (
                    <>
                      1/4 Yearly <br></br> Installment
                    </>
                  ) : (
                    "قسط ربع سنوي"
                  )}
                </th>
                <th scope="col" className="text-darkblue align-middle">
                  {english ? (
                    <>
                      1/2 Month <br></br>Installment
                    </>
                  ) : (
                    "قسط نصف شهري"
                  )}
                </th>
              </tr>
            </thead>
            <tbody className="table-light text-secondary">
              {property.plans.length > 0 && (
                <tr>
                  {/* ---------------------------- */}
                  <td className="align-middle">
                    <select
                      id="payment-system-calculator-select"
                      onChange={(e) => {
                        setPlanIndex(e.target.value);
                      }}
                    >
                      <>
                        {property.plans.map((plan, index) => (
                          <option key={plan.deposit + plan.years} value={index} selected={index === planIndex ? true : false}>
                            {english ? plan.deposit : plan.deposit?.toLocaleString("ar-u-nu-arab")}
                          </option>
                        ))}
                      </>
                    </select>
                  </td>
                  {/* ---------------------------- */}
                  <td className="align-middle">
                    {english ? property.plans[planIndex].years : <>{property.plans[planIndex].years?.toLocaleString("ar-u-nu-arab")}</>}
                  </td>
                  {/* ---------------------------- */}
                  <td className="align-middle">
                    {english ? (
                      <>{property.plans[planIndex].yearInstallment} EGP</>
                    ) : (
                      <>{property.plans[planIndex].yearInstallment?.toLocaleString("ar-u-nu-arab")} جنيه مصري</>
                    )}
                  </td>
                  {/* ---------------------------- */}
                  <td className="align-middle">
                    {english ? (
                      <>{property.plans[planIndex].quarterYearInstallment} EGP</>
                    ) : (
                      <>{property.plans[planIndex].quarterYearInstallment?.toLocaleString("ar-u-nu-arab")} جنيه مصري</>
                    )}
                  </td>
                  {/* ---------------------------- */}
                  <td className="align-middle">
                    {english ? (
                      <>{property.plans[planIndex].halfMonthInstallment} EGP</>
                    ) : (
                      <>{property.plans[planIndex].halfMonthInstallment?.toLocaleString("ar-u-nu-arab")} جنيه مصري</>
                    )}
                  </td>
                  {/* ---------------------------- */}
                </tr>
              )}
              {property.plans.length === 0 && <h4 className="text-darkblue text-bold">{english ? "No data found" : "لا يوجد بيانات"}</h4>}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default PaymentSystemCalculator;
