import pyramids from "./assets/malls/pyramids.jpg";
import propertyCAD from "./assets/viewDetailsModal/propertyCAD.jpg";
import popularBrandsImage from "./assets/singleMallPage/brands.jpg";
import mallFloorView from "./assets/singleMallUnits/mallFloorView.jpg";
import hotel from "./assets/singleMallPage/hotel.jpg";
import commercial from "./assets/singleMallPage/commercial.jpg";
import medical from "./assets/singleMallPage/medical.jpg";
import administrative from "./assets/singleMallPage/administrative.jpg";
import image from "./assets/singleMallPage/pyramids.jpg";
import star from "./assets/singleCompoundPage/star.jpg";
import goldenTower from "./assets/malls/goldenTower.jpg";
import borouj from "./assets/latestProjects/borouj.jpg";
import TAD from "./assets/latestProjects/TAD.jpg";
import logo from "./assets/malls/logo.png";
import logo1 from "./assets/availableCompanies/1.jpg";
import companyImage from "./assets/companyProfile/companyImage.jpg";
import profilePicture from "./assets/clientReviews/profilePicture.jpg";
import dropPinImg from "./assets/dropPinImg/dropPinImg.jpg";
import locationCardImage from "./assets/availableLocations/locationCardImage.jpg";
import coffeeShops from "./assets/investmentProperties/coffeeShops.png";
import restaurants from "./assets/investmentProperties/restaurants.png";
import shops from "./assets/investmentProperties/shops.png";
import offices from "./assets/investmentProperties/administrativeOffices.png";
import partitions from "./assets/investmentProperties/partitions.png";
import pharmacies from "./assets/investmentProperties/pharmacies.png";
import clinics from "./assets/investmentProperties/clinics.png";
import hotels from "./assets/investmentProperties/hotels.png";
import apartments from "./assets/residentialProperties/residentialApartments.png";
import duplexVillas from "./assets/residentialProperties/duplexVillas.jfif";
import detachedVillas from "./assets/residentialProperties/detachedVillas.jfif";
import twinHouse from "./assets/residentialProperties/twinHouse.png";
import studio from "./assets/residentialProperties/studio.jfif";
import hotelUnits from "./assets/residentialProperties/hotelUnits.png";
//---------------------------------------------------------------------------------------------//
export const mallData = {
  id: "1",
  backgroundImage: image, //used as background image in html
  name: "Pyramids Mall",
  nameArabic: "بيراميدز مول",
  address: "24 flowers street, New capital city", //max 30 characters
  addressArabic: "شارع الزهور، العاصمة الجديدة", //max 30 characters
  projectImage: pyramids, //used as card image
  latestProjectImage: TAD, //used as latest project image
  buildingFloors: 8,
  tags: ["Commercial", "Medical", "Hotel"],
  distance: 320,
  implementingCompany: {
    companyId: "1",
    name: "Home Town Developments",
    nameArabic: "هوم تاون للتنمية العقارية",
    logo: logo,
  },
  type: "mall",
  acres: 1364,
  mallUnitsPrices: [
    {
      tag: "Commercial",
      min: 20,
      max: 120,
      type: "K", // K:thousands M:millions
      typeArabic: "الف",
      image: commercial,
    },
    {
      tag: "Medical",
      min: 20,
      max: 120,
      type: "K", // K:thousands M:millions
      typeArabic: "الف",
      image: medical,
    },
    {
      tag: "Hotel",
      min: 20,
      max: 120,
      type: "K", // K:thousands M:millions
      typeArabic: "الف",
      image: hotel,
    },
    {
      tag: "Administrative",
      min: 20,
      max: 120,
      type: "K", // K:thousands M:millions
      typeArabic: "الف",
      image: administrative,
    },
  ],
  floorsFeatures: [
    {
      number: 1,
      tag: "commercial",
      area: 700,
      layout: mallFloorView,
      features: ["Feature 1 Here", "Feature 2 Here", "Feature 3 Here"],
      featuresArabic: ["الميزة الأولى", "الميزة الثانية", "الميزة الثالثة"],
      units: [
        {
          id: "1",
          name: "coffee shop No.10",
          nameArabic: "كافيه رقم ٦٠",
          propertyCAD: [{ floor: 1, CAD: propertyCAD }], // array of floors & CAD for each floor
          unitImage: coffeeShops,
          tags: ["Investment"],
          area: 300,
          pricePerMeter: 1600,
          totalPrice: 230000,
          type: "investment",
          category: "coffee shops",
          status: "free", //free or pending or taken
          finishing: "full", //full or half or none
          plans: [
            {
              deposit: 50, // from 0 to 100 : where 50 means 50%
              years: 7, //installment years
              yearInstallment: 1200,
              quarterYearInstallment: 300,
              halfMonthInstallment: 50,
            },
            {
              deposit: 25, // from 0 to 100 : where 50 means 50%
              years: 4, //installment years
              yearInstallment: 3000,
              quarterYearInstallment: 750,
              halfMonthInstallment: 125,
            },
          ],
        },
        {
          id: "2",
          name: "restaurant No.11",
          nameArabic: "مطعم رقم ٦٠ ",
          propertyCAD: [{ floor: 1, CAD: propertyCAD }], // array of floors & CAD for each floor
          unitImage: restaurants,
          tags: ["Investment"],
          area: 300,
          pricePerMeter: 1600,
          totalPrice: 230000,
          type: "investment",
          category: "restaurants",
          status: "free", //free or pending or taken
          finishing: "full", //full or half or none
          plans: [
            {
              deposit: 50, // from 0 to 100 : where 50 means 50%
              years: 7, //installment years
              yearInstallment: 1200,
              quarterYearInstallment: 300,
              halfMonthInstallment: 50,
            },
            {
              deposit: 25, // from 0 to 100 : where 50 means 50%
              years: 4, //installment years
              yearInstallment: 3000,
              quarterYearInstallment: 750,
              halfMonthInstallment: 125,
            },
          ],
        },
        {
          id: "3",
          name: "shop No.60",
          nameArabic: "محل رقم ٦٠",
          propertyCAD: [{ floor: 1, CAD: propertyCAD }], // array of floors & CAD for each floor
          unitImage: shops,
          tags: ["Investment"],
          area: 300,
          pricePerMeter: 1600,
          totalPrice: 230000,
          type: "investment",
          category: "shops",
          status: "free", //free or pending or taken
          finishing: "full", //full or half or none
          plans: [
            {
              deposit: 50, // from 0 to 100 : where 50 means 50%
              years: 7, //installment years
              yearInstallment: 1200,
              quarterYearInstallment: 300,
              halfMonthInstallment: 50,
            },
            {
              deposit: 25, // from 0 to 100 : where 50 means 50%
              years: 4, //installment years
              yearInstallment: 3000,
              quarterYearInstallment: 750,
              halfMonthInstallment: 125,
            },
          ],
        },
        {
          id: "4",
          name: "partition No.60",
          nameArabic: "بارتشن ٦٠",
          propertyCAD: [{ floor: 1, CAD: propertyCAD }], // array of floors & CAD for each floor
          unitImage: partitions,
          tags: ["Investment"],
          area: 300,
          pricePerMeter: 1600,
          totalPrice: 230000,
          type: "investment",
          category: "partitions",
          status: "free", //free or pending or taken
          finishing: "full", //full or half or none
          plans: [
            {
              deposit: 50, // from 0 to 100 : where 50 means 50%
              years: 7, //installment years
              yearInstallment: 1200,
              quarterYearInstallment: 300,
              halfMonthInstallment: 50,
            },
            {
              deposit: 25, // from 0 to 100 : where 50 means 50%
              years: 4, //installment years
              yearInstallment: 3000,
              quarterYearInstallment: 750,
              halfMonthInstallment: 125,
            },
          ],
        },
      ],
    },
    {
      number: 2,
      tag: "commercial",
      area: 700,
      layout: mallFloorView,
      features: ["Feature 1 Here", "Feature 2 Here", "Feature 3 Here"],
      featuresArabic: ["الميزة الأولى", "الميزة الثانية", "الميزة الثالثة"],
      units: [
        {
          id: "5",
          name: "coffee shop No.10",
          nameArabic: "كافيه رقم ٦٠",
          propertyCAD: [{ floor: 1, CAD: propertyCAD }], // array of floors & CAD for each floor
          unitImage: coffeeShops,
          tags: ["Investment"],
          area: 300,
          pricePerMeter: 1600,
          totalPrice: 230000,
          type: "investment",
          category: "coffee shops",
          status: "free", //free or pending or taken
          finishing: "full", //full or half or none
          plans: [
            {
              deposit: 50, // from 0 to 100 : where 50 means 50%
              years: 7, //installment years
              yearInstallment: 1200,
              quarterYearInstallment: 300,
              halfMonthInstallment: 50,
            },
            {
              deposit: 25, // from 0 to 100 : where 50 means 50%
              years: 4, //installment years
              yearInstallment: 3000,
              quarterYearInstallment: 750,
              halfMonthInstallment: 125,
            },
          ],
        },
        {
          id: "6",
          name: "restaurant No.11",
          nameArabic: "مطعم رقم ٦٠ ",
          propertyCAD: [{ floor: 1, CAD: propertyCAD }], // array of floors & CAD for each floor
          unitImage: restaurants,
          tags: ["Investment"],
          area: 300,
          pricePerMeter: 1600,
          totalPrice: 230000,
          type: "investment",
          category: "restaurants",
          status: "free", //free or pending or taken
          finishing: "full", //full or half or none
          plans: [
            {
              deposit: 50, // from 0 to 100 : where 50 means 50%
              years: 7, //installment years
              yearInstallment: 1200,
              quarterYearInstallment: 300,
              halfMonthInstallment: 50,
            },
            {
              deposit: 25, // from 0 to 100 : where 50 means 50%
              years: 4, //installment years
              yearInstallment: 3000,
              quarterYearInstallment: 750,
              halfMonthInstallment: 125,
            },
          ],
        },
        {
          id: "7",
          name: "shop No.60",
          nameArabic: "محل رقم ٦٠",
          propertyCAD: [{ floor: 1, CAD: propertyCAD }], // array of floors & CAD for each floor
          unitImage: shops,
          tags: ["Investment"],
          area: 300,
          pricePerMeter: 1600,
          totalPrice: 230000,
          type: "investment",
          category: "shops",
          status: "free", //free or pending or taken
          finishing: "full", //full or half or none
          plans: [
            {
              deposit: 50, // from 0 to 100 : where 50 means 50%
              years: 7, //installment years
              yearInstallment: 1200,
              quarterYearInstallment: 300,
              halfMonthInstallment: 50,
            },
            {
              deposit: 25, // from 0 to 100 : where 50 means 50%
              years: 4, //installment years
              yearInstallment: 3000,
              quarterYearInstallment: 750,
              halfMonthInstallment: 125,
            },
          ],
        },
        {
          id: "8",
          name: "partition No.60",
          nameArabic: "بارتشن ٦٠",
          propertyCAD: [{ floor: 1, CAD: propertyCAD }], // array of floors & CAD for each floor
          unitImage: partitions,
          tags: ["Investment"],
          area: 300,
          pricePerMeter: 1600,
          totalPrice: 230000,
          type: "investment",
          category: "partitions",
          status: "free", //free or pending or taken
          finishing: "full", //full or half or none
          plans: [
            {
              deposit: 50, // from 0 to 100 : where 50 means 50%
              years: 7, //installment years
              yearInstallment: 1200,
              quarterYearInstallment: 300,
              halfMonthInstallment: 50,
            },
            {
              deposit: 25, // from 0 to 100 : where 50 means 50%
              years: 4, //installment years
              yearInstallment: 3000,
              quarterYearInstallment: 750,
              halfMonthInstallment: 125,
            },
          ],
        },
      ],
    },
    {
      number: 3,
      tag: "medical",
      area: 800,
      layout: mallFloorView,
      features: ["Feature 1 Here", "Feature 2 Here", "Feature 3 Here"],
      featuresArabic: ["الميزة الأولى", "الميزة الثانية", "الميزة الثالثة"],
      units: [
        {
          id: "9",
          name: "pharmacy No.60",
          nameArabic: "صيدلية ٦٠",
          propertyCAD: [{ floor: 1, CAD: propertyCAD }], // array of floors & CAD for each floor
          unitImage: pharmacies,
          tags: ["Investment"],
          area: 300,
          pricePerMeter: 1600,
          totalPrice: 230000,
          type: "investment",
          category: "pharmacies",
          status: "free", //free or pending or taken
          finishing: "full", //full or half or none
          plans: [
            {
              deposit: 50, // from 0 to 100 : where 50 means 50%
              years: 7, //installment years
              yearInstallment: 1200,
              quarterYearInstallment: 300,
              halfMonthInstallment: 50,
            },
            {
              deposit: 25, // from 0 to 100 : where 50 means 50%
              years: 4, //installment years
              yearInstallment: 3000,
              quarterYearInstallment: 750,
              halfMonthInstallment: 125,
            },
          ],
        },
        {
          id: "10",
          name: "clinic No.60",
          nameArabic: "عيادة ٦٠",
          propertyCAD: [{ floor: 1, CAD: propertyCAD }], // array of floors & CAD for each floor
          unitImage: clinics,
          tags: ["Investment"],
          area: 300,
          pricePerMeter: 1600,
          totalPrice: 230000,
          type: "investment",
          category: "clinics",
          status: "free", //free or pending or taken
          finishing: "full", //full or half or none
          plans: [
            {
              deposit: 50, // from 0 to 100 : where 50 means 50%
              years: 7, //installment years
              yearInstallment: 1200,
              quarterYearInstallment: 300,
              halfMonthInstallment: 50,
            },
            {
              deposit: 25, // from 0 to 100 : where 50 means 50%
              years: 4, //installment years
              yearInstallment: 3000,
              quarterYearInstallment: 750,
              halfMonthInstallment: 125,
            },
          ],
        },
      ],
    },
    {
      number: 4,
      tag: "medical",
      area: 800,
      layout: mallFloorView,
      features: ["Feature 1 Here", "Feature 2 Here", "Feature 3 Here"],
      featuresArabic: ["الميزة الأولى", "الميزة الثانية", "الميزة الثالثة"],
      units: [
        {
          id: "11",
          name: "pharmacy No.60",
          nameArabic: "صيدلية ٦٠",
          propertyCAD: [{ floor: 1, CAD: propertyCAD }], // array of floors & CAD for each floor
          unitImage: pharmacies,
          tags: ["Investment"],
          area: 300,
          pricePerMeter: 1600,
          totalPrice: 230000,
          type: "investment",
          category: "pharmacies",
          status: "free", //free or pending or taken
          finishing: "full", //full or half or none
          plans: [
            {
              deposit: 50, // from 0 to 100 : where 50 means 50%
              years: 7, //installment years
              yearInstallment: 1200,
              quarterYearInstallment: 300,
              halfMonthInstallment: 50,
            },
            {
              deposit: 25, // from 0 to 100 : where 50 means 50%
              years: 4, //installment years
              yearInstallment: 3000,
              quarterYearInstallment: 750,
              halfMonthInstallment: 125,
            },
          ],
        },
        {
          id: "12",
          name: "clinic No.60",
          nameArabic: "عيادة ٦٠",
          propertyCAD: [{ floor: 1, CAD: propertyCAD }], // array of floors & CAD for each floor
          unitImage: clinics,
          tags: ["Investment"],
          area: 300,
          pricePerMeter: 1600,
          totalPrice: 230000,
          type: "investment",
          category: "clinics",
          status: "free", //free or pending or taken
          finishing: "full", //full or half or none
          plans: [
            {
              deposit: 50, // from 0 to 100 : where 50 means 50%
              years: 7, //installment years
              yearInstallment: 1200,
              quarterYearInstallment: 300,
              halfMonthInstallment: 50,
            },
            {
              deposit: 25, // from 0 to 100 : where 50 means 50%
              years: 4, //installment years
              yearInstallment: 3000,
              quarterYearInstallment: 750,
              halfMonthInstallment: 125,
            },
          ],
        },
      ],
    },
    {
      number: 5,
      tag: "administrative",
      area: 1000,
      layout: mallFloorView,
      features: ["Feature 1 Here", "Feature 2 Here", "Feature 3 Here"],
      featuresArabic: ["الميزة الأولى", "الميزة الثانية", "الميزة الثالثة"],
      units: [
        {
          id: "13",
          name: "office No.60",
          nameArabic: "مكتب ٦٠",
          propertyCAD: [{ floor: 1, CAD: propertyCAD }], // array of floors & CAD for each floor
          unitImage: offices,
          tags: ["Investment"],
          area: 300,
          pricePerMeter: 1600,
          totalPrice: 230000,
          type: "investment",
          category: "office",
          status: "free", //free or pending or taken
          finishing: "full", //full or half or none
          plans: [
            {
              deposit: 50, // from 0 to 100 : where 50 means 50%
              years: 7, //installment years
              yearInstallment: 1200,
              quarterYearInstallment: 300,
              halfMonthInstallment: 50,
            },
            {
              deposit: 25, // from 0 to 100 : where 50 means 50%
              years: 4, //installment years
              yearInstallment: 3000,
              quarterYearInstallment: 750,
              halfMonthInstallment: 125,
            },
          ],
        },
      ],
    },
    {
      number: 6,
      tag: "administrative",
      area: 1000,
      layout: mallFloorView,
      features: ["Feature 1 Here", "Feature 2 Here", "Feature 3 Here"],
      featuresArabic: ["الميزة الأولى", "الميزة الثانية", "الميزة الثالثة"],
      units: [
        {
          id: "14",
          name: "office No.60",
          nameArabic: "مكتب ٦٠",
          propertyCAD: [{ floor: 1, CAD: propertyCAD }], // array of floors & CAD for each floor
          unitImage: offices,
          tags: ["Investment"],
          area: 300,
          pricePerMeter: 1600,
          totalPrice: 230000,
          type: "investment",
          category: "office",
          status: "free", //free or pending or taken
          finishing: "full", //full or half or none
          plans: [
            {
              deposit: 50, // from 0 to 100 : where 50 means 50%
              years: 7, //installment years
              yearInstallment: 1200,
              quarterYearInstallment: 300,
              halfMonthInstallment: 50,
            },
            {
              deposit: 25, // from 0 to 100 : where 50 means 50%
              years: 4, //installment years
              yearInstallment: 3000,
              quarterYearInstallment: 750,
              halfMonthInstallment: 125,
            },
          ],
        },
      ],
    },
    {
      number: 7,
      tag: "hotel",
      area: 1000,
      layout: mallFloorView,
      features: ["Feature 1 Here", "Feature 2 Here", "Feature 3 Here"],
      featuresArabic: ["الميزة الأولى", "الميزة الثانية", "الميزة الثالثة"],
      units: [
        {
          id: "15",
          name: "Room No. 414",
          nameArabic: " غرفة ١",
          propertyCAD: [{ floor: 1, CAD: propertyCAD }], // array of floors & CAD for each floor
          unitImage: hotelUnits,
          tags: ["Investment"],
          area: 300,
          pricePerMeter: 1600,
          totalPrice: 230000,
          implementingCompanyLogo: "",
          type: "investment",
          category: "hotel units",
          status: "free", //free or pending or taken
          finishing: "full", //full or half or none
          plans: [
            {
              deposit: 50, // from 0 to 100 : where 50 means 50%
              years: 7, //installment years
              yearInstallment: 1200,
              quarterYearInstallment: 300,
              halfMonthInstallment: 50,
            },
            {
              deposit: 25, // from 0 to 100 : where 50 means 50%
              years: 4, //installment years
              yearInstallment: 3000,
              quarterYearInstallment: 750,
              halfMonthInstallment: 125,
            },
          ],
        },
      ],
    },
    {
      number: 8,
      tag: "hotel",
      area: 1000,
      layout: mallFloorView,
      features: ["Feature 1 Here", "Feature 2 Here", "Feature 3 Here"],
      featuresArabic: ["الميزة الأولى", "الميزة الثانية", "الميزة الثالثة"],
      units: [
        {
          id: "16",
          name: "Room No. 414",
          nameArabic: " غرفة ١",
          propertyCAD: [{ floor: 1, CAD: propertyCAD }], // array of floors & CAD for each floor
          unitImage: hotelUnits,
          tags: ["Investment"],
          area: 300,
          pricePerMeter: 1600,
          totalPrice: 230000,
          implementingCompanyLogo: "",
          type: "investment",
          category: "hotel units",
          status: "free", //free or pending or taken
          finishing: "full", //full or half or none
          plans: [
            {
              deposit: 50, // from 0 to 100 : where 50 means 50%
              years: 7, //installment years
              yearInstallment: 1200,
              quarterYearInstallment: 300,
              halfMonthInstallment: 50,
            },
            {
              deposit: 25, // from 0 to 100 : where 50 means 50%
              years: 4, //installment years
              yearInstallment: 3000,
              quarterYearInstallment: 750,
              halfMonthInstallment: 125,
            },
          ],
        },
      ],
    },
  ],
  intro: "The latest projects of Pyramids Real Estate, will be the largest real estate projects of Pyramids Company .",
  introArabic: "أحدث مشاريع بيراميدز العقارية ، ستكون أضخم المشاريع العقارية لشركة بيراميدز",
  importantAreas: [
    "Banking Area.",
    "Governmental District.",
    "Located In Near The Central Station.",
    "The Petroleum Companies Sector, Airline Sector.",
    "Gold And Fashion Markets.",
  ],
  importantAreasArabic: ["منطقة بنوك", "الحي الحكومي", "يقع بالقرب من المحطة المركزية", "قطاع شركات البترول ، قطاع الطيران", "أسواق الذهب والأزياء"],
  popularBrandsImage: popularBrandsImage,
  location: {
    id: "1",
    aboutLocation:
      "The Downtown area is located in the heart of the New Capital and is surrounded by the most important landmarks in the Capital. To the north, it is bordered by the Government District, the Ministries District, the Parliament Building, the Opera House, and the Monorail Train Station. To the west, it is bordered by Al Masa Capital Hotel. To the south, it is bordered by the Green River",
    aboutLocationArabic:
      "تقع منطقة وسط البلد في قلب العاصمة الجديدة وتحيط بها أهم معالم العاصمة ، ومن الشمال يحدها الحي الحكومي ، وحي الوزارات ، ومبنى البرلمان ، ودار الأوبرا ، و محطة القطار المونوريل ويحدها من الغرب فندق الماسة كابيتال ومن الجنوب النهر الأخضر ",
    map: "https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d884517.9983935376!2d30.877271529638946!3d30.003894070195585!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2seg!4v1677169575574!5m2!1sen!2seg",
  },
};
//---------------------------------------------------------------------------------------------//
export const compoundData = {
  id: "2",
  backgroundImage: star, //used as background image in html
  name: "Star Compound",
  nameArabic: "كمبوند ستار",
  address: "Star compound, New capital city", //max 30 characters
  addressArabic: "شارع الزهور، العاصمة الجديدة", //max 30 characters
  layout: mallFloorView,
  projectImage: goldenTower, //used as card image
  latestProjectImage: borouj, //used as latest project image
  propertiesNumber: 200,
  tags: ["Residential"],
  distance: 320,
  implementingCompany: {
    companyId: "1",
    name: "Home Town Developments",
    nameArabic: "هوم تاون للتنمية العقارية",
    logo: logo,
  },
  type: "compound",
  acres: 1364,
  compoundUnitsPrices: [
    {
      tag: "Apartments",
      min: 20,
      max: 120,
      type: "K", // K:thousands M:millions
      typeArabic: "الف",
      image: apartments,
    },
    {
      tag: "Duplex Villas",
      min: 20,
      max: 120,
      type: "K", // K:thousands M:millions
      typeArabic: "الف",
      image: duplexVillas,
    },
    {
      tag: "Detached Villas",
      min: 20,
      max: 120,
      type: "K", // K:thousands M:millions
      typeArabic: "الف",
      image: detachedVillas,
    },
    {
      tag: "Twin Houses",
      min: 20,
      max: 120,
      type: "K", // K:thousands M:millions
      typeArabic: "الف",
      image: twinHouse,
    },
    {
      tag: "Studios",
      min: 20,
      max: 120,
      type: "K", // K:thousands M:millions
      typeArabic: "الف",
      image: studio,
    },
    {
      tag: "Hotel Units",
      min: 20,
      max: 120,
      type: "K", // K:thousands M:millions
      typeArabic: "الف",
      image: hotelUnits,
    },
  ],
  blocks: [
    {
      id: "1",
      blockNumber: "37 H",
      blockNumberArabic: "٣٧ اتش",
      buildingFloors: 2,
      floorsFeatures: [
        {
          number: 1,
          area: 1,
          layout: mallFloorView,
          units: [
            {
              id: "1",
              name: "Apartment No. 123",
              nameArabic: " شقة رقم ١",
              propertyCAD: [{ floor: 1, CAD: propertyCAD }], // array of floors & CAD for each floor
              unitImage: apartments,
              tags: ["Residential"],
              area: 300,
              pricePerMeter: 1600,
              totalPrice: 230000,
              implementingCompanyLogo: "",
              type: "residential",
              category: "apartments",
              status: "taken", //free or pending or taken
              finishing: "full", //full or half or none
              plans: [
                {
                  deposit: 50, // from 0 to 100 : where 50 means 50%
                  years: 7, //installment years
                  yearInstallment: 1200,
                  quarterYearInstallment: 300,
                  halfMonthInstallment: 50,
                },
                {
                  deposit: 25, // from 0 to 100 : where 50 means 50%
                  years: 4, //installment years
                  yearInstallment: 3000,
                  quarterYearInstallment: 750,
                  halfMonthInstallment: 125,
                },
              ],
            },
            {
              id: "2",
              name: "Apartment No. 122",
              nameArabic: "شقة رقم ١١",
              propertyCAD: [{ floor: 1, CAD: propertyCAD }], // array of floors & CAD for each floor
              unitImage: apartments,
              tags: ["Residential"],
              area: 300,
              pricePerMeter: 1600,
              totalPrice: 230000,
              implementingCompanyLogo: "",
              type: "residential",
              category: "apartments",
              status: "free", //free or pending or taken
              finishing: "full", //full or half or none
              plans: [
                {
                  deposit: 50, // from 0 to 100 : where 50 means 50%
                  years: 7, //installment years
                  yearInstallment: 1200,
                  quarterYearInstallment: 300,
                  halfMonthInstallment: 50,
                },
                {
                  deposit: 25, // from 0 to 100 : where 50 means 50%
                  years: 4, //installment years
                  yearInstallment: 3000,
                  quarterYearInstallment: 750,
                  halfMonthInstallment: 125,
                },
              ],
            },
          ],
        },
        {
          number: 2,
          area: 1,
          layout: mallFloorView,
          units: [
            {
              id: "3",
              name: "Apartment No. 124",
              nameArabic: "شقة رقم ١",
              propertyCAD: [{ floor: 1, CAD: propertyCAD }], // array of floors & CAD for each floor
              unitImage: apartments,
              tags: ["Residential"],
              area: 300,
              pricePerMeter: 1600,
              totalPrice: 230000,
              implementingCompanyLogo: "",
              type: "residential",
              category: "apartments",
              status: "taken", //free or pending or taken
              finishing: "full", //full or half or none
              plans: [
                {
                  deposit: 50, // from 0 to 100 : where 50 means 50%
                  years: 7, //installment years
                  yearInstallment: 1200,
                  quarterYearInstallment: 300,
                  halfMonthInstallment: 50,
                },
                {
                  deposit: 25, // from 0 to 100 : where 50 means 50%
                  years: 4, //installment years
                  yearInstallment: 3000,
                  quarterYearInstallment: 750,
                  halfMonthInstallment: 125,
                },
              ],
            },
            {
              id: "4",
              name: "Apartment No. 125",
              nameArabic: "شقة رقم ١١",
              propertyCAD: [{ floor: 1, CAD: propertyCAD }], // array of floors & CAD for each floor
              unitImage: apartments,
              tags: ["Residential"],
              area: 300,
              pricePerMeter: 1600,
              totalPrice: 230000,
              implementingCompanyLogo: "",
              type: "residential",
              category: "apartments",
              status: "free", //free or pending or taken
              finishing: "full", //full or half or none
              plans: [
                {
                  deposit: 50, // from 0 to 100 : where 50 means 50%
                  years: 7, //installment years
                  yearInstallment: 1200,
                  quarterYearInstallment: 300,
                  halfMonthInstallment: 50,
                },
                {
                  deposit: 25, // from 0 to 100 : where 50 means 50%
                  years: 4, //installment years
                  yearInstallment: 3000,
                  quarterYearInstallment: 750,
                  halfMonthInstallment: 125,
                },
              ],
            },
          ],
        },
      ],
    },
  ],
  duplexVillas: [
    {
      id: "5",
      name: "Duplex Villa No.10",
      nameArabic: "فيلا دوبلكس رقم ",
      buildingFloors: 2,
      propertyCAD: [
        {
          id: "55",
          floor: 1,
          CAD: propertyCAD,
          unitImage: duplexVillas,
          area: 300,
          pricePerMeter: 1600,
          totalPrice: 230000,
          status: "free", //free or pending or taken
          finishing: "full", //full or half or none
          plans: [
            {
              deposit: 50, // from 0 to 100 : where 50 means 50%
              years: 7, //installment years
              yearInstallment: 1200,
              quarterYearInstallment: 300,
              halfMonthInstallment: 50,
            },
            {
              deposit: 25, // from 0 to 100 : where 50 means 50%
              years: 4, //installment years
              yearInstallment: 3000,
              quarterYearInstallment: 750,
              halfMonthInstallment: 125,
            },
          ],
        },
        {
          id: "66",
          floor: 2,
          CAD: propertyCAD,
          unitImage: duplexVillas,
          area: 300,
          pricePerMeter: 1600,
          totalPrice: 230000,
          status: "free", //free or pending or taken
          finishing: "full", //full or half or none
          plans: [
            {
              deposit: 50, // from 0 to 100 : where 50 means 50%
              years: 7, //installment years
              yearInstallment: 1200,
              quarterYearInstallment: 300,
              halfMonthInstallment: 50,
            },
            {
              deposit: 25, // from 0 to 100 : where 50 means 50%
              years: 4, //installment years
              yearInstallment: 3000,
              quarterYearInstallment: 750,
              halfMonthInstallment: 125,
            },
          ],
        },
      ],
      unitImage: duplexVillas,
      tags: ["Residential"],
      implementingCompanyLogo: "",
      type: "residential",
      category: "duplex villas",
      area: 600,
      pricePerMeter: 3200,
      totalPrice: 460000,
      finishing: "full", //full or half or none
      plans: [
        {
          deposit: 70, // from 0 to 100 : where 50 means 50%
          years: 10, //installment years
          yearInstallment: 1000,
          quarterYearInstallment: 275,
          halfMonthInstallment: 190,
        },
        {
          deposit: 60, // from 0 to 100 : where 50 means 50%
          years: 10, //installment years
          yearInstallment: 1000,
          quarterYearInstallment: 750,
          halfMonthInstallment: 999,
        },
      ],
      allFloorsAvailable: true, // all floors available => true, otherwise => false
    },
  ],
  detachedVillas: [
    {
      id: "7",
      name: "Detached Villa No.10",
      nameArabic: "فيلا منفصلة رقم ",
      buildingFloors: 2,
      propertyCAD: [
        {
          id: "77",
          floor: 1,
          CAD: propertyCAD,
          unitImage: detachedVillas,
          area: 300,
          pricePerMeter: 1600,
          totalPrice: 230000,
          status: "free", //free or pending or taken
          finishing: "full", //full or half or none
          plans: [
            {
              deposit: 50, // from 0 to 100 : where 50 means 50%
              years: 7, //installment years
              yearInstallment: 1200,
              quarterYearInstallment: 300,
              halfMonthInstallment: 50,
            },
            {
              deposit: 25, // from 0 to 100 : where 50 means 50%
              years: 4, //installment years
              yearInstallment: 3000,
              quarterYearInstallment: 750,
              halfMonthInstallment: 125,
            },
          ],
        },
        {
          id: "88",
          floor: 2,
          CAD: propertyCAD,
          unitImage: detachedVillas,
          area: 300,
          pricePerMeter: 1600,
          totalPrice: 230000,
          status: "free", //free or pending or taken
          finishing: "full", //full or half or none
          plans: [
            {
              deposit: 50, // from 0 to 100 : where 50 means 50%
              years: 7, //installment years
              yearInstallment: 1200,
              quarterYearInstallment: 300,
              halfMonthInstallment: 50,
            },
            {
              deposit: 25, // from 0 to 100 : where 50 means 50%
              years: 4, //installment years
              yearInstallment: 3000,
              quarterYearInstallment: 750,
              halfMonthInstallment: 125,
            },
          ],
        },
      ],
      unitImage: detachedVillas,
      tags: ["Residential"],
      implementingCompanyLogo: "",
      type: "residential",
      category: "detached villas",
      area: 600,
      pricePerMeter: 3200,
      totalPrice: 460000,
      finishing: "full", //full or half or none
      plans: [
        {
          deposit: 70, // from 0 to 100 : where 50 means 50%
          years: 10, //installment years
          yearInstallment: 1000,
          quarterYearInstallment: 275,
          halfMonthInstallment: 190,
        },
        {
          deposit: 60, // from 0 to 100 : where 50 means 50%
          years: 10, //installment years
          yearInstallment: 1000,
          quarterYearInstallment: 750,
          halfMonthInstallment: 999,
        },
      ],
      allFloorsAvailable: true, // all floors available => true, otherwise => false
    },
  ],
  twinHouses: [
    {
      id: "9",
      name: "Twin house No.10",
      nameArabic: "توين هاوس رقم ",
      buildingFloors: 2,
      propertyCAD: [
        {
          id: "99",
          floor: 1,
          CAD: propertyCAD,
          unitImage: twinHouse,
          area: 300,
          pricePerMeter: 1600,
          totalPrice: 230000,
          status: "free", //free or pending or taken
          finishing: "full", //full or half or none
          plans: [
            {
              deposit: 50, // from 0 to 100 : where 50 means 50%
              years: 7, //installment years
              yearInstallment: 1200,
              quarterYearInstallment: 300,
              halfMonthInstallment: 50,
            },
            {
              deposit: 25, // from 0 to 100 : where 50 means 50%
              years: 4, //installment years
              yearInstallment: 3000,
              quarterYearInstallment: 750,
              halfMonthInstallment: 125,
            },
          ],
        },
        {
          id: "101",
          floor: 2,
          CAD: propertyCAD,
          unitImage: twinHouse,
          area: 300,
          pricePerMeter: 1600,
          totalPrice: 230000,
          status: "free", //free or pending or taken
          finishing: "full", //full or half or none
          plans: [
            {
              deposit: 50, // from 0 to 100 : where 50 means 50%
              years: 7, //installment years
              yearInstallment: 1200,
              quarterYearInstallment: 300,
              halfMonthInstallment: 50,
            },
            {
              deposit: 25, // from 0 to 100 : where 50 means 50%
              years: 4, //installment years
              yearInstallment: 3000,
              quarterYearInstallment: 750,
              halfMonthInstallment: 125,
            },
          ],
        },
      ],
      unitImage: twinHouse,
      tags: ["Residential"],
      implementingCompanyLogo: "",
      type: "residential",
      category: "twin house",
      area: 600,
      pricePerMeter: 3200,
      totalPrice: 460000,
      finishing: "full", //full or half or none
      plans: [
        {
          deposit: 70, // from 0 to 100 : where 50 means 50%
          years: 10, //installment years
          yearInstallment: 1000,
          quarterYearInstallment: 275,
          halfMonthInstallment: 190,
        },
        {
          deposit: 60, // from 0 to 100 : where 50 means 50%
          years: 10, //installment years
          yearInstallment: 1000,
          quarterYearInstallment: 750,
          halfMonthInstallment: 999,
        },
      ],
      allFloorsAvailable: true, // all floors available => true, otherwise => false
    },
  ],
  studios: [
    {
      id: "11",
      blockNumber: "37 H",
      blockNumberArabic: "٣٧ اتش",
      buildingFloors: 1,
      floorsFeatures: [
        {
          number: 1,
          area: 1,
          layout: mallFloorView,
          units: [
            {
              id: "12",
              name: "Studio No. 123",
              nameArabic: " ستوديو ١",
              propertyCAD: [{ floor: 1, CAD: propertyCAD }], // array of floors & CAD for each floor
              unitImage: studio,
              tags: ["Residential"],
              area: 300,
              pricePerMeter: 1600,
              totalPrice: 230000,
              implementingCompanyLogo: "",
              type: "residential",
              category: "studio",
              status: "free", //free or pending or taken
              finishing: "full", //full or half or none
              plans: [
                {
                  deposit: 50, // from 0 to 100 : where 50 means 50%
                  years: 7, //installment years
                  yearInstallment: 1200,
                  quarterYearInstallment: 300,
                  halfMonthInstallment: 50,
                },
                {
                  deposit: 25, // from 0 to 100 : where 50 means 50%
                  years: 4, //installment years
                  yearInstallment: 3000,
                  quarterYearInstallment: 750,
                  halfMonthInstallment: 125,
                },
              ],
            },
          ],
        },
      ],
    },
  ],
  hotelUnits: [
    {
      id: "14",
      blockNumber: "37 H",
      blockNumberArabic: "٣٧ اتش",
      buildingFloors: 1,
      floorsFeatures: [
        {
          number: 1,
          area: 1,
          layout: mallFloorView,
          units: [
            {
              id: "15",
              name: "Room No. 123",
              nameArabic: " غرفة رقم ١",
              propertyCAD: [{ floor: 1, CAD: propertyCAD }], // array of floors & CAD for each floor
              unitImage: hotelUnits,
              tags: ["Residential"],
              area: 300,
              pricePerMeter: 1600,
              totalPrice: 230000,
              implementingCompanyLogo: "",
              type: "residential",
              category: "hotel units",
              status: "free", //free or pending or taken
              finishing: "full", //full or half or none
              plans: [
                {
                  deposit: 50, // from 0 to 100 : where 50 means 50%
                  years: 7, //installment years
                  yearInstallment: 1200,
                  quarterYearInstallment: 300,
                  halfMonthInstallment: 50,
                },
                {
                  deposit: 25, // from 0 to 100 : where 50 means 50%
                  years: 4, //installment years
                  yearInstallment: 3000,
                  quarterYearInstallment: 750,
                  halfMonthInstallment: 125,
                },
              ],
            },
          ],
        },
      ],
    },
  ],
  features: ["Feature 1 Here", "Feature 2 Here", "Feature 3 Here"],
  featuresArabic: ["الميزة الأولى", "الميزة الثانية", "الميزة الثالثة"],
  intro: "The latest projects of Pyramids Real Estate, will be the largest real estate projects of Pyramids Company .",
  introArabic: "أحدث مشاريع بيراميدز العقارية ، ستكون أضخم المشاريع العقارية لشركة بيراميدز",
  importantAreas: [
    "Banking Area.",
    "Governmental District.",
    "Located In Near The Central Station.",
    "The Petroleum Companies Sector, Airline Sector.",
    "Gold And Fashion Markets.",
  ],
  importantAreasArabic: ["منطقة بنوك", "الحي الحكومي", "يقع بالقرب من المحطة المركزية", "قطاع شركات البترول ، قطاع الطيران", "أسواق الذهب والأزياء"],
  location: {
    id: "1",
    aboutLocation:
      "The Downtown area is located in the heart of the New Capital and is surrounded by the most important landmarks in the Capital. To the north, it is bordered by the Government District, the Ministries District, the Parliament Building, the Opera House, and the Monorail Train Station. To the west, it is bordered by Al Masa Capital Hotel. To the south, it is bordered by the Green River",
    aboutLocationArabic:
      "تقع منطقة وسط البلد في قلب العاصمة الجديدة وتحيط بها أهم معالم العاصمة ، ومن الشمال يحدها الحي الحكومي ، وحي الوزارات ، ومبنى البرلمان ، ودار الأوبرا ، و محطة القطار المونوريل ويحدها من الغرب فندق الماسة كابيتال ومن الجنوب النهر الأخضر ",
    map: "https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d884517.9983935376!2d30.877271529638946!3d30.003894070195585!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2seg!4v1677169575574!5m2!1sen!2seg",
  },
};
//---------------------------------------------------------------------------------------------//
export const companyData = {
  id: "1",
  name: "Home Town Developments",
  nameArabic: "هوم تاون للتطوير العقاري",
  logo: logo1, //used while displaying company card & logo inside company profile page
  image: companyImage,
  rating: 4.5,
  aboutFirstParagraph:
    "Hometown developments, proud to be an accredited and trusted real estate company provider in the MENA region, we provide high-quality properties with sustainable amenities to our beloved clients.",
  aboutFirstParagraphArabic:
    "شركة هوم تاون للتطوير العقاري ، نفخر بكوننا مزودًا معتمدًا وموثوقًا به في مجال العقارات في منطقة الشرق الأوسط وشمال إفريقيا ، نحن نقدم عقارات عالية الجودة مع وسائل الراحة المستدامة لعملائنا الأحباء.",
  aboutSecondParagraph:
    "With a steady pace, Hometown development managed to capture the largest percentage of the real estate market in the 5th settlement",
  aboutSecondParagraphArabic: "بوتيرة ثابتة ، تمكنت شركة هوم تاون للتطوير العقاري من الاستحواذ على أكبر نسبة من سوق العقارات في التجمع الخامس",
  projectNumbers: 20,
  //for the following attribute, I dont need the whole mall & compound object to be stored in those arrays
  //we need objects like these
  //  const compound= {
  //     id: "1",
  //     projectImage: star,
  //     name: "Star Compound",
  //     nameArabic: "كمبوند ستار",
  //     address: "Star compound, New capital city, Cairo, Egypt",
  //     addressArabic: "شارع الزهور، العاصمة الجديدة، القاهرة، مصر",
  //     propertiesNumber: 200,
  //     tags: ["Commercial", "Medical", "Hotel"],
  //     distance: 320,
  //   },
  //  const mall= {
  //     id: "1",
  //     projectImage: pyramids,
  //     name: "Pyramids Mall",
  //     nameArabic: "بيراميدز مول",
  //     address: "24 flowers street, New capital city, Cairo, Egypt",
  //     addressArabic: "شارع الزهور، العاصمة الجديدة، القاهرة، مصر",
  //     buildingFloors: 15,
  //     tags: ["Commercial", "Medical", "Hotel"],
  //     distance: 320,
  //   },
  // where compound & mall objects are subsets from the compoundData & mallData objects
  companyProjects: [mallData, compoundData],
  //show 3 recent feedbacks
  feedbacks: [
    {
      id: "1",
      creatorId: "1",
      creatorName: "Mohamed Ahmed",
      creatorPhoto: profilePicture,
      text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s Lorem Ipsum",
      date: 1519211809934,
      likersIds: ["1", "2", "3"], //used to get count of likes
      replies: [
        {
          id: "1",
          creatorId: "1",
          creatorName: "Mohamed Ahmed",
          creatorPhoto: profilePicture,
          text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s Lorem Ipsum",
          date: 1519211809934,
          likersIds: ["1", "2", "3"], //used to get count of likes
        },
      ],
    },
    {
      id: "2",
      creatorId: "2",
      creatorName: "Mohamed Ahmed",
      creatorPhoto: profilePicture,
      text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s Lorem Ipsum",
      date: 1519211809934,
      likersIds: ["1", "2", "3"], //used to get count of likes
      replies: [
        {
          id: "1",
          creatorId: "1",
          creatorName: "Mohamed Ahmed",
          creatorPhoto: profilePicture,
          text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s Lorem Ipsum",
          date: 1519211809934,
          likersIds: ["1", "2", "3"], //used to get count of likes
        },
      ],
    },
    {
      id: "3",
      creatorId: "3",
      creatorName: "Mohamed Ahmed",
      creatorPhoto: profilePicture,
      text: "Lorem Ipsum is simply dummy text of the printing.",
      date: 1519211809934,
      likersIds: ["1", "2", "3"], //used to get count of likes
      replies: [
        {
          id: "1",
          creatorId: "1",
          creatorName: "Mohamed Ahmed",
          creatorPhoto: profilePicture,
          text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s Lorem Ipsum",
          date: 1519211809934,
          likersIds: ["1", "2", "3"], //used to get count of likes
        },
      ],
    },
  ],
  facebookLink: "abc",
  instagramLink: "abc",
  linkedinLink: "abc",
};
//---------------------------------------------------------------------------------------------//
export const locationData = {
  id: "1",
  name: "Downtown",
  nameArabic: "داون تاون",
  locationCardImage: locationCardImage,
  logo: dropPinImg,
  map: "https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d884517.9983935376!2d30.877271529638946!3d30.003894070195585!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2seg!4v1677169575574!5m2!1sen!2seg",
  aboutLocation:
    "The Downtown area is located in the heart of the New Capital and is surrounded by the most important landmarks in the Capital. To the north, it is bordered by the Government District, the Ministries District, the Parliament Building, the Opera House, and the Monorail Train Station. To the west, it is bordered by Al Masa Capital Hotel. To the south, it is bordered by the Green River.",
  aboutLocationArabic:
    "تقع منطقة وسط المدينة في قلب العاصمة الجديدة وتحيط بها أهم معالم العاصمة. إلى الشمال ، يحدها المنطقة الحكومية ومنطقة الوزارات ومبنى البرلمان ودار الأوبرا ومحطة القطار المونوريل. يحده من الغرب فندق الماسة كابيتال. يحدها من الجنوب النهر الأخضر",
  futureVision:
    "The Downtown area is located in the heart of the New Capital and is surrounded by the most important landmarks in the Capital. To the north, it is bordered by the Government District, the Ministries District, the Parliament Building, the Opera House, and the Monorail Train Station. To the west, it is bordered by Al Masa Capital Hotel. To the south, it is bordered by the Green River",
  futureVisionArabic:
    "تقع منطقة وسط المدينة في قلب العاصمة الجديدة وتحيط بها أهم معالم العاصمة. إلى الشمال ، يحدها المنطقة الحكومية ومنطقة الوزارات ومبنى البرلمان ودار الأوبرا ومحطة القطار المونوريل. يحده من الغرب فندق الماسة كابيتال. يحدها من الجنوب النهر الأخضر",

  propertiesNumber: 1000,
  tags: ["Malls", "Investment", "Duplex Villa", "Restaurants"],
  acres: 1364,
  intro: "Downtown is a huge investment district with ​​1,364 acres of area and is divided into 5 neighborhoods.",
  introArabic: "داون تاون هي منطقة استثمارية ضخمة تبلغ مساحتها ١٣٦٤ فدانًا وتنقسم إلى ٥ أحياء",
  mainFeatures: [
    "Shopping Centers",
    "Major Commercial Activities In Various Fields.",
    "Administrative Towers.",
    "Headquarters Of Companies.",
    "Gardens With Various Designs, Medical Units",
  ],
  mainFeaturesArabic: ["مراكز تسوق", "أنشطة تجارية رئيسية في مختلف المجالات", "أبراج إدارية", "مقر شركات", "حدائق بتصميمات مختلفة", "وحدات طبية"],
  availableCompounds: [compoundData, compoundData, compoundData, compoundData],
  //for the above attribute, I dont need the whole compound object to be stored in this array
  // we need object like the following
  //  const compound= {
  //     id: "1",
  //     projectImage: star,
  //     name: "Star Compound",
  //     nameArabic: "كمبوند ستار",
  //     address: "Star compound, New capital city, Cairo, Egypt",
  //     addressArabic: "شارع الزهور، العاصمة الجديدة، القاهرة، مصر",
  //     propertiesNumber: 200,
  //     tags: ["Commercial", "Medical", "Hotel"],
  //     distance: 320,
  //   },
  // where the compound object above is subset from compoundData object

  availableMalls: [mallData, mallData, mallData, mallData],
  //for the above attribute, I dont need the whole mall object to be stored in this array
  // we need object like the following
  //  const mall= {
  //     id: "1",
  //     projectImage: pyramids,
  //     name: "Pyramids Mall",
  //     nameArabic: "بيراميدز مول",
  //     address: "24 flowers street, New capital city, Cairo, Egypt",
  //     addressArabic: "شارع الزهور، العاصمة الجديدة، القاهرة، مصر",
  //     buildingFloors: 15,
  //     tags: ["Commercial", "Medical", "Hotel"],
  //     distance: 320,
  //   },
  // where the mall object above is subset from compoundData object

  availableCompanies: [
    companyData,
    companyData,
    companyData,
    companyData,
    companyData,
    companyData,
    companyData,
    companyData,
    companyData,
    companyData,
    companyData,
    companyData,
  ],
  //for the above attribute, I dont need the whole company object to be stored in this array
  // we need object like the following
  // const company={
  //   id: "1",
  //   name: "Home Town Developments",
  //   nameArabic: "هوم تاون للتطوير العقاري",
  //   logo: logo1,
  //   projectNumbers: 20,
  // },
  // where the company object above is subset from companyData object
};
//---------------------------------------------------------------------------------------------//
//---------------------------------------------------------------------------------------------//
//----------------------These lists should be queried from backend-----------------------------//
//---------------------------------------------------------------------------------------------//
//---------------------------------------------------------------------------------------------//
//we dont need the whole mall object inside this array
//we just need the following
//  const mall= {
//     id: "1",
//     projectImage: pyramids,
//     name: "Pyramids Mall",
//     nameArabic: "بيراميدز مول",
//     address: "24 flowers street, New capital city, Cairo, Egypt",
//     addressArabic: "شارع الزهور، العاصمة الجديدة، القاهرة، مصر",
//     buildingFloors: 15,
//     tags: ["Commercial", "Medical", "Hotel"],
//     distance: 320,
//   },
export const mallsList = [mallData, mallData, mallData, mallData, mallData, mallData];
//---------------------------------------------------------------------------------------------//
//we dont need the whole compound object inside this array
//we just need the following
//  const compound= {
//     id: "1",
//     projectImage: star,
//     name: "Star Compound",
//     nameArabic: "كمبوند ستار",
//     address: "Star compound, New capital city, Cairo, Egypt",
//     addressArabic: "شارع الزهور، العاصمة الجديدة، القاهرة، مصر",
//     propertiesNumber: 200,
//     tags: ["Commercial", "Medical", "Hotel"],
//     distance: 320,
//   },
export const compoundsList = [compoundData, compoundData, compoundData, compoundData];
//---------------------------------------------------------------------------------------------//
//we only need 12 companies to be fetched, we can choose them by their activity or by owner preference
//we dont need the whole company object inside this array
//we just need the following
//  const company= {
//     id: "1",
//     name:"",
//     nameArabic:"",
//     projectNumbers:,
//     logo:,
//   },
export const companiesList = [
  companyData,
  companyData,
  companyData,
  companyData,
  companyData,
  companyData,
  companyData,
  companyData,
  companyData,
  companyData,
  companyData,
  companyData,
];
//---------------------------------------------------------------------------------------------//
//we dont need the whole location object inside this array
//we just need the following
//  const location= {
//     id: "1",
//     name:"",
//     nameArabic:"",
//   },
export const locationsList = [locationData, locationData, locationData, locationData];
//---------------------------------------------------------------------------------------------//
// should be array of 9 elements
//we dont need the whole (mall or compound) object inside this array
//we just need the following
//  const mall= {
//     id: "1",
//     name:"",
//     nameArabic:"",
//     address:"",
//     addressArabic:"",
//     latestProjectImage:,
//   },
//  const compound= {
//     id: "1",
//     name:"",
//     nameArabic:"",
//     address:"",
//     addressArabic:"",
//     latestProjectImage:,
//   },
export const latestProjects = [mallData, compoundData, mallData, compoundData, mallData, compoundData, mallData, compoundData, mallData];
//---------------------------------------------------------------------------------------------//
//search results for units inside malls or towers rendered in home page
//mall commercial floor=>contains units that have category (coffee shops/restaurants/shops/partitions)
//mall medical floor=>contains units that have category (pharmacies/clinics)
//mall administrative floor=>contains units that have category (offices)
//mall hotel floor=>contains units that have category (hotel units)
export const investmentPropertiesList = [
  { category: "coffee shops", source: coffeeShops, results: 200 },
  { category: "restaurants", source: restaurants, results: 40 },
  { category: "shops", source: shops, results: 560 },
  { category: "offices", source: offices, results: 123 },
  { category: "partitions", source: partitions, results: 500 },
  { category: "pharmacies", source: pharmacies, results: 40 },
  { category: "clinics", source: clinics, results: 60 },
  { category: "hotel units", source: hotels, results: 201 },
];
//---------------------------------------------------------------------------------------------//
//search results for units inside compounds rendered in home page
//mall commercial floor=>contains units that have category (coffee shops/restaurants/shops/partitions)
//mall medical floor=>contains units that have category (pharmacies/clinics)
//mall administrative floor=>contains units that have category (offices)
//mall hotel floor=>contains units that have category (hotel units)
export const residentialPropertiesList = [
  { category: "apartments", source: apartments, results: 500 },
  { category: "duplex villas", source: duplexVillas, results: 704 },
  { category: "detached villas", source: detachedVillas, results: 1300 },
  { category: "twin house", source: twinHouse, results: 130 },
  { category: "studio", source: studio, results: 30 },
  { category: "hotel units", source: hotelUnits, results: 100 },
];
//---------------------------------------------------------------------------------------------//
//---------------------------------------------------------------------------------------------//
//--------------------------------------END OF LISTS-------------------------------------------//
//---------------------------------------------------------------------------------------------//
//---------------------------------------------------------------------------------------------//
export const loggedUserData = {
  id: "1",
  name: "Ali Youssef",
  email: "amrhammdallaaa2017@gmail.com",
  phone: "01045592045",
  gender: "male",
  preferedCurrency: "Egp", //Egp or Dollar
  preferedLanguage: "English",
  profilePicture: profilePicture,
  bookingList: {
    investmentPropertiesList: [],
    residentialPropertiesList: [],
  },
  favouriteList: {
    investmentPropertiesList: [],
    residentialPropertiesList: [],
  },
  likedFeedbacksForCompanies: [
    {
      companyId: "1",
      feedbackIds: ["1", "2", "3"],
    },
    {
      companyId: "2",
      feedbackIds: ["5", "6", "7"],
    },
  ],
  likedRepliesForFeedbacks: [
    {
      companyId: "1",
      feedbackId: "1",
      repliesIds: ["1", "2", "3"],
    },
    {
      companyId: "2",
      feedbackId: "6",
      repliesIds: ["1", "2", "3"],
    },
  ],
};
//---------------------------------------------------------------------------------------------//
//---------------------------------------------------------------------------------------------//
//---------------------------------------------------------------------------------------------//
//---------------------------------------------------------------------------------------------//
export const clientReview = {
  id: "1",
  creatorId: "1",
  name: "Mohamed Ahmed",
  email: "",
  phone: "",
  source: profilePicture,
  review:
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s. Lorem Ipsum",
  profession: "Ui / Ux Designer",
};
// should be only 3 review to maintain website UI design
export const bestClientReviews = [clientReview, clientReview, clientReview];
//----------------------------------------------------------------------------------------------//
//----------------------------------------------------------------------------------------------//
//----------------------------------------------------------------------------------------------//
//The following objects simulate the (unit card for residential property) used in (search page)-//
//(apartments,studios,hotel units) properties should be inside floor, and floor inside block----//
//---we return units that have status:"free", the unit properties are returned by GET request---//
//----A new attribute is added called propertyFloor, this can be obtained from floors table-----//
//------------Some other attributes are added from blocks table as blockNumber----------------- //
//----Some other attributes are added from compounds table and renamed as name => projectName---//
export const searchResultsApartments = [
  {
    id: "1",
    name: "Apartment No. 123",
    nameArabic: " شقة رقم ١",
    propertyCAD: [{ floor: 1, CAD: propertyCAD }], // array of floors & CAD for each floor
    unitImage: apartments,
    tags: ["Residential"],
    area: 300,
    pricePerMeter: 1600,
    totalPrice: 230000,
    implementingCompanyLogo: "",
    type: "residential",
    category: "apartments",
    status: "free", //free or pending or taken
    finishing: "full", //full or half or none
    plans: [
      {
        deposit: 50, // from 0 to 100 : where 50 means 50%
        years: 7, //installment years
        yearInstallment: 1200,
        quarterYearInstallment: 300,
        halfMonthInstallment: 50,
      },
      {
        deposit: 25, // from 0 to 100 : where 50 means 50%
        years: 4, //installment years
        yearInstallment: 3000,
        quarterYearInstallment: 750,
        halfMonthInstallment: 125,
      },
    ],
    propertyFloor: 1,
    //attributes to be added from compound object to construct apartment as a residential property
    projectName: compoundData.name,
    projectNameArabic: compoundData.nameArabic,
    projectType: compoundData.type,
    projectId: compoundData.id,
    projectImage: compoundData.projectImage,
    address: compoundData.address,
    addressArabic: compoundData.addressArabic,
    distance: compoundData.distance,
    blockNumber: "37 H",
    blockNumberArabic: "٣٧ اتش",
    buildingFloors: 2,
  },
];
//---------------------------------------------------------------------------------------------//
//---------------------------------------------------------------------------------------------//
export const searchResultsStudios = [
  {
    id: "1",
    name: "Studio No. 123",
    nameArabic: " ستوديو رقم ١",
    propertyCAD: [{ floor: 1, CAD: propertyCAD }], // array of floors & CAD for each floor
    unitImage: studio,
    tags: ["Residential"],
    area: 300,
    pricePerMeter: 1600,
    totalPrice: 230000,
    implementingCompanyLogo: "",
    type: "residential",
    category: "studio",
    status: "free", //free or pending or taken
    finishing: "full", //full or half or none
    plans: [
      {
        deposit: 50, // from 0 to 100 : where 50 means 50%
        years: 7, //installment years
        yearInstallment: 1200,
        quarterYearInstallment: 300,
        halfMonthInstallment: 50,
      },
      {
        deposit: 25, // from 0 to 100 : where 50 means 50%
        years: 4, //installment years
        yearInstallment: 3000,
        quarterYearInstallment: 750,
        halfMonthInstallment: 125,
      },
    ],
    propertyFloor: 1,
    //attributes to be added from compound object to construct studio as a residential property
    projectName: compoundData.name,
    projectNameArabic: compoundData.nameArabic,
    projectType: compoundData.type,
    projectId: compoundData.id,
    projectImage: compoundData.projectImage,
    address: compoundData.address,
    addressArabic: compoundData.addressArabic,
    distance: compoundData.distance,
    blockNumber: "37 H",
    blockNumberArabic: "٣٧ اتش",
    buildingFloors: 6,
  },
];
//---------------------------------------------------------------------------------------------//
//---------------------------------------------------------------------------------------------//
export const searchResultsHotelUnitsR = [
  {
    id: "1",
    name: "Room No. 414",
    nameArabic: " غرفة ١",
    propertyCAD: [{ floor: 1, CAD: propertyCAD }], // array of floors & CAD for each floor
    unitImage: hotelUnits,
    tags: ["Residential"],
    area: 300,
    pricePerMeter: 1600,
    totalPrice: 230000,
    implementingCompanyLogo: "",
    type: "residential",
    category: "hotel units",
    status: "free", //free or pending or taken
    finishing: "full", //full or half or none
    plans: [
      {
        deposit: 50, // from 0 to 100 : where 50 means 50%
        years: 7, //installment years
        yearInstallment: 1200,
        quarterYearInstallment: 300,
        halfMonthInstallment: 50,
      },
      {
        deposit: 25, // from 0 to 100 : where 50 means 50%
        years: 4, //installment years
        yearInstallment: 3000,
        quarterYearInstallment: 750,
        halfMonthInstallment: 125,
      },
    ],
    propertyFloor: 8,
    //attributes to be added from compound object to construct studio as a residential property
    projectName: compoundData.name,
    projectNameArabic: compoundData.nameArabic,
    projectType: compoundData.type,
    projectId: compoundData.id,
    projectImage: compoundData.projectImage,
    address: compoundData.address,
    addressArabic: compoundData.addressArabic,
    distance: compoundData.distance,
    blockNumber: "37 H",
    blockNumberArabic: "٣٧ اتش",
    buildingFloors: 10,
  },
];
//----------------------------------------------------------------------------------------------//
//----------------------------------------------------------------------------------------------//
//----------------------------------------------------------------------------------------------//
//The following objects simulate the (unit card for residential property) used in (search page)-//
//(duplex villas, detached villas, twin houses), each villa or twin house is divided into floors//
//------------So we have table for villas, and table for floors (inside villas)-----------------//
//-------we return whole villas that have allFloorsAvailable:true, returned by GET request------//
//A new attribute is added called propertyFloor:0, where 0 means whole villa is available-------//
//Some other attributes are added from compounds table and renamed as name => projectName-------//
//-additionally we return available floors in a villa, by checking status attribute inside floor//
//-floor attributes are returned in new form, example displayed in 2nd object of following array//
//A new attribute is added called propertyFloor, and it's value obtained from floors table------//
//---------------------Some other attributes are added from villas table-----------------------//
//Some other attributes are added from compounds table and renamed as name => projectName-------//
export const searchResultsDuplexVillas = [
  {
    id: "2",
    name: "Duplex Villa No.10",
    nameArabic: "فيلا دوبلكس رقم ",
    buildingFloors: 2,
    propertyCAD: [
      {
        floor: 1,
        CAD: propertyCAD,
        unitImage: duplexVillas,
        area: 300,
        pricePerMeter: 1600,
        totalPrice: 230000,
        status: "free", //free or pending or taken
        finishing: "full", //full or half or none
        plans: [
          {
            deposit: 50, // from 0 to 100 : where 50 means 50%
            years: 7, //installment years
            yearInstallment: 1200,
            quarterYearInstallment: 300,
            halfMonthInstallment: 50,
          },
          {
            deposit: 25, // from 0 to 100 : where 50 means 50%
            years: 4, //installment years
            yearInstallment: 3000,
            quarterYearInstallment: 750,
            halfMonthInstallment: 125,
          },
        ],
      },
      {
        floor: 2,
        CAD: propertyCAD,
        unitImage: duplexVillas,
        area: 300,
        pricePerMeter: 1600,
        totalPrice: 230000,
        status: "free", //free or pending or taken
        finishing: "full", //full or half or none
        plans: [
          {
            deposit: 50, // from 0 to 100 : where 50 means 50%
            years: 7, //installment years
            yearInstallment: 1200,
            quarterYearInstallment: 300,
            halfMonthInstallment: 50,
          },
          {
            deposit: 25, // from 0 to 100 : where 50 means 50%
            years: 4, //installment years
            yearInstallment: 3000,
            quarterYearInstallment: 750,
            halfMonthInstallment: 125,
          },
        ],
      },
    ],
    unitImage: duplexVillas,
    tags: ["Residential"],
    implementingCompanyLogo: "",
    type: "residential",
    category: "duplex villas",
    area: 600,
    pricePerMeter: 3200,
    totalPrice: 460000,
    finishing: "full", //full or half or none
    plans: [
      {
        deposit: 70, // from 0 to 100 : where 50 means 50%
        years: 10, //installment years
        yearInstallment: 1000,
        quarterYearInstallment: 275,
        halfMonthInstallment: 190,
      },
      {
        deposit: 60, // from 0 to 100 : where 50 means 50%
        years: 10, //installment years
        yearInstallment: 1000,
        quarterYearInstallment: 750,
        halfMonthInstallment: 999,
      },
    ],
    allFloorsAvailable: true, // all floors available => true, otherwise => false
    // attributes added from compound object to construct villa as residential property
    propertyFloor: 0,
    projectName: compoundData.name,
    projectNameArabic: compoundData.nameArabic,
    projectType: compoundData.type,
    projectId: compoundData.id,
    projectImage: compoundData.projectImage,
    address: compoundData.address,
    addressArabic: compoundData.addressArabic,
    distance: compoundData.distance,
  },
  {
    id: "99",
    // the following 8 attributes are floor attributes in new form
    propertyCAD: [
      {
        //remove 7 attributes from floor and add them externally
        CAD: propertyCAD,
      },
    ],
    unitImage: duplexVillas,
    status: "free", //free or pending or taken
    area: 300,
    pricePerMeter: 1600,
    totalPrice: 230000,
    finishing: "full", //full or half or none
    plans: [
      {
        deposit: 50, // from 0 to 100 : where 50 means 50%
        years: 7, //installment years
        yearInstallment: 1200,
        quarterYearInstallment: 300,
        halfMonthInstallment: 50,
      },
      {
        deposit: 25, // from 0 to 100 : where 50 means 50%
        years: 4, //installment years
        yearInstallment: 3000,
        quarterYearInstallment: 750,
        halfMonthInstallment: 125,
      },
    ],
    //--------------------end of 8 attributes-----------------------//
    //returned from villa object
    name: "Duplex Villa No.12",
    nameArabic: "فيلا دوبلكس رقم ",
    buildingFloors: 2,
    tags: ["Residential"],
    implementingCompanyLogo: "",
    type: "residential",
    category: "duplex villas",
    //-------------------------------------------------------------//
    //new property added from floor
    propertyFloor: 2,
    //-------------------------------------------------------------//
    // attributes added from compound object to construct villa as residential property
    projectName: compoundData.name,
    projectNameArabic: compoundData.nameArabic,
    projectType: compoundData.type,
    projectId: compoundData.id,
    projectImage: compoundData.projectImage,
    address: compoundData.address,
    addressArabic: compoundData.addressArabic,
    distance: compoundData.distance,
  },
];
//---------------------------------------------------------------------------------------------//
//---------------------------------------------------------------------------------------------//
export const searchResultsDetachedVillas = [
  {
    id: "3",
    name: "Detached Villa No.15",
    nameArabic: "فيلا منفصلة رقم ",
    buildingFloors: 2,
    propertyCAD: [
      {
        floor: 1,
        CAD: propertyCAD,
        unitImage: detachedVillas,
        area: 300,
        pricePerMeter: 1600,
        totalPrice: 230000,
        status: "free", //free or pending or taken
        finishing: "full", //full or half or none
        plans: [
          {
            deposit: 50, // from 0 to 100 : where 50 means 50%
            years: 7, //installment years
            yearInstallment: 1200,
            quarterYearInstallment: 300,
            halfMonthInstallment: 50,
          },
          {
            deposit: 25, // from 0 to 100 : where 50 means 50%
            years: 4, //installment years
            yearInstallment: 3000,
            quarterYearInstallment: 750,
            halfMonthInstallment: 125,
          },
        ],
      },
      {
        floor: 2,
        CAD: propertyCAD,
        unitImage: detachedVillas,
        area: 300,
        pricePerMeter: 1600,
        totalPrice: 230000,
        status: "free", //free or pending or taken
        finishing: "full", //full or half or none
        plans: [
          {
            deposit: 50, // from 0 to 100 : where 50 means 50%
            years: 7, //installment years
            yearInstallment: 1200,
            quarterYearInstallment: 300,
            halfMonthInstallment: 50,
          },
          {
            deposit: 25, // from 0 to 100 : where 50 means 50%
            years: 4, //installment years
            yearInstallment: 3000,
            quarterYearInstallment: 750,
            halfMonthInstallment: 125,
          },
        ],
      },
    ],
    unitImage: detachedVillas,
    tags: ["Residential"],
    implementingCompanyLogo: "",
    type: "residential",
    category: "detached villas",
    area: 600,
    pricePerMeter: 3200,
    totalPrice: 460000,
    finishing: "full", //full or half or none
    plans: [
      {
        deposit: 70, // from 0 to 100 : where 50 means 50%
        years: 10, //installment years
        yearInstallment: 1000,
        quarterYearInstallment: 275,
        halfMonthInstallment: 190,
      },
      {
        deposit: 60, // from 0 to 100 : where 50 means 50%
        years: 10, //installment years
        yearInstallment: 1000,
        quarterYearInstallment: 750,
        halfMonthInstallment: 999,
      },
    ],
    allFloorsAvailable: true, // all floors available => true, otherwise => false
    // attributes added from compound object to construct villa as residential property
    propertyFloor: 0,
    projectName: compoundData.name,
    projectNameArabic: compoundData.nameArabic,
    projectType: compoundData.type,
    projectId: compoundData.id,
    projectImage: compoundData.projectImage,
    address: compoundData.address,
    addressArabic: compoundData.addressArabic,
    distance: compoundData.distance,
  },
];
//---------------------------------------------------------------------------------------------//
//---------------------------------------------------------------------------------------------//
// Twin house is 2-halfed villa, we deal with each half as a single villa
export const searchResultsTwinHouses = [
  {
    id: "3",
    name: "Twin House 15-I",
    nameArabic: "توين هاوس رقم ",
    buildingFloors: 2,
    propertyCAD: [
      {
        floor: 1,
        CAD: propertyCAD,
        unitImage: twinHouse,
        area: 300,
        pricePerMeter: 1600,
        totalPrice: 230000,
        status: "free", //free or pending or taken
        finishing: "full", //full or half or none
        plans: [
          {
            deposit: 50, // from 0 to 100 : where 50 means 50%
            years: 7, //installment years
            yearInstallment: 1200,
            quarterYearInstallment: 300,
            halfMonthInstallment: 50,
          },
          {
            deposit: 25, // from 0 to 100 : where 50 means 50%
            years: 4, //installment years
            yearInstallment: 3000,
            quarterYearInstallment: 750,
            halfMonthInstallment: 125,
          },
        ],
      },
      {
        floor: 2,
        CAD: propertyCAD,
        unitImage: twinHouse,
        area: 300,
        pricePerMeter: 1600,
        totalPrice: 230000,
        status: "free", //free or pending or taken
        finishing: "full", //full or half or none
        plans: [
          {
            deposit: 50, // from 0 to 100 : where 50 means 50%
            years: 7, //installment years
            yearInstallment: 1200,
            quarterYearInstallment: 300,
            halfMonthInstallment: 50,
          },
          {
            deposit: 25, // from 0 to 100 : where 50 means 50%
            years: 4, //installment years
            yearInstallment: 3000,
            quarterYearInstallment: 750,
            halfMonthInstallment: 125,
          },
        ],
      },
    ],
    unitImage: twinHouse,
    tags: ["Residential"],
    implementingCompanyLogo: "",
    type: "residential",
    category: "twin house",
    area: 600,
    pricePerMeter: 3200,
    totalPrice: 460000,
    finishing: "full", //full or half or none
    plans: [
      {
        deposit: 70, // from 0 to 100 : where 50 means 50%
        years: 10, //installment years
        yearInstallment: 1000,
        quarterYearInstallment: 275,
        halfMonthInstallment: 190,
      },
      {
        deposit: 60, // from 0 to 100 : where 50 means 50%
        years: 10, //installment years
        yearInstallment: 1000,
        quarterYearInstallment: 750,
        halfMonthInstallment: 999,
      },
    ],
    allFloorsAvailable: true, // all floors available => true, otherwise => false
    // attributes added from compound object to construct twin house as residential property
    propertyFloor: 0,
    projectName: compoundData.name,
    projectNameArabic: compoundData.nameArabic,
    projectType: compoundData.type,
    projectId: compoundData.id,
    projectImage: compoundData.projectImage,
    address: compoundData.address,
    addressArabic: compoundData.addressArabic,
    distance: compoundData.distance,
  },
];
//---------------------------------------------------------------------------------------------//
//---------------------------------------------------------------------------------------------//
//---------------------------------------------------------------------------------------------//
//---------------------------------------------------------------------------------------------//
//The following objects simulate the (unit card for investment property) used in (search page)-//
//----investment property should be inside floor, and floor should be inside mall or tower-----//
//--we return units that have status:"free", the unit properties are returned by GET request---//
//----A new attribute is added called propertyFloor, this can be obtained from floors table----//
//Some other attributes are added from the mall or tower table and renamed as name=>projectName//
//---------------------------------------------------------------------------------------------//
//all units that have category="coffee shops"
export const searchResultsCoffeShops = [
  {
    id: "1",
    name: "coffee shop No.60",
    nameArabic: "كافيه رقم ٦٠",
    propertyCAD: [{ floor: 1, CAD: propertyCAD }], // array of floors & CAD for each floor
    unitImage: coffeeShops,
    tags: ["Investment"],
    area: 300,
    pricePerMeter: 1600,
    totalPrice: 230000,
    type: "investment",
    category: "coffee shops",
    status: "free", //free or pending or taken
    finishing: "full", //full or half or none
    plans: [
      {
        deposit: 50, // from 0 to 100 : where 50 means 50%
        years: 7, //installment years
        yearInstallment: 1200,
        quarterYearInstallment: 300,
        halfMonthInstallment: 50,
      },
      {
        deposit: 25, // from 0 to 100 : where 50 means 50%
        years: 4, //installment years
        yearInstallment: 3000,
        quarterYearInstallment: 750,
        halfMonthInstallment: 125,
      },
    ],
    propertyFloor: 1,
    //attributes to be added from mall object to construct shop as an investment property
    projectName: mallData.name,
    projectNameArabic: mallData.nameArabic,
    projectType: mallData.type,
    projectId: mallData.id,
    projectImage: coffeeShops,
    address: mallData.address,
    addressArabic: mallData.addressArabic,
    distance: mallData.distance,
    buildingFloors: mallData.buildingFloors,
  },
];
//---------------------------------------------------------------------------------------------//
//---------------------------------------------------------------------------------------------//
//all units that have category="restaurants"
export const searchResultsRestaurants = [
  {
    id: "1",
    name: "Restaurant No.60",
    nameArabic: "مطعم رقم ٦٠",
    propertyCAD: [{ floor: 1, CAD: propertyCAD }], // array of floors & CAD for each floor
    unitImage: restaurants,
    tags: ["Investment"],
    area: 300,
    pricePerMeter: 1600,
    totalPrice: 230000,
    type: "investment",
    category: "restaurants",
    status: "free", //free or pending or taken
    finishing: "full", //full or half or none
    plans: [
      {
        deposit: 50, // from 0 to 100 : where 50 means 50%
        years: 7, //installment years
        yearInstallment: 1200,
        quarterYearInstallment: 300,
        halfMonthInstallment: 50,
      },
      {
        deposit: 25, // from 0 to 100 : where 50 means 50%
        years: 4, //installment years
        yearInstallment: 3000,
        quarterYearInstallment: 750,
        halfMonthInstallment: 125,
      },
    ],
    propertyFloor: 1,
    //attributes to be added from mall object to construct shop as an investment property
    projectName: mallData.name,
    projectNameArabic: mallData.nameArabic,
    projectType: mallData.type,
    projectId: mallData.id,
    projectImage: mallData.projectImage,
    address: mallData.address,
    addressArabic: mallData.addressArabic,
    distance: mallData.distance,
    buildingFloors: mallData.buildingFloors,
  },
];
//---------------------------------------------------------------------------------------------//
//---------------------------------------------------------------------------------------------//
//all units that have category="shops"
export const searchResultsShops = [
  {
    id: "1",
    name: "shop No.60",
    nameArabic: "محل رقم ٦٠",
    propertyCAD: [{ floor: 1, CAD: propertyCAD }], // array of floors & CAD for each floor
    unitImage: shops,
    tags: ["Investment"],
    area: 300,
    pricePerMeter: 1600,
    totalPrice: 230000,
    type: "investment",
    category: "shops",
    status: "free", //free or pending or taken
    finishing: "full", //full or half or none
    plans: [
      {
        deposit: 50, // from 0 to 100 : where 50 means 50%
        years: 7, //installment years
        yearInstallment: 1200,
        quarterYearInstallment: 300,
        halfMonthInstallment: 50,
      },
      {
        deposit: 25, // from 0 to 100 : where 50 means 50%
        years: 4, //installment years
        yearInstallment: 3000,
        quarterYearInstallment: 750,
        halfMonthInstallment: 125,
      },
    ],
    propertyFloor: 1,
    //attributes to be added from mall object to construct shop as an investment property
    projectName: mallData.name,
    projectNameArabic: mallData.nameArabic,
    projectType: mallData.type,
    projectId: mallData.id,
    projectImage: shops,
    address: mallData.address,
    addressArabic: mallData.addressArabic,
    distance: mallData.distance,
    buildingFloors: mallData.buildingFloors,
  },
];
//---------------------------------------------------------------------------------------------//
//---------------------------------------------------------------------------------------------//
//all units that have category="office"
export const searchResultsOffices = [
  {
    id: "1",
    name: "office No.60",
    nameArabic: "مكتب ٦٠",
    propertyCAD: [{ floor: 1, CAD: propertyCAD }], // array of floors & CAD for each floor
    unitImage: offices,
    tags: ["Investment"],
    area: 300,
    pricePerMeter: 1600,
    totalPrice: 230000,
    type: "investment",
    category: "office",
    status: "free", //free or pending or taken
    finishing: "full", //full or half or none
    plans: [
      {
        deposit: 50, // from 0 to 100 : where 50 means 50%
        years: 7, //installment years
        yearInstallment: 1200,
        quarterYearInstallment: 300,
        halfMonthInstallment: 50,
      },
      {
        deposit: 25, // from 0 to 100 : where 50 means 50%
        years: 4, //installment years
        yearInstallment: 3000,
        quarterYearInstallment: 750,
        halfMonthInstallment: 125,
      },
    ],
    propertyFloor: 5,
    //attributes to be added from mall object to construct office as an investment property
    projectName: mallData.name,
    projectNameArabic: mallData.nameArabic,
    projectType: mallData.type,
    projectId: mallData.id,
    projectImage: offices,
    address: mallData.address,
    addressArabic: mallData.addressArabic,
    distance: mallData.distance,
    buildingFloors: mallData.buildingFloors,
  },
];
//---------------------------------------------------------------------------------------------//
//---------------------------------------------------------------------------------------------//
//all units that have category="partitions"
export const searchResultsPartitions = [
  {
    id: "1",
    name: "partition No.60",
    nameArabic: "بارتشن ٦٠",
    propertyCAD: [{ floor: 1, CAD: propertyCAD }], // array of floors & CAD for each floor
    unitImage: partitions,
    tags: ["Investment"],
    area: 300,
    pricePerMeter: 1600,
    totalPrice: 230000,
    type: "investment",
    category: "partitions",
    status: "free", //free or pending or taken
    finishing: "full", //full or half or none
    plans: [
      {
        deposit: 50, // from 0 to 100 : where 50 means 50%
        years: 7, //installment years
        yearInstallment: 1200,
        quarterYearInstallment: 300,
        halfMonthInstallment: 50,
      },
      {
        deposit: 25, // from 0 to 100 : where 50 means 50%
        years: 4, //installment years
        yearInstallment: 3000,
        quarterYearInstallment: 750,
        halfMonthInstallment: 125,
      },
    ],
    propertyFloor: 1,
    //attributes to be added from mall object to construct partition as an investment property
    projectName: mallData.name,
    projectNameArabic: mallData.nameArabic,
    projectType: mallData.type,
    projectId: mallData.id,
    projectImage: partitions,
    address: mallData.address,
    addressArabic: mallData.addressArabic,
    distance: mallData.distance,
    buildingFloors: mallData.buildingFloors,
  },
];
//---------------------------------------------------------------------------------------------//
//---------------------------------------------------------------------------------------------//
//all units that have category="pharmacies"
export const searchResultsPharmacies = [
  {
    id: "1",
    name: "pharmacy No.60",
    nameArabic: "صيدلية ٦٠",
    propertyCAD: [{ floor: 1, CAD: propertyCAD }], // array of floors & CAD for each floor
    unitImage: pharmacies,
    tags: ["Investment"],
    area: 300,
    pricePerMeter: 1600,
    totalPrice: 230000,
    type: "investment",
    category: "pharmacies",
    status: "free", //free or pending or taken
    finishing: "full", //full or half or none
    plans: [
      {
        deposit: 50, // from 0 to 100 : where 50 means 50%
        years: 7, //installment years
        yearInstallment: 1200,
        quarterYearInstallment: 300,
        halfMonthInstallment: 50,
      },
      {
        deposit: 25, // from 0 to 100 : where 50 means 50%
        years: 4, //installment years
        yearInstallment: 3000,
        quarterYearInstallment: 750,
        halfMonthInstallment: 125,
      },
    ],
    propertyFloor: 3,
    //attributes to be added from mall object to construct pharmacy as an investment property
    projectName: mallData.name,
    projectNameArabic: mallData.nameArabic,
    projectType: mallData.type,
    projectId: mallData.id,
    projectImage: pharmacies,
    address: mallData.address,
    addressArabic: mallData.addressArabic,
    distance: mallData.distance,
    buildingFloors: mallData.buildingFloors,
  },
];
//---------------------------------------------------------------------------------------------//
//---------------------------------------------------------------------------------------------//
//all units that have category="clinics"
export const searchResultsClinics = [
  {
    id: "1",
    name: "clinic No.60",
    nameArabic: "عيادة ٦٠",
    propertyCAD: [{ floor: 1, CAD: propertyCAD }], // array of floors & CAD for each floor
    unitImage: clinics,
    tags: ["Investment"],
    area: 300,
    pricePerMeter: 1600,
    totalPrice: 230000,
    type: "investment",
    category: "clinics",
    status: "free", //free or pending or taken
    finishing: "full", //full or half or none
    plans: [
      {
        deposit: 50, // from 0 to 100 : where 50 means 50%
        years: 7, //installment years
        yearInstallment: 1200,
        quarterYearInstallment: 300,
        halfMonthInstallment: 50,
      },
      {
        deposit: 25, // from 0 to 100 : where 50 means 50%
        years: 4, //installment years
        yearInstallment: 3000,
        quarterYearInstallment: 750,
        halfMonthInstallment: 125,
      },
    ],
    propertyFloor: 3,
    //attributes to be added from mall object to construct clinic as an investment property
    projectName: mallData.name,
    projectNameArabic: mallData.nameArabic,
    projectType: mallData.type,
    projectId: mallData.id,
    projectImage: clinics,
    address: mallData.address,
    addressArabic: mallData.addressArabic,
    distance: mallData.distance,
    buildingFloors: mallData.buildingFloors,
  },
];
//---------------------------------------------------------------------------------------------//
//---------------------------------------------------------------------------------------------//
//all units that have category="hotel units" && type="investment"
export const searchResultsHotelUnitsI = [
  {
    id: "1",
    name: "Room No. 414",
    nameArabic: " غرفة ١",
    propertyCAD: [{ floor: 1, CAD: propertyCAD }], // array of floors & CAD for each floor
    unitImage: hotelUnits,
    tags: ["Investment"],
    area: 300,
    pricePerMeter: 1600,
    totalPrice: 230000,
    implementingCompanyLogo: "",
    type: "investment",
    category: "hotel units",
    status: "free", //free or pending or taken
    finishing: "full", //full or half or none
    plans: [
      {
        deposit: 50, // from 0 to 100 : where 50 means 50%
        years: 7, //installment years
        yearInstallment: 1200,
        quarterYearInstallment: 300,
        halfMonthInstallment: 50,
      },
      {
        deposit: 25, // from 0 to 100 : where 50 means 50%
        years: 4, //installment years
        yearInstallment: 3000,
        quarterYearInstallment: 750,
        halfMonthInstallment: 125,
      },
    ],
    propertyFloor: 7,
    //attributes to be added from compound object to construct studio as a residential property
    projectName: mallData.name,
    projectNameArabic: mallData.nameArabic,
    projectType: mallData.type,
    projectId: mallData.id,
    projectImage: hotelUnits,
    address: mallData.address,
    addressArabic: mallData.addressArabic,
    distance: mallData.distance,
    buildingFloors: 10,
  },
];
