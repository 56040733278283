import axios from "../axios.js";
//------------------------------//
export function searchPage(category, location) {
  return async (dispatch) => {
    dispatch({
      type: "SET_LOADING",
      payload: true,
    });
    //-------------------------------------//
    //-------------------------------------//
    try {
      const response = await axios.get(`/malls/`);
      dispatch({
        type: "SET_SEARCH_AVAILABLE_MALLS",
        payload: response.data,
      });
    } catch (error) {
      console.log(error);
    }
    //-------------------------------------//
    //-------------------------------------//
    try {
      const response = await axios.get(`/compounds/`);
      dispatch({
        type: "SET_SEARCH_AVAILABLE_COMPOUNDS",
        payload: response.data,
      });
    } catch (error) {
      console.log(error);
    }
    //-------------------------------------//
    //-------------------------------------//
    try {
      const response = await axios.get(`/companies/`);
      dispatch({
        type: "SET_SEARCH_AVAILABLE_COMPANIES",
        payload: response.data,
      });
    } catch (error) {
      console.log(error);
    }
    //-------------------------------------//
    //-------------------------------------//
    try {
      const response = await axios.get(`/locations/`);
      dispatch({
        type: "SET_SEARCH_AVAILABLE_LOCATIONS",
        payload: response.data,
      });
    } catch (error) {
      console.log(error);
    }
    //-------------------------------------//
    //-------------------------------------//
    //fetching data based on route parameters
    if (category) {
      try {
        const url = location.pathname.includes("/residential")
          ? "/search-residential-unit/"
          : "/search-investment-unit/";
        const response = await axios.get(url, {
          params: {
            category: category === "apartments" ? "apartment" : category,
            status: "free",
          },
        });
        dispatch({
          type: "SET_SEARCH_RESULTS",
          payload: response.data,
        });
      } catch (error) {
        console.log(error);
      }
    } else {
      dispatch({
        type: "SET_SEARCH_RESULTS",
        payload: {
          searchResults: [],
          filters: false,
        },
      });
    }
    //-------------------------------------//
    //-------------------------------------//
    dispatch({
      type: "SET_LOADING",
      payload: false,
    });
  };
}
//------------------------------//
export function searchByFilters(
  category,
  minInstallment,
  maxInstallment,
  roomsNumber,
  floorNumber,
  minPrice,
  maxPrice,
  minArea,
  maxArea,
  minReservationDeposit,
  maxReservationDeposit,
  finishing,
  location,
  url
) {
  return async (dispatch) => {
    dispatch({
      type: "SET_FILTER_LOADER",
      payload: true,
    });
    try {
      const response = await axios.get(url, {
        params: {
          category: category === "apartments" ? "apartment" : category,
          installmentYears_lte:
            maxInstallment === 10 && minInstallment === 1
              ? null
              : maxInstallment,
          installmentYears_gte:
            maxInstallment === 10 && minInstallment === 1
              ? null
              : minInstallment,
          roomsNumber: roomsNumber === 0 ? null : roomsNumber,
          floor: floorNumber,
          price_lte: maxPrice,
          price_gte: minPrice,
          maxArea: maxArea,
          minArea: minArea,
          deposit_lte:
            maxReservationDeposit === 50 && minReservationDeposit === 0
              ? null
              : maxReservationDeposit,
          deposit_gte:
            maxReservationDeposit === 50 && minReservationDeposit === 0
              ? null
              : minReservationDeposit,
          finishing: finishing,
          // location: location,
          status: "free",
        },
      });
      console.log(response);
      dispatch({
        type: "SET_FILTER_RESULTS",
        payload: response.data,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: "SET_FILTER_RESULTS",
        payload: [],
      });
    }
    dispatch({
      type: "SET_FILTER_LOADER",
      payload: false,
    });
  };
}
