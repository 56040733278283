import { toast } from "react-toastify";
import * as bootstrap from "bootstrap";
import axios from "../axios.js";
//------------------------------//
export function addToBooking(user, property) {
  return async (dispatch) => {
    try {
      dispatch({
        type: "SET_BOOKING_BUTTON_LOADER",
        payload: true,
      });
      await axios.post(
        `/bookings/`,
        { units: [property.id] },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      dispatch({
        type: "SET_USER_FAVOURITE_LIST",
        payload: user.favourite.filter((unit) => unit.id !== property.id),
      });
      const myModal = new bootstrap.Modal(document.getElementById("success-booking-modal"));

      myModal.show();
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data["message"] || "Failed to add unit to your booking list");
    } finally {
      dispatch({
        type: "SET_BOOKING_BUTTON_LOADER",
        payload: false,
      });
    }
  };
}
//------------------------------//
export function getBooking(user) {
  return async (dispatch) => {
    try {
      dispatch({
        type: "SET_LOADING",
        payload: true,
      });
      const response = await axios.get(`/bookings/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      });
      dispatch({
        type: "SET_USER_BOOKING_LIST",
        payload: response.data,
      });
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data["message"] || "Failed to get your booking list");
    } finally {
      dispatch({
        type: "SET_LOADING",
        payload: false,
      });
    }
  };
}
//------------------------------//
export function deleteBooking(user, id) {
  return async (dispatch) => {
    try {
      await axios.delete(`/bookings/${id}/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      });
      dispatch({
        type: "SET_USER_BOOKING_LIST",
        payload: user.booking.filter((unit) => unit.id !== id),
      });
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data["message"] || "Failed to remove unit from your booking list");
    }
  };
}
