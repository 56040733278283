import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { changeSiteLanguage } from "../../actions/language";
import { NavDropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import { logout } from "../../actions/logout";
import "./navBar.css";
//-----------------------------------------------------------------------------------------------//
const NavBar = () => {
  const location = useLocation();
  //----------------------------------------------------------------------------------------------//
  const navigate = useNavigate();
  //----------------------------------------------------------------------------------------------//
  const dispatch = useDispatch();
  //----------------------------------------------------------------------------------------------//
  const english = useSelector((state) => state.english.active);
  //----------------------------------------------------------------------------------------------//
  const user = useSelector((state) => state.user.user);
  //----------------------------------------------------------------------------------------------//
  return (
    <nav className="navbar custom-navbar bg-white">
      {/*---------------------Top NavBar----------------------*/}
      {/*---------------------Top NavBar----------------------*/}
      {/* <div className="container-fluid custom-container-fluid-1">
        <div className="top-navbar-wrapper">
          <div className="phones-section">
            {english ? (
              <span className="navbar-text ms-2">
                <i className="fa fa-phone me-2" aria-hidden="true"></i>
                01087930388-0117899030
              </span>
            ) : (
              <span className="navbar-text ms-2">
                <i className="fa fa-phone me-2" aria-hidden="true"></i>
                ٠١٠٨٧٩٣٠٣٨٨ - ٠١١٧٨٩٩٠٣٠
              </span>
            )}
          </div>
          <div className="address-section">
            {english ? (
              <span className="navbar-text ms-2">
                <i className="fa fa-map-o me-2" aria-hidden="true"></i>
                Mohamed bn zaid street, New capital, Cairo Governorate
              </span>
            ) : (
              <span className="navbar-text ms-2">
                <i className="fa fa-map-o me-2" aria-hidden="true"></i>
                شارع محمد بن زايد، العاصمة الجديدة، محافظة القاهرة
              </span>
            )}
          </div>
          <div className="clock-section">
            {english ? (
              <span className="navbar-text ms-2">
                <i className="fa fa-clock-o me-2" aria-hidden="true"></i>
                Sun - Sat: 8 AM - 10 PM
              </span>
            ) : (
              <span className="navbar-text ms-2">
                <i className="fa fa-clock-o me-2" aria-hidden="true"></i>
                الأحد - السبت : ٨ ص - ١٠ م
              </span>
            )}
          </div>
        </div>
      </div> */}
      {/*---------------------Bottom NavBar-------------------*/}
      {/*---------------------Bottom NavBar-------------------*/}
      <div className={english ? "custom-container-fluid-2" : "custom-container-fluid-2-arabic"}>
        {/* ------------------------------------------ */}
        {/* ------------------------------------------ */}
        <div className="brand-section">
          {english ? (
            <h5 className="text-primary">
              <strong>Alassema</strong>
            </h5>
          ) : (
            <h5 className="text-primary">
              <strong>العاصمة</strong>
            </h5>
          )}
        </div>
        {/* ------------------------------------------ */}
        {/* ------------------------------------------ */}
        <div className={english ? "custom-navbar-nav mb-2" : "custom-navbar-nav-arabic mb-2"}>
          <div
            className={
              location.pathname === "/" || location.pathname === "/home"
                ? "me-3 text-center custom-nav-item-active"
                : "me-3 text-center custom-nav-item"
            }
          >
            <div>
              <Link to="/home">{english ? "Home" : "الرئيسية"}</Link>
            </div>
            {(location.pathname === "/" || location.pathname === "/home") && (
              <div className="navbar-dot">
                <i className="fa fa-circle"></i>
              </div>
            )}
          </div>
          {/* -------------------- */}
          {user.id && (
            <div
              className={
                location.pathname === "/favourite"
                  ? "me-3 text-center custom-nav-item-active"
                  : "me-3 text-center custom-nav-item"
              }
            >
              <div>
                <Link to="/favourite">{english ? "Favourite" : "المفضلة"}</Link>
              </div>
              {location.pathname === "/favourite" && (
                <div className="navbar-dot">
                  <i className="fa fa-circle"></i>
                </div>
              )}
            </div>
          )}
          {/* -------------------- */}
          {user.id && (
            <div
              className={
                location.pathname === "/booking"
                  ? "me-3 text-center custom-nav-item-active"
                  : "me-3 text-center custom-nav-item"
              }
            >
              <div>
                <Link to="/booking">{english ? "Booking" : "الحجز"}</Link>
              </div>
              {location.pathname === "/booking" && (
                <div className="navbar-dot">
                  <i className="fa fa-circle"></i>
                </div>
              )}
            </div>
          )}
          {/* -------------------- */}
          <div className="me-3 text-center custom-nav-item">
            <div>
              <a href="tel:+201017762142">
                {!english && <i className="fa fa-phone me-1"></i>}
                {english ? "Call Us" : "اتصل بنا"}
                {english && <i className="fa fa-phone ms-1"></i>}
              </a>
            </div>
          </div>
        </div>
        {/* ------------------------------------------ */}
        {/* ------------------------------------------ */}
        <div className={english ? "custom-navbar-nav mb-2" : "custom-navbar-nav-arabic mb-2"}>
          <form className="d-flex me-3" role="search">
            <input
              className="form-control me-2"
              type="search"
              placeholder={english ? "Search" : "بحث"}
              aria-label="Search"
            ></input>
            <Link to="/investment/search/coffee shops" className="btn btn-outline-primary" type="submit">
              {english ? "Search" : "بحث"}
            </Link>
          </form>
        </div>
        {/* ------------------------------------------ */}
        {/* ------------------------------------------ */}
        <div className={english ? "custom-navbar-nav mb-2" : "custom-navbar-nav-arabic mb-2"}>
          {/* ------------------------- */}
          {/* ------------------------- */}
          <span
            role="button"
            className="btn-link m-1 custom-nav-item"
            onClick={() => {
              dispatch(changeSiteLanguage(!english));
            }}
          >
            <i className="fa fa-language m-1" aria-hidden="true"></i>
            {english === true ? "العربية" : "English"}
          </span>
          {/* ------------------------- */}
          {/* ------------------------- */}
          <span role="button" className="btn-link m-1 custom-nav-item">
            <i className="fa fa-bell-o" aria-hidden="true"></i>
          </span>
          {/* ------------------------- */}
          {/* ------------------------- */}
          {!user.id && !user.userLoader && (
            <>
              <Link className=" m-1" to="/signup">
                <OverlayTrigger
                  placement="bottom"
                  overlay={english ? <Tooltip id="tooltip">sign up</Tooltip> : <Tooltip id="tooltip">تسجيل </Tooltip>}
                >
                  <i style={{ fontSize: "25px" }} className="fa fa-user-plus text-dark"></i>
                </OverlayTrigger>
              </Link>
              <Link className=" m-1" to="/signin">
                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    english ? <Tooltip id="tooltip">sign in</Tooltip> : <Tooltip id="tooltip">تسجيل دخول</Tooltip>
                  }
                >
                  <i className="fa fa-sign-in text-dark" style={{ fontSize: "27px" }}></i>
                </OverlayTrigger>
              </Link>
            </>
          )}
          {/* ------------------------- */}
          {/* ------------------------- */}
          {user.id && !user.userLoader && (
            <div className={english ? "navbar-profile-section m-1" : "navbar-profile-section-arabic m-1"}>
              <img
                src={user.profilePicture}
                style={{ width: "30px", height: "30px", borderRadius: "50%" }}
                alt=""
              ></img>
              <span className="text-darkblue text-bold ms-1 me-1">{user.name}</span>
              <NavDropdown menuVariant="light">
                <NavDropdown.Item
                  className="profile-dropdown-items btn btn-link btn-sm"
                  onClick={() => {
                    navigate("/me");
                  }}
                >
                  <i className="fa fa-user me-1"></i> {english ? "Manage Account" : "ادارة حسابي"}
                </NavDropdown.Item>
                <NavDropdown.Item
                  className="profile-dropdown-items btn btn-link btn-sm"
                  onClick={() => {
                    navigate("/favourite");
                  }}
                >
                  <i className="fa fa-heart-o me-1"></i> {english ? "Saved" : "المفضل"}
                </NavDropdown.Item>
                <NavDropdown.Item
                  className="profile-dropdown-items btn btn-link btn-sm"
                  onClick={() => {
                    localStorage.removeItem("capitalAccessToken");
                    localStorage.removeItem("capitalRefreshToken");
                    dispatch(logout(navigate));
                  }}
                >
                  <i className="fa fa-sign-out me-1"></i> {english ? "Sign Out" : "خروج"}
                </NavDropdown.Item>
              </NavDropdown>
            </div>
          )}
          {/* ------------------------- */}
          {/* ------------------------- */}
        </div>
        {/* ------------------------------------------ */}
        {/* ------------------------------------------ */}
      </div>
      {/*-----------------------------------------------------*/}
      {/*-----------------------------------------------------*/}
    </nav>
  );
};
export default NavBar;
