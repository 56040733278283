import React from "react";
import { useSelector } from "react-redux";
import "./aboutSection.css";
//---------------------------------------------------------------------------------------------//
const AboutSection = ({ image, aboutFirstParagraph, aboutSecondParagraph }) => {
  //--------------------------------------------------------------------------------------------//
  const english = useSelector((state) => state.english.active);
  //--------------------------------------------------------------------------------------------//
  return (
    <div className="company-profile-about-section-wrapper mt-5">
      <div className={english ? "company-profile-about-section-inner" : "company-profile-about-section-inner-arabic"}>
        <div className={english ? "company-profile-about-section-text" : "company-profile-about-section-text-arabic"}>
          <h1 className="text-darkblue mb-3">{english ? "About the company" : "نبذة عن الشركة"}</h1>
          <p className="text-secondary">{aboutFirstParagraph}</p>
          <p className="text-secondary">{aboutSecondParagraph}</p>
        </div>
        <div className="company-profile-about-section-image">
          <img src={image} alt=""></img>
        </div>
      </div>
    </div>
  );
};

export default AboutSection;
