import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../actions/login";
import { ToastContainer } from "react-toastify";
import SpinnerButton from "../Spinner/spinner";
import signIn from "../../assets/signIn/signIn.png";
import { logout } from "../../actions/logout";
import "./SignIn.css";
//---------------------------------------------------------------------------------------------//
const SignIn = () => {
  //-----------------------------------------------------------------//
  const dispatch = useDispatch();
  //-----------------------------------------------------------------//
  const navigate = useNavigate();
  //-----------------------------------------------------------------//
  const [email, setEmail] = useState("");
  //-----------------------------------------------------------------//
  const [password, setPassword] = useState("");
  //-----------------------------------------------------------------//
  const english = useSelector((state) => state.english.active);
  //-----------------------------------------------------------------//
  const loading = useSelector((state) => state.generalPurposeLoader.loading);
  //-----------------------------------------------------------------//
  const user = useSelector((state) => state.user.user);
  //-----------------------------------------------------------------//
  useEffect(() => {
    if (user.id) {
      dispatch(logout);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //-----------------------------------------------------------------//
  return (
    <>
      <div className="custom-signin-section-wrapper">
        <div className="custom-signin-section-inner">
          {/* ------------------------------------------------------------------------- */}
          {/* ------------------------------------------------------------------------- */}
          <div className={english ? "custom-signin-form-section" : "custom-signin-form-section-arabic"}>
            {/*--------------------- headers----------------------- */}
            <div className="custom-signin-form-header">
              <h1 className="text-darkblue text-bold mb-4 mt-5">{english ? "Sign In" : "دخول"}</h1>
              <p className="text-darkblue">{english ? "Welcome To Alassema" : "مرحبا بك في العاصمة"}</p>
              <p className="text-darkblue mb-4">
                {english ? "One Step To Success, Get Started Now" : "خطوة واحدة للنجاح، ابدأ الآن"}
              </p>
            </div>
            {/*---------------------- email------------------------ */}
            {/*---------------------- email------------------------ */}
            <div
              className={
                english ? "custom-signin-single-input-wrapper mb-2" : "custom-signin-single-input-wrapper-arabic mb-2"
              }
            >
              <label htmlFor="email" className="text-darkblue mb-1">
                {english ? "Email" : "البريد الالكتروني"}
              </label>
              <input
                id={english ? "email" : "emailArabic"}
                type="text"
                placeholder={english ? "Enter Your Email" : "ادخل البريد الالكتروني"}
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              ></input>
            </div>
            {/*---------------------password----------------------- */}
            {/*---------------------password----------------------- */}
            <div
              className={
                english ? "custom-signin-single-input-wrapper mb-2" : "custom-signin-single-input-wrapper-arabic mb-2"
              }
            >
              <label htmlFor="password" className="text-darkblue mb-1">
                {english ? "Password" : "كلمة السر"}
              </label>
              <input
                id={english ? "password" : "passwordArabic"}
                type="password"
                placeholder={english ? "Enter Your Password" : "ادخل كلمة السر"}
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              ></input>
            </div>
            {/*----------------------sign in----------------------- */}
            {/*----------------------sign in----------------------- */}
            <div className="custom-signin-button-wrapper">
              <button
                className="btn btn-primary"
                onClick={(e) => {
                  dispatch(login(email, password, navigate, english));
                }}
              >
                {loading && <SpinnerButton />}
                {english ? "Sign In" : "تسجيل الدخول"}
              </button>
            </div>
            {/*------------------don't have account---------------- */}
            {/*------------------don't have account---------------- */}
            <div className={english ? "custom-signin-have-an-account" : "custom-signin-have-an-account-arabic"}>
              <span className="me-1 ms-1">{english ? "Don't have an account?" : "ليس لديك حساب؟"}</span>
              <Link to="/signup">{english ? "Sign up" : "تسجيل"}</Link>
            </div>
            {/*---------------------------------------------------- */}
            {/*---------------------------------------------------- */}
          </div>
          {/* ------------------------------------------------------------------------- */}
          {/* ------------------------------------------------------------------------- */}
          <div className="custom-signin-right-image">
            <img src={signIn} alt=""></img>
          </div>
          {/* ------------------------------------------------------------------------- */}
          {/* ------------------------------------------------------------------------- */}
        </div>
      </div>
      <ToastContainer ltr />
    </>
  );
};

export default SignIn;
