import Spinner from "react-bootstrap/Spinner";
function SpinnerButton({
  color = "white",
  style = { width: "15px", height: "15px" },
}) {
  return (
    <Spinner
      style={style}
      animation="border"
      role="status"
      variant={color}
    ></Spinner>
  );
}

export default SpinnerButton;
