let defaultState = {
  availableCompounds: [],
};

const availableCompounds = (state = defaultState, action) => {
  if (action.type === "SET_SEARCH_AVAILABLE_COMPOUNDS") {
    return {
      ...state,
      availableCompounds: action.payload,
    };
  } else {
    return { ...state };
  }
};

export default availableCompounds;
