let defaultState = {
  loading: false,
};

const generalPurposeLoader = (state = defaultState, action) => {
  if (action.type === "SET_LOADING") {
    return {
      ...state,
      loading: action.payload,
    };
  } else {
    return { ...state };
  }
};

export default generalPurposeLoader;
