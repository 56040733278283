import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { toast } from "react-toastify";
const api_url = "https://api.eg-newcapital.com/api/v1";
//----------------------------------------------------------------------------------------//
const instance = axios.create({
  baseURL: api_url,
  //time out after 10 minutes
  timeout: 600000,
  headers: {
    "Content-type": "application/json",
  },
});
//----------------------------------------------------------------------------------------//
instance.interceptors.request.use(async (config) => {
  if (config.headers.Authorization) {
    //console.log(config.headers.Authorization)
    const decoded = jwtDecode(config.headers.Authorization);
    const currentdate = Math.floor(new Date().getTime() / 1000);
    // console.log(currentdate);
    // console.log(decoded);
    if (currentdate > decoded.exp - 60) {
      let data = {
        refresh: localStorage.getItem("capitalRefreshToken"),
      };
      try {
        const res = await axios.post(`${api_url}/token/refresh/`, data);
        localStorage.setItem("capitalAccessToken", res.data.access);
        localStorage.setItem("capitalRefreshToken", res.data.refresh);
        config.headers.Authorization = data.access;
      } catch (error) {
        if (error.response.status === 401) {
          alert("Session expired, please login again");
          localStorage.removeItem("capitalRefreshToken");
          window.location.reload();
        }
      }
    }
  }

  return config;
}, null);
//----------------------------------------------------------------------------------------//
instance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    console.log(error);
    if (error?.response?.status) {
      const expectedError = error.response.status >= 400 && error.response.status < 500;
      if (!expectedError) {
        toast.error(error.message);
      }
    } else if (error.message) {
      toast.error(error.message);
    }
    return Promise.reject(error);
  }
);
//----------------------------------------------------------------------------------------//
export default instance;
