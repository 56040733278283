import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { register } from "../../actions/register";
import { ToastContainer } from "react-toastify";
import { logout } from "../../actions/logout";
import SpinnerButton from "../Spinner/spinner";
import signUp from "../../assets/signUp/signUp.png";
import "./SignUp.css";
//---------------------------------------------------------------------------------------------//
const SignUp = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  //-----------------------------------------------------------------//
  const [name, setName] = useState("");
  //-----------------------------------------------------------------//
  const [email, setEmail] = useState("");
  //-----------------------------------------------------------------//
  const [password, setPassword] = useState("");
  //-----------------------------------------------------------------//
  const [termsPrivacy, setTermsPrivacy] = useState(false);
  //-----------------------------------------------------------------//
  const english = useSelector((state) => state.english.active);
  //-----------------------------------------------------------------//
  const loading = useSelector((state) => state.generalPurposeLoader.loading);
  //-----------------------------------------------------------------//
  const user = useSelector((state) => state.user.user);
  //-----------------------------------------------------------------//
  useEffect(() => {
    if (user.id) {
      dispatch(logout);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //-----------------------------------------------------------------//
  return (
    <>
      <div className="custom-signup-section-wrapper">
        <div className="custom-signup-section-inner">
          {/* --------------------------------------------------------- */}
          {/* --------------------------------------------------------- */}
          <div className={english ? "custom-signup-form-section" : "custom-signup-form-section-arabic"}>
            {/*--------------------- headers----------------------- */}
            {/*--------------------- headers----------------------- */}
            <div className="custom-signup-form-header">
              <h1 className="text-darkblue text-bold mb-4 mt-5">{english ? "Sign Up" : "تسجيل"}</h1>
              <p className="text-darkblue">{english ? "Welcome To Alassema" : "مرحبا بك في العاصمة"}</p>
              <p className="text-darkblue mb-4">
                {english ? "One Step To Success, Get Started Now" : "خطوة واحدة للنجاح، ابدأ الآن"}
              </p>
            </div>
            {/*-------------------- full name---------------------- */}
            {/*-------------------- full name---------------------- */}
            <div
              className={
                english ? "custom-signup-single-input-wrapper mb-2" : "custom-signup-single-input-wrapper-arabic mb-2"
              }
            >
              <label htmlFor="fullName" className="text-darkblue mb-1">
                {english ? "Full Name" : "الاسم بالكامل"}
              </label>
              <input
                id={english ? "fullName" : "fullNameArabic"}
                type="text"
                placeholder={english ? "Enter Your Full Name" : "ادخل الاسم بالكامل"}
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
              ></input>
            </div>
            {/*---------------------- email------------------------ */}
            {/*---------------------- email------------------------ */}
            <div
              className={
                english ? "custom-signup-single-input-wrapper mb-2" : "custom-signup-single-input-wrapper-arabic mb-2"
              }
            >
              <label htmlFor="email" className="text-darkblue mb-1">
                {english ? "Email" : "البريد الالكتروني"}
              </label>
              <input
                id={english ? "email" : "emailArabic"}
                type="text"
                placeholder={english ? "Enter Your Email" : "ادخل البريد الالكتروني"}
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              ></input>
            </div>
            {/*---------------------password----------------------- */}
            {/*---------------------password----------------------- */}
            <div
              className={
                english ? "custom-signup-single-input-wrapper mb-2" : "custom-signup-single-input-wrapper-arabic mb-2"
              }
            >
              <label htmlFor="password" className="text-darkblue mb-1">
                {english ? "Password" : "كلمة السر"}
              </label>
              <input
                id={english ? "password" : "passwordArabic"}
                type="password"
                placeholder={english ? "Enter Your Password" : "ادخل كلمة السر"}
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              ></input>
            </div>
            {/*-------------------- check box---------------------- */}
            {/*-------------------- check box---------------------- */}
            <div className="custom-signup-checkbox mb-3">
              {/* ------------------ */}
              {/* ------------------ */}
              {english && (
                <input
                  type="checkbox"
                  className="me-2"
                  onChange={(e) => {
                    setTermsPrivacy(e.target.checked);
                  }}
                ></input>
              )}
              <label htmlFor="agree">
                {english ? (
                  <span>
                    I agree to <a href="/">Terms of services</a> and <a href="/">Privacy policy</a>
                  </span>
                ) : (
                  <span>
                    اوافق على <a href="/">الشروط</a> و <a href="/">سياسة الخصوصية</a>{" "}
                  </span>
                )}
              </label>
              {/* ------------------ */}
              {/* ------------------ */}
              {!english && (
                <input
                  type="checkbox"
                  className="ms-2"
                  onChange={(e) => {
                    setTermsPrivacy(e.target.checked);
                  }}
                ></input>
              )}
              {/* ------------------ */}
              {/* ------------------ */}
              <br></br>
            </div>
            {/*----------------------sign up----------------------- */}
            {/*----------------------sign up----------------------- */}
            <div className="custom-signup-button-wrapper">
              <button
                disabled={termsPrivacy === false ? true : false}
                className="btn btn-primary"
                onClick={(e) => {
                  dispatch(register(name, email, password, navigate, english));
                }}
              >
                {loading && <SpinnerButton />}
                {english ? "Sign Up" : "تسجيل"}
              </button>
            </div>
            {/*-------------------have account--------------------- */}
            {/*-------------------have account--------------------- */}
            <div className={english ? "custom-signup-have-an-account" : "custom-signup-have-an-account-arabic"}>
              <span className="me-1 ms-1">{english ? "Already have an account?" : "هل لديك حساب؟"}</span>
              <Link to="/signin">{english ? "Sign in" : "تسجيل الدخول"}</Link>
            </div>
          </div>
          {/* --------------------------------------------------------- */}
          {/* --------------------------------------------------------- */}
          <div className="custom-signup-right-image">
            <img src={signUp} alt=""></img>
          </div>
          {/* --------------------------------------------------------- */}
          {/* --------------------------------------------------------- */}
        </div>
      </div>
      <ToastContainer ltr />
    </>
  );
};

export default SignUp;
