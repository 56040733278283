import { useRouteError,Link } from "react-router-dom";

export default function ErrorPage() {
  const error = useRouteError();
  console.error(error);

  return (
    <div class="d-flex align-items-center justify-content-center vh-100">
      <div class="text-center">
        <h1 class="display-1 fw-bold">404</h1>
        <p class="fs-3">
          {" "}
          <h3 class="text-danger">Opps!</h3> Page not found.
        </p>
        <p class="lead">The page you’re looking for doesn’t exist.</p>
        <Link to="/home" class="btn btn-primary">
          Go Home
        </Link>
      </div>
    </div>
  );
}
