import apartments from "../assets/residentialProperties/residentialApartments.png";
import duplexVillas from "../assets/residentialProperties/duplexVillas.jfif";
import detachedVillas from "../assets/residentialProperties/detachedVillas.jfif";
import twinHouse from "../assets/residentialProperties/twinHouse.png";
import studio from "../assets/residentialProperties/studio.jfif";
import hotelUnits from "../assets/residentialProperties/hotelUnits.png";

let defaultState = {
  selectedCompound: {},
  loading: true,
  errorMessage: "",
};

const selectedCompound = (state = defaultState, action) => {
  if (action.type === "SET_SELECTED_COMPOUND") {
    let payload = action.payload.selectedCompound;
    if (payload?.compoundUnitsPrices?.length > 0) {
      payload.compoundUnitsPrices.forEach((item) => (item.image = ""));
      // eslint-disable-next-line array-callback-return
      payload.compoundUnitsPrices.map((item) => {
        if (item.tag === "Apartments") {
          item.image = apartments;
        } else if (item.tag === "Duplex Villas") {
          item.image = duplexVillas;
        } else if (item.tag === "Detached Villas") {
          item.image = detachedVillas;
        } else if (item.tag === "Twin Houses") {
          item.image = twinHouse;
        } else if (item.tag === "Studios") {
          item.image = studio;
        } else if (item.tag === "Hotel Units") {
          item.image = hotelUnits;
        }
      });
    }
    return {
      ...state,
      selectedCompound: action.payload.selectedCompound,
      loading: action.payload.loading,
      errorMessage: action.payload.errorMessage,
    };
  } else {
    return { ...state };
  }
};

export default selectedCompound;
