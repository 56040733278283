import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { companyData } from "../../dummy";
import TopImage from "./SubComponents/topImage/topImage";
import MallUnitsPrices from "./SubComponents/mallUnitsPrices/mallUnitsPrices";
import LocationMainFeatures from "./SubComponents/MallMainFeatures/mallMainFeatures";
import AvailableBrands from "./SubComponents/AvailableBrands/availableBrands";
import LocationDetails from "./SubComponents/LocationDetails/locationDetails";
import WhatsAppWidget from "react-whatsapp-chat-widget";
//----------------------------------------------------------------------------------//
import mainFeaturesImage from "../../assets/singleMallPage/mainFeaturesImage.jpg";
//-----------------------------------------------------------------------------------//
import { getSingleMall } from "../../actions/getSingleMall";
import Loader from "../Loader/loader";
import Error from "../ErrorPages/error";
//-----------------------------------------------------------------------------------//
const SingleMallPage = () => {
  const params = useParams();
  //----------------------------------------------------------------------------------//
  const dispatch = useDispatch();
  //----------------------------------------------------------------------------------//
  const english = useSelector((state) => state.english.active);
  //----------------------------------------------------------------------------------//
  const selectedMall = useSelector((state) => state.selectedMall.selectedMall);
  //----------------------------------------------------------------------------------//
  const loading = useSelector((state) => state.selectedMall.loading);
  //----------------------------------------------------------------------------------//
  const errorMessage = useSelector((state) => state.selectedMall.errorMessage);
  //----------------------------------------------------------------------------------//
  function loadPage() {
    dispatch(getSingleMall(params.id, english));
  }
  //----------------------------------------------------------------------------------//
  useEffect(() => {
    loadPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //----------------------------------------------------------------------------------//
  return (
    <Fragment>
      {selectedMall.id && !loading && (
        <>
          <TopImage
            id={selectedMall.id}
            image={selectedMall.backgroundImage}
            name={english ? selectedMall.name : selectedMall.nameArabic}
            acres={selectedMall.acres}
            buildingFloors={selectedMall.buildingFloors}
            mallUnitsPrices={selectedMall.mallUnitsPrices}
            implementingCompany={selectedMall.implementingCompany}
          ></TopImage>
          <MallUnitsPrices mallUnitsPrices={selectedMall.mallUnitsPrices} id={selectedMall.id}></MallUnitsPrices>
          <LocationMainFeatures
            name={english ? selectedMall.name : selectedMall.nameArabic}
            mainFeaturesImage={mainFeaturesImage}
            intro={english ? selectedMall.intro : selectedMall.introArabic}
            importantAreas={english ? selectedMall.importantAreas : selectedMall.importantAreasArabic}
          ></LocationMainFeatures>
          <AvailableBrands popularBrandsImage={selectedMall.popularBrandsImage} />
          <LocationDetails
            location={selectedMall.location}
            mallLocationMap={selectedMall.location.map}
          ></LocationDetails>
          <WhatsAppWidget
            phoneNo="+201009244362"
            position={english ? "right" : "left"}
            autoOpen={true}
            autoOpenTimer={5000}
            iconSize="60"
            iconColor="white"
            iconBgColor="#25D366"
            headerIcon={companyData.logo}
            headerTitle={english ? "Alassema" : "العاصمة"}
            btnTxt={english ? "Start Chat" : "ابدأ الدردشة"}
            messageBoxTxt={
              english
                ? `I want to know more about ${selectedMall.name}`
                : `أرجو معرفة المزيد عن ${selectedMall.nameArabic}`
            }
            chatMessage={
              english ? (
                <>
                  Hi there 👋 <br />
                  <br /> How can I help you?
                </>
              ) : (
                <>
                  مرحبا 👋 <br />
                  <br />
                  كيف تريد ان نساعدك؟
                </>
              )
            }
            messageBox={false}
          ></WhatsAppWidget>
        </>
      )}
      {loading && <Loader />}
      {!loading && !selectedMall.id && <Error message={errorMessage} />}
    </Fragment>
  );
};
export default SingleMallPage;
