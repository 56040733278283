import React from "react";
import { useSelector } from "react-redux";
import CardItem from "../../../CardItem/cardItem";
import "./availableCompounds.css";
//----------------------------------------------------------------------------------------------//
const AvailableCompounds = ({ availableCompounds }) => {
  //--------------------------------------------------------------------------------------------//
  const english = useSelector((state) => state.english.active);
  //--------------------------------------------------------------------------------------------//
  return (
    <div className="custom-available-compounds-wrapper">
      <div
        className={
          english
            ? "custom-available-compounds"
            : "custom-available-compounds-arabic"
        }
      >
        <h1 className="text-darkblue text-bold mb-4 mt-5">
          {english ? "Compounds In Site" : "الكمبوندات المتاحة في الموقع"}
        </h1>
        <div className="custom-available-compounds-container">
          {availableCompounds.slice(0, 4).map((element) => (
            <CardItem
              id={element.id}
              name={element.name}
              nameArabic={element.nameArabic}
              projectImage={element.projectImage}
              buildingFloors={element.buildingFloors}
              propertiesNumber={element.propertiesNumber}
              tags={element.tags}
              address={element.address}
              addressArabic={element.addressArabic}
              distance={element.distance}
              implementingCompanyLogo={element.implementingCompanyLogo}
              type="compound"
              buttonText={english ? "View Details" : "اعرض التفاصيل"}
              viewDetails="false"
            />
          ))}
        </div>
        <div className="text-center m-4">
          <button
            className={
              english
                ? "btn btn-primary btn-lg show-more-button"
                : "btn btn-primary btn-lg show-more-button-arabic"
            }
          >
            {english ? "Show More" : "اعرض المزيد"}
            <i className="fa fa-angle-double-down angle-double-text m-2"></i>
          </button>
        </div>
      </div>
    </div>
  );
};

export default AvailableCompounds;
