import React from "react";
import { useSelector } from "react-redux";
import CardItem from "../../../CardItem/cardItem";
import "./companyProjects.css";
//----------------------------------------------------------------------------------------------//
const CompanyProjects = ({ projects }) => {
  //--------------------------------------------------------------------------------------------//
  const english = useSelector((state) => state.english.active);
  //--------------------------------------------------------------------------------------------//
  return (
    <div className="custom-company-projects-wrapper">
      <div
        className={
          english ? "custom-company-projects" : "custom-company-projects-arabic"
        }
      >
        <h1 className="text-darkblue text-bold mb-4 mt-5">
          {english ? "Company Projects" : "مشروعات الشركة"}
        </h1>
        <div className="custom-company-projects-container">
          {projects.slice(0, 4).map((element) => (
            <CardItem
              key={element.id}
              id={element.id}
              name={element.name}
              nameArabic={element.nameArabic}
              projectImage={element.projectImage}
              buildingFloors={element.buildingFloors}
              propertiesNumber={element.propertiesNumber}
              tags={element.tags}
              address={element.address}
              addressArabic={element.addressArabic}
              distance={element.distance}
              implementingCompany={element.implementingCompany}
              type={element.type}
              buttonText={english ? "View Details" : "اعرض التفاصيل"}
              viewDetails="false"
            />
          ))}
        </div>
        <div className="text-center m-4">
          <button
            className={
              english
                ? "btn btn-primary btn-lg show-more-button"
                : "btn btn-primary btn-lg show-more-button-arabic"
            }
          >
            {english ? "Show More" : "اعرض المزيد"}
            <i className="fa fa-angle-double-down angle-double-text m-2"></i>
          </button>
        </div>
      </div>
    </div>
  );
};

export default CompanyProjects;
