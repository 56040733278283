import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { companyData } from "../../dummy";
import { getSingleLocation } from "../../actions/getSingleLocation";
import Loader from "../Loader/loader";
import Error from "../ErrorPages/error";
//----------------------------------------------------------------------------------//
import TopImage from "./SubComponents/topImage/topImage";
import AboutSection from "./SubComponents/AboutSection/aboutSection";
import LocationMainFeatures from "./SubComponents/LocationMainFeatures/locationMainFeatures";
import AvailableCompounds from "./SubComponents/AvailableCompounds/availableCompounds";
import AvailableCompanies from "../SearchPage/SubComponents/AvailableCompanies/availableCompanies";
//----------------------------------------------------------------------------------//
import aboutLocation from "../../assets/singleLocationPage/aboutLocation.png";
import futureVision from "../../assets/singleLocationPage/futureVision.png";
import mainFeaturesImage from "../../assets/singleLocationPage/mainFeaturesImage.jpg";
import WhatsAppWidget from "react-whatsapp-chat-widget";
//-----------------------------------------------------------------------------------//
const SingleLocationPage = () => {
  const params = useParams();
  //----------------------------------------------------------------------------------//
  const dispatch = useDispatch();
  //----------------------------------------------------------------------------------//
  const english = useSelector((state) => state.english.active);
  //----------------------------------------------------------------------------------//
  const selectedLocation = useSelector((state) => state.selectedLocation.selectedLocation);
  //----------------------------------------------------------------------------------//
  const loading = useSelector((state) => state.selectedLocation.loading);
  //----------------------------------------------------------------------------------//
  const errorMessage = useSelector((state) => state.selectedLocation.errorMessage);
  //----------------------------------------------------------------------------------//
  function loadPage() {
    dispatch(getSingleLocation(params.id, english));
  }
  //----------------------------------------------------------------------------------//
  useEffect(() => {
    loadPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //---------------------------------------------------------------------------------//
  return (
    <Fragment>
      {selectedLocation.id && !loading && (
        <>
          <TopImage
            logo={selectedLocation.logo}
            locationName={english ? selectedLocation.name : selectedLocation.nameArabic}
            acres={selectedLocation.acres}
            propertiesNumber={selectedLocation.propertiesNumber}
            companies={selectedLocation.availableCompanies.length}
            map={selectedLocation.map}
          ></TopImage>
          <AboutSection
            aboutLocationImage={aboutLocation}
            futureVisionImage={futureVision}
            aboutLocationText={english ? selectedLocation.aboutLocation : selectedLocation.aboutLocationArabic}
            futureVisionText={english ? selectedLocation.futureVision : selectedLocation.futureVisionArabic}
          ></AboutSection>
          <LocationMainFeatures
            mainFeaturesImage={mainFeaturesImage}
            intro={english ? selectedLocation.intro : selectedLocation.introArabic}
            mainFeatures={english ? selectedLocation.mainFeatures : selectedLocation.mainFeaturesArabic}
          ></LocationMainFeatures>
          <AvailableCompounds availableCompounds={selectedLocation.availableCompounds}></AvailableCompounds>
          <AvailableCompanies
            text={
              english ? `Companies In ${selectedLocation.name}` : `الشركات المتاحة في ${selectedLocation.nameArabic} `
            }
            availableCompanies={selectedLocation.availableCompanies}
          ></AvailableCompanies>
          <WhatsAppWidget
            phoneNo="+201009244362"
            position={english ? "right" : "left"}
            autoOpen={true}
            autoOpenTimer={5000}
            iconSize="60"
            iconColor="white"
            iconBgColor="#25D366"
            headerIcon={companyData.logo}
            headerTitle={english ? "Alassema" : "العاصمة"}
            btnTxt={english ? "Start Chat" : "ابدأ الدردشة"}
            messageBoxTxt={
              english
                ? `I want to know more about ${selectedLocation.name}`
                : `أرجو معرفة المزيد عن ${selectedLocation.nameArabic}`
            }
            chatMessage={
              english ? (
                <>
                  Hi there 👋 <br />
                  <br /> How can I help you?
                </>
              ) : (
                <>
                  مرحبا 👋 <br />
                  <br />
                  كيف تريد ان نساعدك؟
                </>
              )
            }
            messageBox={false}
          />
        </>
      )}

      {loading && <Loader />}
      {!loading && !selectedLocation.id && <Error message={errorMessage} />}
    </Fragment>
  );
};
export default SingleLocationPage;
