import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import TopImage from "./SubComponents/topImage/topImage";
import CompoundUnitsPrices from "./SubComponents/CompoundUnitsPrices/compoundUnitsPrices";
import LocationMainFeatures from "./SubComponents/CompoundMainFeatures/compoundMainFeatures";
import LocationDetails from "./SubComponents/LocationDetails/locationDetails";
import { companyData } from "../../dummy";
import { getSingleCompound } from "../../actions/getSingleCompound";
import Loader from "../Loader/loader";
import Error from "../ErrorPages/error";
import WhatsAppWidget from "react-whatsapp-chat-widget";
import mainFeaturesImage from "../../assets/singleMallPage/mainFeaturesImage.jpg";
//-----------------------------------------------------------------------------------//
const SingleCompoundPage = () => {
  const dispatch = useDispatch();
  //----------------------------------------------------------------------------------//
  const params = useParams();
  //----------------------------------------------------------------------------------//
  const english = useSelector((state) => state.english.active);
  //----------------------------------------------------------------------------------//
  const selectedCompound = useSelector((state) => state.selectedCompound.selectedCompound);
  //----------------------------------------------------------------------------------//
  const loading = useSelector((state) => state.selectedCompound.loading);
  //----------------------------------------------------------------------------------//
  const errorMessage = useSelector((state) => state.selectedCompound.errorMessage);
  //----------------------------------------------------------------------------------//
  function loadPage() {
    dispatch(getSingleCompound(params.id, english));
  }
  //----------------------------------------------------------------------------------//
  useEffect(() => {
    loadPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //----------------------------------------------------------------------------------//
  return (
    <Fragment>
      {selectedCompound.id && !loading && (
        <>
          <TopImage
            id={selectedCompound.id}
            image={selectedCompound.backgroundImage}
            name={english ? selectedCompound.name : selectedCompound.nameArabic}
            acres={selectedCompound.acres}
            propertiesNumber={selectedCompound.propertiesNumber}
            compoundUnitsPrices={selectedCompound.compoundUnitsPrices}
            implementingCompany={selectedCompound.implementingCompany}
          ></TopImage>
          <CompoundUnitsPrices
            compoundUnitsPrices={selectedCompound.compoundUnitsPrices}
            id={selectedCompound.id}
          ></CompoundUnitsPrices>
          <LocationMainFeatures
            name={english ? selectedCompound.name : selectedCompound.nameArabic}
            mainFeaturesImage={mainFeaturesImage}
            intro={english ? selectedCompound.intro : selectedCompound.introArabic}
            importantAreas={english ? selectedCompound.importantAreas : selectedCompound.importantAreasArabic}
          ></LocationMainFeatures>
          <LocationDetails
            location={selectedCompound.location}
            compoundLocationMap={selectedCompound.location.map}
          ></LocationDetails>
          <WhatsAppWidget
            phoneNo="+201009244362"
            position={english ? "right" : "left"}
            autoOpen={true}
            autoOpenTimer={5000}
            iconSize="60"
            iconColor="white"
            iconBgColor="#25D366"
            headerIcon={companyData.logo}
            headerTitle={english ? "Alassema" : "العاصمة"}
            btnTxt={english ? "Start Chat" : "ابدأ الدردشة"}
            messageBoxTxt={
              english
                ? `I want to know more about ${selectedCompound.name}`
                : `أرجو معرفة المزيد عن ${selectedCompound.nameArabic}`
            }
            chatMessage={
              english ? (
                <>
                  Hi there 👋 <br />
                  <br /> How can I help you?
                </>
              ) : (
                <>
                  مرحبا 👋 <br />
                  <br />
                  كيف تريد ان نساعدك؟
                </>
              )
            }
            messageBox={false}
          ></WhatsAppWidget>
        </>
      )}
      {loading && <Loader />}
      {!loading && !selectedCompound.id && <Error message={errorMessage} />}
    </Fragment>
  );
};
export default SingleCompoundPage;
