import React from "react";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import "./successFavourite.css";
//-----------------------------------------------------------------------------------------//
const SuccessFavourite = ({ property }) => {
  const navigate = useNavigate();
  const location = useLocation();
  //--------------------------------------------------------------------------------------//
  const english = useSelector((state) => state.english.active);
  return (
    <div
      id="success-favourite-modal"
      className="modal"
      tabIndex="-1"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered success-favourite-modal-dialog">
        <div className="modal-content success-favourite-modal-content">
          <div className="modal-header success-favourite-modal-header">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body text-center">
            <div className="mb-4">
              <CheckCircleOutlineIcon
                className="text-success"
                style={{
                  width: "20%",
                  height: "20%",
                  margin: "auto",
                }}
              />
            </div>
            <h4 className="text-darkblue">
              {english
                ? "You have successfully add this unit to your favourite list"
                : "تم اضافة الوحدة الى القائمة المفضلة"}
            </h4>
            {english ? (
              <p className="text-secondary">
                {/* ------------- */}
                You have successfully added unit number ({property.name})
                <br></br>
                {/* ------------- */}
                {property.projectType === "compound" &&
                  property.blockNumber &&
                  `in block ${property.blockNumber}`}
                {/* ------------- */}
                {property.propertyFloor === 0
                  ? ""
                  : ` (floor ${property.propertyFloor}) `}
                {/* ------------- */}
                in ({property.projectName}) to your favourite list"
                {/* ------------- */}
              </p>
            ) : (
              <p className="text-secondary">
                {/* ------------- */}
                لقد قمت باضافة الوحدة رقم ({property.nameArabic})<br></br>
                {/* ------------- */}
                {property.projectType === "compound" &&
                  property.blockNumber &&
                  `فى مبنى ${property.blockNumberArabic}`}
                {/* ------------- */}
                {property.propertyFloor === 0
                  ? ""
                  : ` (طابق ${property.propertyFloor?.toLocaleString(
                      "ar-u-nu-arab"
                    )}) `}
                <br></br>
                {/* ------------- */}
                في ({property.projectNameArabic}) الى القائمة المفضلة
              </p>
            )}
            {!location.pathname.includes("/search") && (
              <Link
                className="btn btn-outline-primary btn-lg"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  navigate("/favourite");
                }}
              >
                {english ? "View Favourite List" : "عرض القائمة المفضلة"}
              </Link>
            )}
            {location.pathname.includes("/search") && (
              <button
                className="btn btn-outline-primary btn-lg"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                {english ? "Confirm" : "تأكيد"}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuccessFavourite;
