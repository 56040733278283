import React from "react";
import "./availableBrands.css";
//----------------------------------------------------------------------------------------------//
const availableBrands = ({ popularBrandsImage }) => {
  return (
    <div className="custom-available-brands-wrapper">
      <div className="custom-available-brands bg-light ">
        <img src={popularBrandsImage} alt=""></img>
      </div>
    </div>
  );
};

export default availableBrands;
