import React from "react";
import { useSelector } from "react-redux";
import "./clientReviews.css";
//------------------------------------------------------------------------------------------------//
const ClientReviews = ({ bestClientReviews }) => {
  //---------------------------------------------------------------------------------//
  const english = useSelector((state) => state.english.active);
  //---------------------------------------------------------------------------------//
  return (
    <div className="custom-clients-reviews-wrapper mb-5">
      <div className={english ? "custom-clients-reviews" : "custom-clients-reviews-arabic"}>
        {/* -------------------------------------- */}
        {/* -------------------------------------- */}
        {english && (
          <h2 className="text-darkblue text-bold mb-4 mt-5">
            What Users Say<br></br>
            About CapitalAgent Services
          </h2>
        )}
        {/* -------------------------------------- */}
        {/* -------------------------------------- */}
        {!english && (
          <h2 className="text-darkblue text-bold mb-4 mt-5">
            ماذا يقول العملاء<br></br>
            عن خدمات كابيتال ايجنت
          </h2>
        )}
        {/* -------------------------------------- */}
        {/* -------------------------------------- */}
        <div className={english ? "custom-clients-reviews-container" : "custom-clients-reviews-container-arabic"}>
          {/* ---------------------- */}
          {/* ---------------------- */}
          {bestClientReviews.length > 0 &&
            bestClientReviews.map((element, index) => (
              <div
                className={
                  index === 1
                    ? "custom-clients-reviews-element second-review"
                    : index === 2
                    ? "custom-clients-reviews-element third-review"
                    : "custom-clients-reviews-element"
                }
                key={element.id}
              >
                <img src={element.source} alt=""></img>
                <p className="text-secondary mt-2">{element.review}</p>
                <h6 className="text-darkblue text-bold">{element.name}</h6>
                <span className="text-secondary">{element.profession}</span>
              </div>
            ))}
          {/* ---------------------- */}
          {/* ---------------------- */}
          {bestClientReviews.length === 0 && (
            <h3 className="text-darkblue text-center">{english ? "No reviews found" : "لا يوجد تعليقات"}</h3>
          )}
          {/* ---------------------- */}
          {/* ---------------------- */}
        </div>
        {/* -------------------------------------- */}
        {/* -------------------------------------- */}
      </div>
    </div>
  );
};

export default ClientReviews;
