import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import TopImage from "./SubComponents/topImage/topImage";
import TabsAndList from "./SubComponents/TabsAndList/TabsAndList";
import ViewDetailsModal from "./SubComponents/viewDetailsModal/viewDetailsModal";
import bookingPageImage from "../../assets/bookingPage/slide1.png";
import favouritePageImage from "../../assets/favouritePage/slide1.jpg";
import { deleteFavourite, getFavourite } from "../../actions/favourite";
import { addToBooking, deleteBooking, getBooking } from "../../actions/booking";
import * as bootstrap from "bootstrap";
import Loader from "../Loader/loader";
//---------------------------------------------------------------------------------------------------//
const FavouriteAndBookingPages = () => {
  const dispatch = useDispatch();
  //---------------------------------------------------------------------------------------------//
  const user = useSelector((state) => state.user.user);
  //---------------------------------------------------------------------------------------------//
  const loading = useSelector((state) => state.generalPurposeLoader.loading);
  //---------------------------------------------------------------------------------------------//
  const english = useSelector((state) => state.english.active);
  //---------------------------------------------------------------------------------------------//
  const [propertyIndex, setPropertyIndex] = useState("");
  //---------------------------------------------------------------------------------------------//
  const location = useLocation();
  //----------------------------------------------------------------------------------------------//
  const [favourite, set_favourite] = useState(1);
  //----------------------------------------------------------------------------------------------//
  let residentialList = [];
  //----------------------------------------------------------------------------------------------//
  let investmentList = [];
  //----------------------------------------------------------------------------------------------//
  const imageFirstLine = location.pathname.includes("/booking")
    ? english
      ? "Check The Below List For Your Booked Properties,"
      : "راجع قائمة الحجز بالأسفل"
    : english
    ? "Check The Below List For Your Favourite Properties,"
    : "راجع القائمة المفضلة بالأسفل";
  const imageSecondLine = english ? "Restaurants, Villas, Clinics, Etc ..." : "...مطاعم، فيلات، عيادات، إِلخ";
  //----------------------------------------------------------------------------------------------//
  function viewPropertyDetails(index) {
    setPropertyIndex(index);
    const myModal = new bootstrap.Modal(document.getElementById("view-details-modal"));
    myModal.show();
  }
  //----------------------------------------------------------------------------------------------//
  //handle heart icon click in favourite page
  async function removeFromFavouriteList(id) {
    dispatch(deleteFavourite(user, id));
  }
  //----------------------------------------------------------------------------------------------//
  //handle (remove) button in booking page
  function removeFromBookingList(id) {
    dispatch(deleteBooking(user, id));
  }
  //----------------------------------------------------------------------------------------------//
  //handle (Book now) button in favourite page OR (Confirm button) in booking page
  function handleBooking(id, index, type, category, propertyFloor) {
    if (location.pathname.includes("/favourite")) {
      dispatch(addToBooking(user, { id }));
    } else if (location.pathname.includes("/booking")) {
    }
  }
  //----------------------------------------------------------------------------------------------//
  async function loadPage() {
    if (location.pathname.includes("/favourite")) {
      dispatch(getFavourite(user));
    } else if (location.pathname.includes("/booking")) {
      dispatch(getBooking(user));
    }
  }
  //----------------------------------------------------------------------------------------------//
  useEffect(() => {
    if (!user.userLoader) {
      if (user.id && user.token) {
        loadPage();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, user.id, user.token]);
  //----------------------------------------------------------------------------------------------//
  if (location.pathname.includes("/booking")) {
    // eslint-disable-next-line array-callback-return
    user?.booking?.map((unit) => {
      if (unit.type === "residential") {
        residentialList.push(unit);
      } else {
        investmentList.push(unit);
      }
    });
  } else if (location.pathname.includes("/favourite")) {
    // eslint-disable-next-line array-callback-return
    user?.favourite?.map((unit) => {
      if (unit.type === "residential") {
        residentialList.push(unit);
      } else {
        investmentList.push(unit);
      }
    });
  }
  //----------------------------------------------------------------------------------------------//
  return (
    <Fragment>
      {!loading && !user.userLoader && (
        <>
          <TopImage
            image={location.pathname.includes("/booking") ? bookingPageImage : favouritePageImage}
            imageHeader={
              location.pathname.includes("/booking")
                ? english
                  ? "Your Booked List"
                  : "قائمة الحجز"
                : english
                ? "Your Favourite List"
                : "القائمة المفضلة"
            }
            imageFirstLine={imageFirstLine}
            imageSecondLine={imageSecondLine}
          />
          <TabsAndList
            renderedList={favourite === 1 ? residentialList : investmentList}
            tabsMiddleTitle={
              location.pathname.includes("/booking")
                ? english
                  ? "Your Booked List"
                  : "قائمة الحجز"
                : english
                ? "Your Favourite List"
                : "القائمة المفضلة"
            }
            favourite={favourite}
            set_favourite={set_favourite}
            bookingPage={location.pathname.includes("/booking") ? "true" : "false"}
            removeFromFavouriteList={removeFromFavouriteList}
            removeFromBookingList={removeFromBookingList}
            handleBooking={handleBooking}
            viewPropertyDetails={viewPropertyDetails}
          />
          <ViewDetailsModal
            property={favourite === 1 ? residentialList[propertyIndex] : investmentList[propertyIndex]} //returns undefined if not found
          />
        </>
      )}
      {(loading || user.userLoader) && <Loader />}
    </Fragment>
  );
};
export default FavouriteAndBookingPages;
