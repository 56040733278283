import { toast } from "react-toastify";
import axios from "../axios.js";
//------------------------------//
//------------------------------//
const translations = {
  "password: This field may not be blank.": "كلمة المرور فارغة",
  "password: Ensure this field has at least 4 characters.": "كلمة المرور أقل من ٤ أحرف",
  "email: This field may not be blank.": "البريد الإلكتروني فارغ",
  "email: Enter a valid email address.": "البريد الإلكتروني غير صحيح",
  "email: User with this email already exists.": "المستخدم موجود بالفعل",
  "password: This password is too short. It must contain at least 8 characters.": "كلمة المرور أقل من ٨ أحرف",
  "password: This password is entirely numeric.": "لا بد أن تحتوي كلمة المرور على أحرف أو رموز",
  "password: This password is too common.":"كلمة المرور ضعيفة",
};
//------------------------------//
export function register(name, email, password, navigate, english) {
  return async (dispatch) => {
    dispatch({
      type: "SET_LOADING",
      payload: true,
    });
    try {
      const formData = new FormData();
      formData.append("name", name);
      formData.append("email", email);
      formData.append("password", password);
      await axios.post(`/users/create/`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      toast.success("User created successfully");
      navigate("/signin");
      // dispatch({
      //   type: "SET_USER_REGISTER",
      //   payload: response.data,
      // });
    } catch (error) {
      console.log(error);
      if (english) {
        toast.error(error?.response?.data["message"] || "failed to register");
      } else {
        toast.error(translations[error?.response?.data["message"]] || "خطأ فى إنشاء حساب");
      }
    } finally {
      dispatch({
        type: "SET_LOADING",
        payload: false,
      });
    }
  };
}
