import axios from "../axios.js";
//------------------------------//
export function home() {
  return async (dispatch) => {
    try {
      const response = await axios.get(`/home/`);
      dispatch({
        type: "UPDATE_HOME",
        payload: response.data,
      });
    } catch (error) {
      console.log(error);
    }
  };
}
