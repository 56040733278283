import React from "react";
import { useSelector } from "react-redux";
import companyProfileBackgroundImage from "../../../../assets/companyProfile/background.jpg";
import RatingStars from "../RatingStars/ratingStars";
import "./topImage.css";
//---------------------------------------------------------------------------------------------//
const CompanyProfileTopImage = ({ logo, companyName, rating, projectNumbers }) => {
  //------------------------------------------------------------------------------------------------//
  const english = useSelector((state) => state.english.active);
  //------------------------------------------------------------------------------------------------//
  return (
    <div className="company-profile-top-image-wrapper">
      <div className="company-profile-top-image-inner">
        <div className="company-profile-top-image-slide">
          <img src={companyProfileBackgroundImage} className="company-profile-top-image" alt="..."></img>
          <div className="company-profile-top-image-container text-light text-center">
            <div className={english ? "company-profile-top-image-subcontainer" : "company-profile-top-image-subcontainer-arabic"}>
              <img src={logo} className="company-profile-logo" alt=""></img>
              <div className={english ? "company-profile-data" : "company-profile-data-arabic"}>
                <div className="company-profile-name">{companyName}</div>
                <div className="company-profile-rating">
                  <RatingStars rating={rating} />
                </div>
                <p className="company-profile-projects">
                  {english && <i className="fa fa-building-o me-2"></i>}
                  {english ? <>{projectNumbers} Project</> : <>{projectNumbers?.toLocaleString("ar-u-nu-arab")} مشروع</>}
                  {!english && <i className="fa fa-building-o ms-2"></i>}
                </p>
              </div>
              <div className="social-icons">
                <i className="fa fa-facebook-official fa-2x text-light me-2"></i>
                <i className="fa fa-instagram fa-2x text-light me-2"></i>
                <i className="fa fa-linkedin-square fa-2x text-light"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyProfileTopImage;
