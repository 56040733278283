import { Link, useNavigate } from "react-router-dom";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import "./availableCompanies.css";
//-------------------------------------------------------------------------//
const AvailableCompanies = ({ text, availableCompanies }) => {
  //------------------------------------------------------------------------//
  const navigate = useNavigate();
  //------------------------------------------------------------------------//
  const english = useSelector((state) => state.english.active);
  //------------------------------------------------------------------------//
  const [start, set_start] = useState(0);
  //------------------------------------------------------------------------//
  //------------------------------------------------------------------------//
  return (
    <div className="custom-available-companies-wrapper">
      {/* ------------------------------------------- */}
      {/* ------------------------------------------- */}
      <div className="custom-available-companies-inner">
        <div className={english ? "available-companies-carousel-slide" : "available-companies-carousel-slide-arabic"}>
          {/* ------------------- */}
          {/* ------------------- */}
          <h1 className="text-darkblue text-bold mb-4 mt-5">{text}</h1>
          {/* ------------------- */}
          {/* ------------------- */}
          {availableCompanies.length > 0 && (
            <div className="four-companies-wrapper">
              <i
                className="fa fa-chevron-left fa-2x"
                role={"button"}
                onClick={() => {
                  if (start > 1) set_start(start - 4);
                }}
              ></i>
              <div className="four-companies">
                {availableCompanies.slice(start, start + 4).map((element, index) => (
                  <div
                    key={element.id}
                    role="button"
                    className={index === 0 ? "custom-first-company" : "custom-company"}
                    onClick={() => {
                      navigate(`/company/profile/${element.id}`);
                    }}
                  >
                    <img src={element.logo} alt="..."></img>
                    <span className="text-darkblue fw-bolder fs-5 mt-2 text-center">{english ? element.name : element.nameArabic}</span>
                    {english ? (
                      <div className="text-secondary mb-2">
                        <i className="fa fa-building-o me-2"></i>
                        <span>{element.projectNumbers} Projects</span>
                      </div>
                    ) : (
                      <div className="text-secondary mb-2">
                        <span>{element.projectNumbers?.toLocaleString("ar-u-nu-arab")} مشروع</span>
                        <i className="fa fa-building-o ms-2"></i>
                      </div>
                    )}
                    <Link to={`/company/profile/${element.id}`} className="btn btn-secondary view-profile-button">
                      {english ? "View Profile" : "تفاصيل"}
                    </Link>
                  </div>
                ))}
              </div>
              <i
                className="fa fa-chevron-right fa-2x"
                role={"button"}
                onClick={() => {
                  if (start < availableCompanies.length - 4 && start <= 4) set_start(start + 4);
                }}
              ></i>
            </div>
          )}
          {/* ------------------- */}
          {/* ------------------- */}
          {availableCompanies.length === 0 && <h3 className="text-darkblue text-center">{english ? "No Companies found" : "لا توجد شركات"}</h3>}
          {/* ------------------- */}
          {/* ------------------- */}
        </div>
      </div>
      {/* ------------------------------------------- */}
      {/* ------------------------------------------- */}
      {availableCompanies.length > 0 && (
        <div className="text-center mt-1">
          {availableCompanies.length > 4 && (
            <i
              className={start === 0 ? "fa fa-circle me-1 text-primary fa-lg" : "fa fa-circle me-1 text-secondary"}
              onClick={() => set_start(0)}
              aria-hidden="true"
              role="button"
            ></i>
          )}
          {availableCompanies.length >= 5 && (
            <i
              className={start === 4 ? "fa fa-circle me-1 text-primary fa-lg" : "fa fa-circle me-1 text-secondary"}
              onClick={() => set_start(4)}
              aria-hidden="true"
              role="button"
            ></i>
          )}
          {availableCompanies.length > 8 && (
            <i
              className={start === 8 ? "fa fa-circle text-primary fa-lg" : "fa fa-circle text-secondary"}
              onClick={() => set_start(8)}
              aria-hidden="true"
              role="button"
            ></i>
          )}
        </div>
      )}
      {/* ------------------------------------------- */}
      {/* ------------------------------------------- */}
    </div>
  );
};

export default AvailableCompanies;
