import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import CardItem from "../../../CardItem/cardItem";
import "./TabsAndList.css";
//------------------------------------------------------------------------------------------//
const TabsAndList = ({
  renderedList,
  tabsMiddleTitle,
  favourite,
  set_favourite,
  bookingPage,
  viewPropertyDetails,
  removeFromFavouriteList,
  removeFromBookingList,
  handleBooking,
}) => {
  //------------------------------------------------------------------------------------------//
  const english = useSelector((state) => state.english.active);
  //------------------------------------------------------------------------------------------//
  return (
    <Fragment>
      <div className="custom-tabs-and-list-wrapper mt-4">
        <div className="custom-favourite-booking-tabs-wrapper mb-4">
          <div>
            <span
              role="button"
              className={
                favourite === 1
                  ? "mt-3 custom-tab-active"
                  : "mt-3 custom-tab-normal text-darkblue"
              }
              onClick={() => set_favourite(1)}
            >
              {english ? "Residential Properties" : "وحدات سكنية"}
            </span>
          </div>
          <div className="tabs-middle-title">
            <span className="text-darkblue mb-4">{tabsMiddleTitle}</span>
          </div>
          <div>
            <span
              role="button"
              className={
                favourite === 2
                  ? "mt-3 custom-tab-active"
                  : "mt-3 custom-tab-normal text-darkblue"
              }
              onClick={() => set_favourite(2)}
            >
              {english ? "Investment Properties" : "وحدات استثمارية"}
            </span>
          </div>
        </div>
        <div className="custom-list-wrapper">
          {renderedList.map((element, index) => (
            <CardItem
              index={index} //use for view-details-modal
              key={element.id}
              id={element.id}
              name={element.name}
              nameArabic={element.nameArabic}
              projectName={element.projectName}
              projectType={element.projectType}
              projectId={element.projectId}
              unitImage={element.unitImage}
              projectImage={element.projectImage}
              blockNumber={element.blockNumber}
              propertyCAD={element.propertyCAD}
              buildingFloors={element.buildingFloors}
              propertyFloor={element.propertyFloor}
              tags={element.tags}
              address={element.address}
              addressArabic={element.addressArabic}
              distance={element.distance}
              area={element.area}
              pricePerMeter={element.pricePerMeter}
              totalPrice={element.totalPrice}
              implementingCompanyLogo={element.implementingCompanyLogo}
              type={element.type}
              category={element.category}
              status={element.status}
              buttonText={
                bookingPage === "true"
                  ? english
                    ? "Confirm"
                    : "تأكيد"
                  : english
                  ? "Book Now"
                  : "أحجز الآن"
              }
              viewDetails="true"
              bookingPage={bookingPage}
              viewPropertyDetails={viewPropertyDetails}
              removeFromFavouriteList={removeFromFavouriteList}
              removeFromBookingList={removeFromBookingList}
              handleBooking={handleBooking}
            />
          ))}
        </div>
      </div>
    </Fragment>
  );
};

export default TabsAndList;
