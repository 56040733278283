import React, { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import SinglePropertyDetails from "../../singlePropertyDetails/singlePropertyDetails";
import "./compoundLayoutView.css";
//---------------------------------------------------------------------------------------------//
const CompoundLayoutView = ({ compoundData, setSelectedUnit, selectedUnit }) => {
  const [building, setBuilding] = useState({});
  //------------------------------------------------------------------------------------------------//
  const english = useSelector((state) => state.english.active);
  //------------------------------------------------------------------------------------------------//
  const location = useLocation();
  //------------------------------------------------------------------------------------------------//
  function handleBuildingTypeSelection(e) {
    if (e.currentTarget.value.includes("block") || e.currentTarget.value.includes("studio") || e.currentTarget.value.includes("hotel")) {
      const arr = e.currentTarget.value.split("/");
      setBuilding({
        type: arr[0],
        blockIndex: parseInt(arr[1]),
      });
    } else if (
      e.currentTarget.value.includes("duplex villa") ||
      e.currentTarget.value.includes("detached villa") ||
      e.currentTarget.value.includes("twin house")
    ) {
      const arr = e.currentTarget.value.split("/");
      setBuilding({
        type: arr[0],
        villaIndex: parseInt(arr[1]),
      });
    }
    setSelectedUnit({});
    document.getElementsByClassName("building-floor-selection")[0].value = english ? "Select floor" : "اختر طابق";
  }
  //------------------------------------------------------------------------------------------------//
  function handleFloorSelection(e) {
    if (building.type.includes("block") || building.type.includes("studio") || building.type.includes("hotel")) {
      setBuilding({
        ...building,
        floorIndex: e.currentTarget.value,
      });
      setSelectedUnit({});
      document.getElementsByClassName("block-unit-selection")[0].value = english ? "Select unit" : "اختر وحدة";
    } else if (building.type.includes("duplex")) {
      //------------------------------//
      //------------------------------//
      //------------------------------//
      if (e.currentTarget.value === "all") {
        setSelectedUnit({
          ...compoundData.duplexVillas[building.villaIndex],
          propertyFloor: 0,
          // attributes added from compound object to construct villa as residential property
          projectName: compoundData.name,
          projectNameArabic: compoundData.nameArabic,
          projectType: compoundData.type,
          projectId: compoundData.id,
          projectImage: compoundData.projectImage,
          address: compoundData.address,
          addressArabic: compoundData.addressArabic,
          distance: compoundData.distance,
        });
      }
      //------------------------------//
      //------------------------------//
      //------------------------------//
      else if (e.currentTarget.value >= 0) {
        const singleFloor = compoundData.duplexVillas[building.villaIndex].propertyCAD[e.currentTarget.value];
        setSelectedUnit({
          ...compoundData.duplexVillas[building.villaIndex],
          //8 attributes to be overwritten by selecting a floor in a villa
          id: singleFloor.id,
          unitImage: singleFloor.unitImage,
          area: singleFloor.area,
          pricePerMeter: singleFloor.pricePerMeter,
          totalPrice: singleFloor.totalPrice,
          plans: [...singleFloor.plans],
          allFloorsAvailable: undefined,
          propertyCAD: [
            {
              CAD: singleFloor.CAD,
            },
          ],
          propertyFloor: singleFloor.floor,
          // attributes added from compound object to construct single floor in a villa as residential property
          projectName: compoundData.name,
          projectNameArabic: compoundData.nameArabic,
          projectType: compoundData.type,
          projectId: compoundData.id,
          projectImage: compoundData.projectImage,
          address: compoundData.address,
          addressArabic: compoundData.addressArabic,
          distance: compoundData.distance,
        });
      }
    } else if (building.type.includes("detached")) {
      //------------------------------//
      //------------------------------//
      //------------------------------//
      if (e.currentTarget.value === "all") {
        setSelectedUnit({
          ...compoundData.detachedVillas[building.villaIndex],
          propertyFloor: 0,
          // attributes added from compound object to construct villa as residential property
          projectName: compoundData.name,
          projectNameArabic: compoundData.nameArabic,
          projectType: compoundData.type,
          projectId: compoundData.id,
          projectImage: compoundData.projectImage,
          address: compoundData.address,
          addressArabic: compoundData.addressArabic,
          distance: compoundData.distance,
        });
      }
      //------------------------------//
      //------------------------------//
      //------------------------------//
      else if (e.currentTarget.value >= 0) {
        const singleFloor = compoundData.detachedVillas[building.villaIndex].propertyCAD[e.currentTarget.value];
        setSelectedUnit({
          ...compoundData.detachedVillas[building.villaIndex],
          propertyFloor: singleFloor.floor,
          //8 attributes to be overwritten by selecting a floor in a villa
          id: singleFloor.id,
          unitImage: singleFloor.unitImage,
          area: singleFloor.area,
          pricePerMeter: singleFloor.pricePerMeter,
          totalPrice: singleFloor.totalPrice,
          plans: [...singleFloor.plans],
          allFloorsAvailable: undefined,
          propertyCAD: [
            {
              ...singleFloor,
            },
          ],
          // attributes added from compound object to construct single floor in a villa as residential property
          projectName: compoundData.name,
          projectNameArabic: compoundData.nameArabic,
          projectType: compoundData.type,
          projectId: compoundData.id,
          projectImage: compoundData.projectImage,
          address: compoundData.address,
          addressArabic: compoundData.addressArabic,
          distance: compoundData.distance,
        });
      }
    } else if (building.type.includes("twin")) {
      //------------------------------//
      //------------------------------//
      //------------------------------//
      if (e.currentTarget.value === "all") {
        setSelectedUnit({
          ...compoundData.twinHouses[building.villaIndex],
          propertyFloor: 0,
          // attributes added from compound object to construct villa as residential property
          projectName: compoundData.name,
          projectNameArabic: compoundData.nameArabic,
          projectType: compoundData.type,
          projectId: compoundData.id,
          projectImage: compoundData.projectImage,
          address: compoundData.address,
          addressArabic: compoundData.addressArabic,
          distance: compoundData.distance,
        });
      }
      //------------------------------//
      //------------------------------//
      //------------------------------//
      else if (e.currentTarget.value >= 0) {
        const singleFloor = compoundData.twinHouses[building.villaIndex].propertyCAD[e.currentTarget.value];
        setSelectedUnit({
          ...compoundData.twinHouses[building.villaIndex],
          propertyFloor: singleFloor.floor,
          //8 attributes to be overwritten by selecting a floor in a villa
          id: singleFloor.id,
          unitImage: singleFloor.unitImage,
          area: singleFloor.area,
          pricePerMeter: singleFloor.pricePerMeter,
          totalPrice: singleFloor.totalPrice,
          plans: [...singleFloor.plans],
          allFloorsAvailable: undefined,
          propertyCAD: [
            {
              ...singleFloor,
            },
          ],
          // attributes added from compound object to construct single floor in a villa as residential property
          projectName: compoundData.name,
          projectNameArabic: compoundData.nameArabic,
          projectType: compoundData.type,
          projectId: compoundData.id,
          projectImage: compoundData.projectImage,
          address: compoundData.address,
          addressArabic: compoundData.addressArabic,
          distance: compoundData.distance,
        });
      }
    }
  }
  //------------------------------------------------------------------------------------------------//
  function handleUnitSelectInFloor(e) {
    if (building.type === "block") {
      const selectedFloor = compoundData.blocks[building.blockIndex].floorsFeatures[building.floorIndex];
      setSelectedUnit({
        ...selectedFloor.units[e.currentTarget.value],
        propertyFloor: selectedFloor.number,
        //attributes to be added from compound object to construct apartment as a residential property
        projectName: compoundData.name,
        projectNameArabic: compoundData.nameArabic,
        projectType: compoundData.type,
        projectId: compoundData.id,
        projectImage: compoundData.projectImage,
        address: compoundData.address,
        addressArabic: compoundData.addressArabic,
        distance: compoundData.distance,
        blockNumber: compoundData.blocks[building.blockIndex].blockNumber,
        blockNumberArabic: compoundData.blocks[building.blockIndex].blockNumberArabic,
        buildingFloors: compoundData.blocks[building.blockIndex].buildingFloors,
      });
    } else if (building.type === "studio") {
      const selectedFloor = compoundData.studios[building.blockIndex].floorsFeatures[building.floorIndex];
      setSelectedUnit({
        ...selectedFloor.units[e.currentTarget.value],
        propertyFloor: selectedFloor.number,
        //attributes to be added from compound object to construct apartment as a residential property
        projectName: compoundData.name,
        projectNameArabic: compoundData.nameArabic,
        projectType: compoundData.type,
        projectId: compoundData.id,
        projectImage: compoundData.projectImage,
        address: compoundData.address,
        addressArabic: compoundData.addressArabic,
        distance: compoundData.distance,
        blockNumber: compoundData.studios[building.blockIndex].blockNumber,
        blockNumberArabic: compoundData.studios[building.blockIndex].blockNumberArabic,
        buildingFloors: compoundData.studios[building.blockIndex].buildingFloors,
      });
    } else if (building.type === "hotel unit") {
      const selectedFloor = compoundData.hotelUnits[building.blockIndex].floorsFeatures[building.floorIndex];
      setSelectedUnit({
        ...selectedFloor.units[e.currentTarget.value],
        propertyFloor: selectedFloor.number,
        //attributes to be added from compound object to construct apartment as a residential property
        projectName: compoundData.name,
        projectNameArabic: compoundData.nameArabic,
        projectType: compoundData.type,
        projectId: compoundData.id,
        projectImage: compoundData.projectImage,
        address: compoundData.address,
        addressArabic: compoundData.addressArabic,
        distance: compoundData.distance,
        blockNumber: compoundData.hotelUnits[building.blockIndex].blockNumber,
        blockNumberArabic: compoundData.hotelUnits[building.blockIndex].blockNumberArabic,
        buildingFloors: compoundData.hotelUnits[building.blockIndex].buildingFloors,
      });
    }
  }
  //------------------------------------------------------------------------------------------------//
  //------------------------------------------------------------------------------------------------//
  //------------------------------------------------------------------------------------------------//
  return (
    <Fragment>
      {/* -------------------------------------------------------------------------------- */}
      {/* -------------------------------Compound info section---------------------------- */}
      {/* -------------------------------------------------------------------------------- */}
      <div className="compound-info-section-wrapper mt-5">
        <div className="compound-info-section-inner">
          {/* ------------------------------------------------------------ */}
          {/* -----------------------Compound 2D CAD---------------------- */}
          {/* ------------------------------------------------------------ */}
          <div className="compound-info-left">
            <img src={compoundData.layout} alt=""></img>
          </div>
          {/* ------------------------------------------------------------ */}
          {/* ----------------------No unit selected---------------------- */}
          {/* ------------------------------------------------------------ */}
          {!selectedUnit.id && (
            <div className="compound-info-right">
              {/* --------------------- */}
              {/* --------------------- */}
              <div className={english ? "compound-info-right-first-section" : "compound-info-right-first-section-arabic"}>
                <div>
                  <h4 className="text-darkblue">{english ? "Compound Name" : "اسم الكمبوند"}</h4>
                  <h3 className="text-bold text-darkblue">{english ? compoundData.name : compoundData.nameArabic}</h3>
                </div>
                <Link to={`/compound/info/${compoundData.id}`} className="btn btn-link">
                  <i className="fa fa-search me-2"></i>
                  {english ? "View Compound Details" : "اعرض تفاصيل عن الكمبوند"}
                </Link>
              </div>
              {/* --------------------- */}
              {/* --------------------- */}
              {compoundData.blocks.length > 0 && (
                <div className={english ? "compound-info-right-second-section" : "compound-info-right-second-section-arabic"}>
                  <p className="text-secondary">
                    <i className="fa fa-building text-primary me-2"></i>
                    {english ? "Blocks number " : "عدد العمارات السكنية"}
                  </p>
                  <p className="text-bold text-dark">
                    {english ? compoundData.blocks.length : compoundData.blocks.length?.toLocaleString("ar-u-nu-arab")}
                  </p>
                </div>
              )}
              {/* --------------------- */}
              {/* --------------------- */}
              {compoundData.duplexVillas.length > 0 && (
                <div className={english ? "compound-info-right-third-section" : "compound-info-right-third-section-arabic"}>
                  <p className="text-secondary">
                    <i className="fa fa-pie-chart text-primary me-2"></i>
                    {english ? "Duplex villas number" : "عدد الفيلات الدوبلكس"}
                  </p>
                  <p className="text-bold text-dark">
                    {english ? compoundData.duplexVillas.length : compoundData.duplexVillas.length?.toLocaleString("ar-u-nu-arab")}
                  </p>
                </div>
              )}
              {/* --------------------- */}
              {/* --------------------- */}
              {compoundData.detachedVillas.length > 0 && (
                <div className={english ? "compound-info-right-third-section" : "compound-info-right-third-section-arabic"}>
                  <p className="text-secondary">
                    <i className="fa fa-pie-chart text-primary me-2"></i>
                    {english ? "Detached villas number" : "عدد الفيلات المنفصلة"}
                  </p>
                  <p className="text-bold text-dark">
                    {english ? compoundData.detachedVillas.length : compoundData.detachedVillas.length?.toLocaleString("ar-u-nu-arab")}
                  </p>
                </div>
              )}
              {/* --------------------- */}
              {/* --------------------- */}
              {compoundData.twinHouses.length > 0 && (
                <div className={english ? "compound-info-right-third-section" : "compound-info-right-third-section-arabic"}>
                  <p className="text-secondary">
                    <i className="fa fa-pie-chart text-primary me-2"></i>
                    {english ? "Twin houses number" : "عدد التوين هاوس"}
                  </p>
                  <p className="text-bold text-dark">
                    {english ? compoundData.twinHouses.length : compoundData.twinHouses.length?.toLocaleString("ar-u-nu-arab")}
                  </p>
                </div>
              )}
              {/* --------------------- */}
              {/* --------------------- */}
              {compoundData.studios.length > 0 && (
                <div className={english ? "compound-info-right-third-section" : "compound-info-right-third-section-arabic"}>
                  <p className="text-secondary">
                    <i className="fa fa-pie-chart text-primary me-2"></i>
                    {english ? "Studios number" : "عدد الاستوديوهات"}
                  </p>
                  <p className="text-bold text-dark">
                    {english ? compoundData.studios.length : compoundData.studios.length?.toLocaleString("ar-u-nu-arab")}
                  </p>
                </div>
              )}
              {/* --------------------- */}
              {/* --------------------- */}
              {compoundData.hotelUnits.length > 0 && (
                <div className={english ? "compound-info-right-third-section" : "compound-info-right-third-section-arabic"}>
                  <p className="text-secondary">
                    <i className="fa fa-pie-chart text-primary me-2"></i>
                    {english ? "hotel units number" : "عدد الغرف الفندقية"}
                  </p>
                  <p className="text-bold text-dark">
                    {english ? compoundData.hotelUnits.length : compoundData.hotelUnits.length?.toLocaleString("ar-u-nu-arab")}
                  </p>
                </div>
              )}
              {/* --------------------- */}
              {/* --------------------- */}
              <div className={english ? "compound-info-right-fourth-section" : "compound-info-right-fourth-section-arabic"}>
                <p className="text-secondary">
                  <i className="fa fa-star-o me-2 text-primary"></i>
                  {english ? "Compound Features" : "مزايا  الكمبوند"}
                </p>
                {english ? (
                  <p className="text-center">
                    {compoundData.features.map((elem, index) => (
                      <span key={index} className="text-dark text-bold">
                        {elem}
                        {index === compoundData.features.length - 1 ? "." : ","}
                      </span>
                    ))}
                  </p>
                ) : (
                  <p className="text-center">
                    {compoundData.featuresArabic.map((elem, index) => (
                      <span key={index} className="text-dark text-bold">
                        {elem}
                        {index === compoundData.featuresArabic.length - 1 ? "" : ", "}
                      </span>
                    ))}
                  </p>
                )}

                <p className="text-danger text-center text-bold clickable">
                  {english ? (
                    <>
                      <span>For any inquiries</span>
                      <a href="tel:+201017762142" className="btn btn-link btn-sm">
                        Call Us<i className="fa fa-phone ms-1"></i>
                      </a>
                    </>
                  ) : (
                    <>
                      <span>محتار و محتاج استشارة مجانيةّ, رن علينا و هنتصل بيك</span>
                      <p>
                        <a href="tel:+201017762142" className="btn btn-link btn-sm">
                          <i className="fa fa-phone me-1"></i>
                          اتصل بنا
                        </a>
                      </p>
                    </>
                  )}
                </p>
              </div>
              {/* --------------------- */}
              {/* --------------------- */}
            </div>
          )}
          {/* ------------------------------------------------------------ */}
          {/* -----------------------Unit selected------------------------ */}
          {/* ------------------------------------------------------------ */}
          {selectedUnit.id && <SinglePropertyDetails property={selectedUnit} />}
        </div>
      </div>
      {/* -------------------------------------------------------------------------------- */}
      {/* ----------------------------------Selecting unit-------------------------------- */}
      {/* -------------------------------------------------------------------------------- */}
      <hr></hr>
      <div className="compound-info-search-filter mt-5">
        {/* --------------------------------------------------------------- */}
        {/* ------------------select building type--------------------------*/}
        {/* ----------------------------------------------------------------*/}
        <>
          <h4 className="text-darkblue">{english ? "Select building" : "اختر المبنى"}</h4>
          <div>
            <select
              className="form-select"
              id={english ? "custom-form-select" : "custom-form-select-arabic"}
              defaultValue={english ? "Select building" : "اختر الوحدة"}
              onChange={(e) => {
                handleBuildingTypeSelection(e);
              }}
            >
              <option disabled>{english ? "Select building" : "اختر الوحدة"}</option>
              {location.pathname.includes("/compound/apartments/") &&
                compoundData.blocks.map((block, index) => (
                  <option value={"block/" + index?.toLocaleString()}>{english ? block.blockNumber : block.blockNumberArabic}</option>
                ))}
              {location.pathname.includes("/compound/duplex") &&
                compoundData.duplexVillas.map((villa, index) => (
                  <option value={"duplex villa/" + index?.toLocaleString()}>{english ? villa.name : villa.nameArabic}</option>
                ))}
              {location.pathname.includes("/compound/detached") &&
                compoundData.detachedVillas.map((villa, index) => (
                  <option value={"detached villa/" + index?.toLocaleString()}>{english ? villa.name : villa.nameArabic}</option>
                ))}
              {location.pathname.includes("/compound/twin") &&
                compoundData.twinHouses.map((villa, index) => (
                  <option value={"twin house/" + index?.toLocaleString()}>{english ? villa.name : villa.nameArabic}</option>
                ))}
              {location.pathname.includes("/compound/studio") &&
                compoundData.studios.map((studio, index) => (
                  <option value={"studio/" + index?.toLocaleString()}>{english ? studio.blockNumber : studio.blockNumberArabic}</option>
                ))}
              {location.pathname.includes("/compound/hotel") &&
                compoundData.hotelUnits.map((hotelUnit, index) => (
                  <option value={"hotel unit/" + index?.toLocaleString()}>{english ? hotelUnit.blockNumber : hotelUnit.blockNumberArabic}</option>
                ))}
            </select>
          </div>
        </>
        {/* ----------------------------------------------------------------*/}
        {/* ----------------------Floor number------------------------------*/}
        {/* ----------------------------------------------------------------*/}
        {building.type && (
          <>
            <h4 className="text-darkblue">{english ? "Select floor" : "اختر الطابق"}</h4>
            <div>
              <select
                className="form-select building-floor-selection"
                id={english ? "custom-form-select" : "custom-form-select-arabic"}
                defaultValue={english ? "Select floor" : "اختر طابق"}
                onChange={(e) => handleFloorSelection(e)}
              >
                <option disabled>{english ? "Select floor" : "اختر طابق"}</option>
                {/* ---------------For block-------------- */}
                {building.type === "block" && (
                  <>
                    {compoundData.blocks[building.blockIndex].floorsFeatures.map((floor, index) => (
                      <option value={index}>{english ? floor.number : floor.number?.toLocaleString("ar-u-nu-arab")}</option>
                    ))}
                  </>
                )}
                {/* ----------For duplex villa------------ */}
                {building.type === "duplex villa" && (
                  <>
                    {compoundData.duplexVillas[building.villaIndex].allFloorsAvailable === true && (
                      <option className="text-dark" value={"all"}>
                        {english ? "All" : "الكل"}
                      </option>
                    )}
                    {compoundData.duplexVillas[building.villaIndex].propertyCAD.map((unit, index) => (
                      <option value={index} disabled={unit.status !== "free"} className={unit.status !== "free" ? "text-secondary" : "text-dark"}>
                        {unit.status === "free"
                          ? english
                            ? unit.floor
                            : unit.floor?.toLocaleString("ar-u-nu-arab")
                          : english
                          ? unit.floor + "(Reserved)"
                          : unit.floor?.toLocaleString("ar-u-nu-arab") + " (محجوز)"}
                      </option>
                    ))}
                  </>
                )}
                {/* ---------For detached villa----------- */}
                {building.type === "detached villa" && (
                  <>
                    {compoundData.detachedVillas[building.villaIndex].allFloorsAvailable === true && (
                      <option className="text-dark" value={"all"}>
                        {english ? "All" : "الكل"}
                      </option>
                    )}
                    {compoundData.detachedVillas[building.villaIndex].propertyCAD.map((unit, index) => (
                      <option value={index} disabled={unit.status !== "free"} className={unit.status !== "free" ? "text-secondary" : "text-dark"}>
                        {unit.status === "free"
                          ? english
                            ? unit.floor
                            : unit.floor?.toLocaleString("ar-u-nu-arab")
                          : english
                          ? unit.floor + "(Reserved)"
                          : unit.floor?.toLocaleString("ar-u-nu-arab") + " (محجوز)"}
                      </option>
                    ))}
                  </>
                )}
                {/* -----------For twin house------------- */}
                {building.type === "twin house" && (
                  <>
                    {compoundData.twinHouses[building.villaIndex].allFloorsAvailable === true && (
                      <option className="text-dark" value={"all"}>
                        {english ? "All" : "الكل"}
                      </option>
                    )}
                    {compoundData.twinHouses[building.villaIndex].propertyCAD.map((unit, index) => (
                      <option value={index} disabled={unit.status !== "free"} className={unit.status !== "free" ? "text-secondary" : "text-dark"}>
                        {unit.status === "free"
                          ? english
                            ? unit.floor
                            : unit.floor?.toLocaleString("ar-u-nu-arab")
                          : english
                          ? unit.floor + "(Reserved)"
                          : unit.floor?.toLocaleString("ar-u-nu-arab") + " (محجوز)"}
                      </option>
                    ))}
                  </>
                )}
                {/* --------------For studio-------------- */}
                {building.type === "studio" && (
                  <>
                    {compoundData.studios[building.blockIndex].floorsFeatures.map((floor, index) => (
                      <option value={index}>{english ? floor.number : floor.number?.toLocaleString("ar-u-nu-arab")}</option>
                    ))}
                  </>
                )}
                {/* ------------For hotel unit------------ */}
                {building.type === "hotel unit" && (
                  <>
                    {compoundData.hotelUnits[building.blockIndex].floorsFeatures.map((floor, index) => (
                      <option value={index}>{english ? floor.number : floor.number?.toLocaleString("ar-u-nu-arab")}</option>
                    ))}
                  </>
                )}
              </select>
            </div>
          </>
        )}
        {/* -----------------------------------------------------------------*/}
        {/* -----------selecting unit in floor for blocks only-------------- */}
        {/* ---------------------------------------------------------------- */}
        {building.type === "block" && building.floorIndex && (
          <>
            <h4 className="text-darkblue">{english ? "Select unit" : "اختر وحدة"}</h4>
            <div>
              <select
                className="form-select block-unit-selection"
                id={english ? "custom-form-select" : "custom-form-select-arabic"}
                defaultValue={english ? "Select unit" : "اختر وحدة"}
                onChange={(e) => {
                  handleUnitSelectInFloor(e);
                }}
              >
                <>
                  <option disabled>{english ? "Select unit" : "اختر وحدة"}</option>
                  {compoundData.blocks[building.blockIndex].floorsFeatures[building.floorIndex].units.map((unit, index) => (
                    <option value={index} disabled={unit.status !== "free"} className={unit.status !== "free" ? "text-secondary" : "text-dark"}>
                      {unit.status === "free"
                        ? english
                          ? unit.name
                          : unit.nameArabic
                        : english
                        ? unit.name + " (Reserved)"
                        : unit.nameArabic + " (محجوز)"}
                    </option>
                  ))}
                </>
              </select>
            </div>
          </>
        )}
        {/* ---------------------------------------------------------------- */}
        {/* -----------selecting unit in floor for studio only-------------- */}
        {/* ---------------------------------------------------------------- */}
        {building.type === "studio" && building.floorIndex && (
          <>
            <h4 className="text-darkblue">{english ? "Select unit" : "اختر وحدة"}</h4>
            <div>
              <select
                className="form-select block-unit-selection"
                id={english ? "custom-form-select" : "custom-form-select-arabic"}
                defaultValue={english ? "Select unit" : "اختر وحدة"}
                onChange={(e) => {
                  handleUnitSelectInFloor(e);
                }}
              >
                <>
                  <option disabled>{english ? "Select unit" : "اختر وحدة"}</option>
                  {compoundData.studios[building.blockIndex].floorsFeatures[building.floorIndex].units.map((unit, index) => (
                    <option value={index} disabled={unit.status !== "free"} className={unit.status !== "free" ? "text-secondary" : "text-dark"}>
                      {unit.status === "free"
                        ? english
                          ? unit.name
                          : unit.nameArabic
                        : english
                        ? unit.name + " (Reserved)"
                        : unit.nameArabic + " (محجوز)"}
                    </option>
                  ))}
                </>
              </select>
            </div>
          </>
        )}
        {/* ---------------------------------------------------------------- */}
        {/* -----------selecting unit in floor for hotel units only--------- */}
        {/* ---------------------------------------------------------------- */}
        {building.type === "hotel unit" && building.floorIndex && (
          <>
            <h4 className="text-darkblue">{english ? "Select unit" : "اختر وحدة"}</h4>
            <div>
              <select
                className="form-select block-unit-selection"
                id={english ? "custom-form-select" : "custom-form-select-arabic"}
                defaultValue={english ? "Select unit" : "اختر وحدة"}
                onChange={(e) => {
                  handleUnitSelectInFloor(e);
                }}
              >
                <>
                  <option disabled>{english ? "Select unit" : "اختر وحدة"}</option>
                  {compoundData.hotelUnits[building.blockIndex].floorsFeatures[building.floorIndex].units.map((unit, index) => (
                    <option value={index} disabled={unit.status !== "free"} className={unit.status !== "free" ? "text-secondary" : "text-dark"}>
                      {unit.status === "free"
                        ? english
                          ? unit.name
                          : unit.nameArabic
                        : english
                        ? unit.name + " (Reserved)"
                        : unit.nameArabic + " (محجوز)"}
                    </option>
                  ))}
                </>
              </select>
            </div>
          </>
        )}
      </div>
    </Fragment>
  );
};

export default CompoundLayoutView;
