import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./cardItem.css";
//---------------------------------------------------------------------------------------------//
const CardItem = ({
  id,
  index,
  name,
  nameArabic,
  unitImage,
  projectImage,
  buildingFloors,
  propertyFloor, // 0:whole
  propertiesNumber, // in case of compound
  tags,
  address,
  addressArabic,
  distance,
  type, //(mall/tower/compound)(big buildings),(residential,investment)(small buildings)
  category, // for units (shops,restaurants,villas,...etc)
  buttonText,
  viewDetails,
  viewPropertyDetails,
  bookingPage,
  removeFromFavouriteList,
  removeFromBookingList,
  handleBooking,
}) => {
  //--------------------------------------------------------------------------------------------//
  const english = useSelector((state) => state.english.active);
  const location = useLocation();
  const navigate = useNavigate();
  //--------------------------------------------------------------------------------------------//
  function getTagClassName(tag) {
    if (tag === "Commercial" || tag === "Residential") {
      return "custom-badge-blue text-primary";
    }
    if (tag === "Medical" || tag === "Investment") {
      return "custom-badge-orange text-orange";
    }
    if (tag === "Hotel") {
      return "custom-badge-green text-success";
    }
  }
  //--------------------------------------------------------------------------------------------//
  function getTagsName(tag) {
    if (!english) {
      switch (tag) {
        case "Residential":
          return "سكني";
        case "Medical":
          return "طبي";
        case "Investment":
          return "استثماري";
        case "Hotel":
          return "فندقي";
        case "Commercial":
          return "تجاري";
        default:
          return "";
      }
    } else return tag;
  }
  //--------------------------------------------------------------------------------------------//
  return (
    <div
      className="custom-card-element"
      role={type === "mall" || type === "compound" ? "button" : ""}
      onClick={() => {
        if (type === "mall") {
          navigate(`/mall/info/${id}`);
        } else if (type === "compound") {
          navigate(`/compound/info/${id}`);
        }
      }}
    >
      {/* ------------------------left image------------------------------- */}
      <div className="custom-card-image me-2">
        <img className="trial" src={type === "residential" || type === "investment" ? unitImage : projectImage} alt="..."></img>
      </div>
      {/* -----------------------right card ------------------------------- */}
      <div className={english ? "custom-card-detail" : "custom-card-detail-arabic"}>
        {/* --------------name------------- */}
        <span className="custom-card-name text-darkblue text-bold">{english ? name : nameArabic}</span>
        {/* ------------address------------ */}
        <div className="custom-card-address text-secondary mb-2">
          <i className={english ? "fa fa-map-o me-2" : "fa fa-map-o ms-2"}></i>
          <span>{english ? address : addressArabic}</span>
        </div>
        {/* ---------floors / properties--------- */}
        <div className="custom-card-floors text-secondary mb-2">
          {type === "compound" && (
            <span>
              <i className={english ? "fa fa-building-o me-2" : "fa fa-building-o ms-2"}></i>
              {english && <span>Properties: {propertiesNumber}</span>}
              {!english && <span>عدد الوحدات: {propertiesNumber?.toLocaleString("ar-u-nu-arab")}</span>}
            </span>
          )}
          {type !== "compound" && (
            <Fragment>
              <span>
                <i className={english ? "fa fa-building-o me-2" : "fa fa-building-o ms-2"}></i>
                {english && <span>Building Floors: {buildingFloors}</span>}
                {!english && <span>عدد الطوابق: {buildingFloors?.toLocaleString("ar-u-nu-arab")} </span>}
              </span>
              {propertyFloor !== undefined && propertyFloor !== 0 && (
                <span>
                  <i className={english ? "fa fa-building-o me-2" : "fa fa-building-o ms-2"}></i>
                  {english && <span>Property Floor: {propertyFloor}</span>}
                  {!english && <span>طابق الوحدة: {propertyFloor?.toLocaleString("ar-u-nu-arab")} </span>}
                </span>
              )}
            </Fragment>
          )}
        </div>
        {/* -----tags/view full details link----- */}
        <div className="custom-card-tags mb-2">
          {tags &&
            tags.map((tag, index) => (
              <span key={index} className={getTagClassName(tag)}>
                {getTagsName(tag)}
              </span>
            ))}
          {viewDetails === "true" && (
            <button
              className="btn btn-link btn-sm custom-card-button"
              onClick={() => {
                viewPropertyDetails(index);
              }}
            >
              {english ? "View full details" : "اعرض التفاصيل"}
            </button>
          )}
        </div>
        {/* ----------------heart icon------------------- */}
        {/*--- view details button for mall & compound--- */}
        {/* ------Book now/Confirm/Remove for units------ */}
        <div className="custom-like-details">
          {/* ----------------heart icon------------- */}
          {(type === "residential" || type === "investment") && bookingPage === "false" && (
            <i
              className="fa fa-heart text-danger custom-card-button"
              role="button"
              onClick={() => {
                removeFromFavouriteList(id, index, type, category, propertyFloor);
              }}
            ></i>
          )}
          {/* view details button for mall & compound */}
          {(type === "mall" || type === "compound") && (
            <Link to={type === "mall" ? `/mall/info/${id}` : `/compound/info/${id}`} className="btn btn-secondary btn-sm custom-card-button">
              {buttonText}
            </Link>
          )}
          {/* ---Book now/Confirm/Remove for units--- */}
          {(type === "residential" || type === "investment") && !location.pathname.includes("/search") && (
            <>
              {/* Button text => (Book now / Confirm) */}
              <button
                className="btn btn-secondary btn-sm custom-card-button"
                onClick={() => {
                  handleBooking(id, index, type, category, propertyFloor);
                }}
              >
                {buttonText}
              </button>
              {/* ----------Remove button------------ */}
              {bookingPage === "true" && (
                <button
                  className="btn btn-danger btn-sm custom-card-button"
                  onClick={() => {
                    removeFromBookingList(id, index, type, category, propertyFloor);
                  }}
                >
                  {english ? "Remove" : "حذف"}
                </button>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default CardItem;
