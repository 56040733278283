import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./latestProjects.css";
//---------------------------------------------------------------------------------//
//---------------------------------------------------------------------------------//
const LatestProjects = ({ latestProjects }) => {
  //---------------------------------------------------------------------------------//
  const navigate = useNavigate();
  //----------------------------------------------------------------------------------//
  const [start, set_start] = useState(0);
  //---------------------------------------------------------------------------------//
  const english = useSelector((state) => state.english.active);
  //---------------------------------------------------------------------------------//
  return (
    <div className="custom-latestprojects-wrapper">
      {/* -------------------------------- */}
      {/* -------------------------------- */}
      <div className="custom-latestprojects-inner">
        <div
          className={
            english
              ? "latest-projects-carousel-slide"
              : "latest-projects-carousel-slide-arabic"
          }
        >
          {/* ------------------ */}
          {/* ------------------ */}
          <h2 className="text-darkblue text-bold mb-4 mt-5">
            {english ? "Latest Projects" : "أحدث المشروعات"}
          </h2>
          {/* ------------------ */}
          {/* ------------------ */}
          {latestProjects.length > 0 && (
            <div className="three-latest-projects">
              {latestProjects.slice(start, start + 3).map((element) => (
                <div
                  className={
                    english
                      ? "custom-latest-single-project"
                      : "custom-latest-single-project-arabic"
                  }
                  onClick={() => {
                    navigate(`/${element.type}/info/${element.id}`);
                  }}
                  role="button"
                  key={element.id}
                >
                  <img src={element.backgroundImage} alt="..."></img>
                  <h6 className="text-darkblue text-bold mt-2">
                    {english ? element.name : element.nameArabic}
                  </h6>
                  {english ? (
                    <div className="custom-malls-address text-secondary mb-2">
                      <i className="fa fa-map-o me-2"></i>
                      <span>{element.address}</span>
                    </div>
                  ) : (
                    <div className="custom-malls-address text-secondary mb-2">
                      <span>{element.addressArabic}</span>
                      <i className="fa fa-map-o ms-2"></i>
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}
          {/* ------------------ */}
          {/* ------------------ */}
          {latestProjects.length === 0 && (
            <h3 className="text-darkblue text-center">
              {english ? "No projects found" : "لا توجد مشاريع"}
            </h3>
          )}
        </div>
      </div>
      {/* -------------------------------- */}
      {/* -------------------------------- */}
      {latestProjects.length > 0 && (
        <div className="text-center mt-1">
          <i
            className={
              start === 0
                ? "fa fa-circle me-1 text-primary fa-lg"
                : "fa fa-circle me-1 text-secondary"
            }
            onClick={() => set_start(0)}
            aria-hidden="true"
          ></i>
          <i
            className={
              start === 3
                ? "fa fa-circle me-1 text-primary fa-lg"
                : "fa fa-circle me-1 text-secondary"
            }
            onClick={() => set_start(3)}
            aria-hidden="true"
          ></i>
          <i
            className={
              start === 6
                ? "fa fa-circle text-primary fa-lg"
                : "fa fa-circle text-secondary"
            }
            onClick={() => set_start(6)}
            aria-hidden="true"
          ></i>
        </div>
      )}
    </div>
  );
};

export default LatestProjects;
