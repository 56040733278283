import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getSingleCompany } from "../../actions/getSingleCompany";
import TopImage from "./SubComponents/topImage/topImage";
import AboutSection from "./SubComponents/aboutSection/aboutSection";
import CompanyProjects from "./SubComponents/CompanyProjects/companyProjects";
import ClientsFeedBack from "./SubComponents/ClientsFeedBack/clientsFeedBack";
import WhatsAppWidget from "react-whatsapp-chat-widget";
import Loader from "../Loader/loader";
import Error from "../ErrorPages/error";
import "react-whatsapp-chat-widget/index.css";
// import { selectedCompany } from "../../dummy";
//----------------------------------------------------------------------------------------------//
const CompanyProfilePage = () => {
  const dispatch = useDispatch();
  //-----------------------------------------------------------------------------------//
  const params = useParams();
  //----------------------------------------------------------------------------------//
  const english = useSelector((state) => state.english.active);
  //----------------------------------------------------------------------------------//
  const selectedCompany = useSelector((state) => state.selectedCompany.selectedCompany);
  //----------------------------------------------------------------------------------//
  const loading = useSelector((state) => state.selectedCompany.loading);
  //----------------------------------------------------------------------------------//
  const errorMessage = useSelector((state) => state.selectedCompany.errorMessage);
  //----------------------------------------------------------------------------------//
  function loadPage() {
    dispatch(getSingleCompany(params.id, english));
  }
  //----------------------------------------------------------------------------------//
  useEffect(() => {
    loadPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //----------------------------------------------------------------------------------//

  //--------------------------------------------------------------------------------------------//
  return (
    <Fragment>
      {selectedCompany.id && !loading && (
        <>
          <TopImage
            logo={selectedCompany.logo}
            companyName={english ? selectedCompany.name : selectedCompany.nameArabic}
            rating={selectedCompany.rating}
            projectNumbers={selectedCompany.projectNumbers}
          />
          <AboutSection
            image={selectedCompany.image}
            aboutFirstParagraph={
              english ? selectedCompany.aboutFirstParagraph : selectedCompany.aboutFirstParagraphArabic
            }
            aboutSecondParagraph={
              english ? selectedCompany.aboutSecondParagraph : selectedCompany.aboutSecondParagraphArabic
            }
          />
          <CompanyProjects projects={selectedCompany.companyProjects} />
          <ClientsFeedBack selectedCompany={selectedCompany} />
          <WhatsAppWidget
            phoneNo="+201009244362"
            position={english ? "right" : "left"}
            autoOpen={true}
            autoOpenTimer={5000}
            iconSize="60"
            iconColor="white"
            iconBgColor="#25D366"
            headerIcon={selectedCompany.logo}
            headerTitle={english ? "Alassema" : "العاصمة"}
            btnTxt={english ? "Start Chat" : "ابدأ الدردشة"}
            messageBoxTxt={
              english
                ? `I want to know more about ${selectedCompany.name}`
                : `أرجو معرفة المزيد عن ${selectedCompany.nameArabic}`
            }
            chatMessage={
              english ? (
                <>
                  Hi there 👋 <br />
                  <br /> How can I help you?
                </>
              ) : (
                <>
                  مرحبا 👋 <br />
                  <br />
                  كيف تريد ان نساعدك؟
                </>
              )
            }
            messageBox={false}
          />
        </>
      )}
      {loading && <Loader />}
      {!loading && !selectedCompany.id && <Error message={errorMessage} />}
    </Fragment>
  );
};

export default CompanyProfilePage;
