let defaultState = {
  searchResults: [],
  filters: false,
  loading: false, //This is loader for filter section only
};

const searchResults = (state = defaultState, action) => {
  if (action.type === "SET_SEARCH_RESULTS") {
    return {
      ...state,
      searchResults: action.payload,
      filters: false,
    };
  } else if (action.type === "SET_FILTER_RESULTS") {
    return {
      ...state,
      searchResults: action.payload,
      filters: true,
    };
  } else if (action.type === "SET_FILTER_LOADER") {
    return {
      ...state,
      loading: action.payload,
    };
  } else {
    return { ...state };
  }
};

export default searchResults;
