let defaultState = {
  availableMalls: [],
};

const availableMalls = (state = defaultState, action) => {
  if (action.type === "UPDATE_HOME") {
    return {
      ...state,
      availableMalls: action.payload.mallsList,
    };
  } else if (action.type === "SET_SEARCH_AVAILABLE_MALLS") {
    return {
      ...state,
      availableMalls: action.payload,
    };
  } else {
    return { ...state };
  }
};

export default availableMalls;
