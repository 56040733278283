import React from "react";
import { useSelector } from "react-redux";
import "./contact.css";
//------------------------------------------------------------------------------------------------//
const Contact = () => {
  const english = useSelector((state) => state.english.active);
  //------------------------------------------------------------------------------------------------//
  return (
    <div className="custom-contact-wrapper">
      <div
        className={
          english ? "custom-contact-inner" : "custom-contact-inner-arabic"
        }
      >
        {/* --------------------Get In touch----------------------- */}
        <div
          className={
            english
              ? "custom-contact-left-wrapper"
              : "custom-contact-left-wrapper-arabic"
          }
        >
          <h2 className="text-darkblue text-bold mb-4 mt-5">
            {english ? "Get In Touch" : "اتصل بنا"}
          </h2>
          <p className="text-secondary mt-2">
            {english
              ? "We are here to help you find your dream property"
              : "نحن هنا لنساعدك الحصول على وحدتك التي تحلم بها"}
            <br></br>
            {english
              ? "Stay connected to explore our services"
              : "ابق متصلا لاكتشاف خدماتنا"}
          </p>
          {/* --------------Our Email-------------- */}
          <div className={english ? "contact-flex" : "contact-flex-arabic"}>
            <div className={english ? "me-4" : "ms-4"}>
              <h4 className="text-darkblue text-bold">@</h4>
            </div>
            <div>
              <h6 className="text-darkblue text-bold">
                {english ? "Our Email" : "البريد الالكتروني"}
              </h6>
              <p className="text-secondary mt-2">
                CapitalAgent.office@gmail.com
              </p>
            </div>
          </div>
          {/* ----------Office Location------------ */}
          <div className={english ? "contact-flex" : "contact-flex-arabic"}>
            <div className={english ? "me-4" : "ms-4"}>
              <h3 className="text-darkblue text-bold">
                <i class="fa fa-map-marker" aria-hidden="true"></i>
              </h3>
            </div>
            <div>
              <h6 className="text-darkblue text-bold">
                {english ? "Office Location" : "عنوان المكتب"}
              </h6>
              <p className="text-secondary mt-2">
                {english
                  ? "Industrial Zone Gate, Cairo Governorate"
                  : "بوابة المنطقة الصناعية، محافظة القاهرة"}
              </p>
            </div>
          </div>
          {/* -----------Phone Number-------------- */}
          <div className={english ? "contact-flex" : "contact-flex-arabic"}>
            <div className={english ? "me-4" : "ms-4"}>
              <h3 className="text-darkblue text-bold">
                <i class="fa fa-phone" aria-hidden="true"></i>
              </h3>
            </div>
            <div>
              <h6 className="text-darkblue text-bold">
                {english ? "Phone Number" : "رقم الهاتف"}
              </h6>
              <p className="text-secondary mt-2">
                {english ? "0249800003 / 01055030209" : "٠٢٤٨٠٠٠٠٣/٠١٠٥٥٠٣٠٢٠٩"}
              </p>
            </div>
          </div>
        </div>
        {/* --------------------Say something---------------------- */}
        <div
          className={
            english
              ? "custom-contact-right-wrapper"
              : "custom-contact-right-wrapper-arabic"
          }
        >
          <h2 className="text-darkblue text-bold mb-4 mt-5">
            {english ? "Say Something.." : "شاركنا رأيك"}
          </h2>
          <input
            type="text"
            placeholder={english ? "Full Name" : "الاسم كاملا"}
            maxLength="25"
            className={
              english
                ? "input-fields mb-1 pb-3"
                : "input-fields-arabic mb-1 pb-3"
            }
          ></input>
          <input
            type="text"
            placeholder={english ? "Enter Your Email" : "البريد الالكتروني"}
            className={
              english
                ? "input-fields mb-1 pb-3"
                : "input-fields-arabic mb-1 pb-3"
            }
          ></input>
          <textarea
            type="text"
            placeholder={english ? "Your Message Here" : "اترك رسالتك هنا"}
            maxLength="100"
            className={english ? "input-fields mb-1" : "input-fields-arabic mb-1"}
            rows={4}
          ></textarea>
          <div className="text-center">
            <button className="btn btn-primary mt-3">
              {english ? "Send Now" : "ارسال"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
