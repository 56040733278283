import React from "react";
import { useSelector } from "react-redux";
import "./searchPageImage.css";
const SearchPageImage = ({ image, imageHeader1, imageHeader2, imageText }) => {
  //------------------------------------------------------------------------------------------------//
  const english = useSelector((state) => state.english.active);
  //------------------------------------------------------------------------------------------------//
  return (
    <div className="custom-search-page-image-wrapper">
      <div className="custom-search-page-image-inner">
        <div className="custom-search-page-image-slide">
          <img src={image} className="" alt="..."></img>
          <div
            className={
              english
                ? "custom-search-page-image-text-wrapper text-light"
                : "custom-search-page-image-text-wrapper-arabic text-light"
            }
          >
            <p className="custom-search-page-image-header-text">
              {imageHeader1}
            </p>
            <p className="custom-search-page-image-header-text">
              {imageHeader2}
            </p>
            <p className="custom-search-page-image-body-text mb-1">
              {imageText}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchPageImage;
