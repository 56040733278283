import { toast } from "react-toastify";
import axios from "../axios.js";
//------------------------------//
const translations = {
  "No active account found with the given credentials": "المستخدم غير موجود",
  "This field may not be blank.": "بيانات غير كاملة، برجاء ملئ جميع الفراغات",
};
//------------------------------//
export function login(email, password, navigate, english) {
  return async (dispatch) => {
    try {
      dispatch({
        type: "SET_LOADING",
        payload: true,
      });
      const response = await axios.post(`/token/`, { email, password });
      dispatch({
        type: "SET_USER_LOGIN",
        payload: response.data,
      });

      navigate("/");
    } catch (error) {
      console.log(error);
      if (english) {
        toast.error(error?.response?.data["error"] || "failed to login");
      } else {
        toast.error(translations[error?.response?.data["error"]] || "خطأ فى تسجيل الدخول");
      }
      return;
    } finally {
      dispatch({
        type: "SET_LOADING",
        payload: false,
      });
    }
  };
}
