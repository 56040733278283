import hotel from "../assets/singleMallPage/hotel.jpg";
import commercial from "../assets/singleMallPage/commercial.jpg";
import medical from "../assets/singleMallPage/medical.jpg";
import administrative from "../assets/singleMallPage/administrative.jpg";

let defaultState = {
  selectedMall: {},
  loading: true,
  errorMessage: "",
};

const selectedMall = (state = defaultState, action) => {
  if (action.type === "SET_SELECTED_MALL") {
    if (action.payload.selectedMall) {
      let payload = action.payload.selectedMall;
      if (payload?.mallUnitsPrices?.length > 0) {
        payload.mallUnitsPrices.forEach((item) => (item.image = ""));
        // eslint-disable-next-line array-callback-return
        payload.mallUnitsPrices.map((item) => {
          if (item.tag === "Commercial") {
            item.image = commercial;
          } else if (item.tag === "Medical") {
            item.image = medical;
          } else if (item.tag === "Hotel") {
            item.image = hotel;
          } else if (item.tag === "Administrative") {
            item.image = administrative;
          }
        });
      }
      return {
        ...state,
        selectedMall: action.payload.selectedMall,
        loading: action.payload.loading,
        errorMessage: action.payload.errorMessage,
      };
    }
  } else {
    return { ...state };
  }
};

export default selectedMall;
