let defaultState = {
  availableLocations: [],
};

const availableLocations = (state = defaultState, action) => {
  if (action.type === "SET_SEARCH_AVAILABLE_LOCATIONS") {
    return {
      ...state,
      availableLocations: action.payload,
    };
  } else {
    return { ...state };
  }
};

export default availableLocations;
