import React from "react";
import MultiRangeSlider from "multi-range-slider-react";
import "./slider.css";
//----------------------------------------------------------------------------------------------//
const Slider = ({ sliderLabel, labelArray, leastValue, highestValue, step, minValue, maxValue, handleInput }) => {
  //--------------------------------------------------------------------------------------------//
  return (
    <div className="label-slider-wrapper">
      <h6 className="text-darkblue text-bold">{sliderLabel}</h6>
      <div className="slider-wrapper">
        <MultiRangeSlider
          min={leastValue}
          max={highestValue}
          step={step}
          minValue={minValue}
          maxValue={maxValue}
          stepOnly={true}
          className="slider"
          label="true"
          labels={labelArray}
          ruler="false"
          barInnerColor="#007bff"
          thumbLeftColor="#007bff"
          thumbRightColor="#007bff"
          barLeftColor="rgb(240,240,240)"
          barRightColor="rgb(240,240,240)"
          onInput={(e) => {
            handleInput(e);
          }}
        ></MultiRangeSlider>
      </div>
    </div>
  );
};

export default Slider;
