import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { companyData } from "../../dummy";
import { ToastContainer } from "react-toastify";
import { Outlet, ScrollRestoration, useLocation } from "react-router-dom";
import { getAccessToken } from "../../actions/getAccessToken";
import NavBar from "../navBar/navBar";
import Footer from "../footer/footer";
import WhatsAppWidget from "react-whatsapp-chat-widget";
import { getUserFromAccessToken } from "../../actions/getUserFromAccessToken";
//----------------------------------------------------------------------------------------------//
const Root = () => {
  const dispatch = useDispatch();
  //--------------------------------------------------------------------------------------------//
  const location = useLocation();
  //--------------------------------------------------------------------------------------------//
  const english = useSelector((state) => state.english.active);
  //--------------------------------------------------------------------------------------------//
  function renderGeneralWhatsappIcon() {
    if (location.pathname.includes("/mall")) return false;
    else if (location.pathname.includes("/company")) return false;
    else if (location.pathname.includes("/compound")) return false;
    else if (location.pathname.includes("/location")) return false;
    else if (location.pathname.includes("/me")) return false;
    else if (location.pathname.includes("/signup")) return false;
    else if (location.pathname.includes("/signin")) return false;
    else return true;
  }
  //--------------------------------------------------------------------------------------------//
  useEffect(() => {
    async function getUser() {
      dispatch({ type: "SET_USER_LOADER", payload: true });
      const response = await dispatch(getAccessToken(refresh, english));
      if (response?.data?.access) {
        await dispatch(getUserFromAccessToken(response.data.access));
      }
      dispatch({ type: "SET_USER_LOADER", payload: false });
    }
    const refresh = localStorage.getItem("capitalRefreshToken");
    if (refresh) {
      getUser();
    } else {
      dispatch({ type: "SET_USER_LOADER", payload: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //--------------------------------------------------------------------------------------------//
  return (
    <Fragment>
      <ScrollRestoration />
      <NavBar />
      <body>
        <Outlet />
      </body>
      <Footer />
      {renderGeneralWhatsappIcon() && (
        <WhatsAppWidget
          phoneNo="+201009244362"
          position={english ? "right" : "left"}
          autoOpen={true}
          autoOpenTimer={3000}
          iconSize="60"
          iconColor="white"
          iconBgColor="#25D366"
          headerIcon={companyData.logo}
          headerTitle={english ? "Alassema" : "العاصمة"}
          btnTxt={english ? "Start Chat" : "ابدأ الدردشة"}
          messageBoxTxt={
            english ? `I want to know more about Alassema services` : `أرجو معرفة المزيد عن خدمات شركة العاصمة`
          }
          chatMessage={
            english ? (
              <>
                Hi there 👋 <br />
                <br /> How can I help you?
              </>
            ) : (
              <>
                مرحبا 👋 <br />
                <br />
                كيف تريد ان نساعدك؟
              </>
            )
          }
          messageBox={false}
        />
      )}
      <ToastContainer rtl />
    </Fragment>
  );
};

export default Root;
