import React from "react";
import app from "../../../../assets/general/app.jpg";
import "./mobApp.css";
const mobApp = () => {
  return (
    <div className="custom-mob-app-wrapper">
      <div className="custom-mob-app">
        <img src={app} alt=""></img>
      </div>
    </div>
  );
};

export default mobApp;
