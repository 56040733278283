import Spinner from "react-bootstrap/Spinner";
import { useSelector } from "react-redux";
function Loader() {
  //--------------------------------------------------------------------------------------------//
  const english = useSelector((state) => state.english.active);

  return (
    <div
      className="position-fixed top-0 start-0 d-flex flex-column justify-content-center align-items-center"
      style={{
        backgroundColor: "rgba(100, 100, 100, 0.500)",
        width: "100%",
        height: "100%",
      }}
    >
      <Spinner animation="border" role="status" variant="white">
        <span className="visually-hidden">
          {english ? "Loading" : "جاري التحميل"}
        </span>
      </Spinner>
      <h3 className="mt-2 text-white">
        {english ? "Loading" : "جاري التحميل"}
      </h3>
    </div>
  );
}

export default Loader;
