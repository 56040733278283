import React from "react";
import { useSelector } from "react-redux";
import CardItem from "../../../CardItem/cardItem";
import "./mallsSection.css";
//----------------------------------------------------------------------------------------------//
const MallsSection = ({ availableMalls }) => {
  //--------------------------------------------------------------------------------------------//
  const english = useSelector((state) => state.english.active);
  //--------------------------------------------------------------------------------------------//
  return (
    <div className="custom-malls-wrapper">
      <div className={english ? "custom-malls" : "custom-malls-arabic"}>
        {/* -------------------------------- */}
        {/* -------------------------------- */}
        {english && (
          <h2 className="text-darkblue text-bold mb-4 mt-5">
            Discover The Best<br></br>
            Malls Based On Highly Rating
          </h2>
        )}
        {/* -------------------------------- */}
        {/* -------------------------------- */}
        {!english && (
          <h2 className="text-darkblue text-bold mb-4 mt-5">
            أكتشف افضل المولات<br></br>
            من حيث التقييم
          </h2>
        )}
        {/* -------------------------------- */}
        {/* -------------------------------- */}
        {availableMalls.length > 0 && (
          <div
            className={
              english
                ? "custom-malls-container"
                : "custom-malls-container-arabic"
            }
          >
            {availableMalls.map((element) => (
              <CardItem
                key={element.id}
                id={element.id}
                name={element.name}
                nameArabic={element.nameArabic}
                projectImage={element.projectImage}
                buildingFloors={element.buildingFloors}
                tags={element.tags}
                address={element.address}
                addressArabic={element.addressArabic}
                distance={element.distance}
                type="mall"
                buttonText={english ? "View Details" : "اعرض التفاصيل"}
                viewDetails="false"
              />
            ))}
          </div>
        )}
        {/* -------------------------------- */}
        {/* -------------------------------- */}
        {availableMalls.length === 0 && (
          <h3 className="text-darkblue text-center">
            {english ? "No Malls found" : "لا يوجد مولات"}
          </h3>
        )}
        {/* -------------------------------- */}
        {/* -------------------------------- */}
        {availableMalls.length > 0 && (
          <div className="text-center m-4">
            <button
              className={
                english
                  ? "btn btn-primary btn-lg show-more-button"
                  : "btn btn-primary btn-lg show-more-button-arabic"
              }
            >
              {english ? "Show More" : "اعرض المزيد"}
              <i className="fa fa-angle-double-down angle-double-text m-2"></i>
            </button>
          </div>
        )}
        {/* -------------------------------- */}
        {/* -------------------------------- */}
      </div>
    </div>
  );
};

export default MallsSection;
