import React, { Fragment, useEffect } from "react";
import Carousel from "./subComponents/carousel/carousel";
import InvestmentProperties from "./subComponents/investmentProperties/investmentProperties";
import ResidentialProperties from "./subComponents/residentialProperties/residentialProperties";
import MallsSection from "./subComponents/mallsSection/mallsSection";
import LatestProjects from "./subComponents/latestProjects/latestProjects";
import MobApp from "./subComponents/mobApp/mobApp";
import ClientReviews from "./subComponents/clientReviews/clientReviews";
import Contact from "./subComponents/contact/contact";
import { home } from "../../actions/home";
import { useDispatch, useSelector } from "react-redux";
//------------------------------------------------------------------------------------//
//------------------------------------------------------------------------------------//
const Home = () => {
  //----------------------------------------------------------------------------------//
  const dispatch = useDispatch();
  //----------------------------------------------------------------------------------//
  const availableMalls = useSelector(
    (state) => state.availableMalls.availableMalls
  );
  //----------------------------------------------------------------------------------//
  const latestProjects = useSelector(
    (state) => state.latestProjects.latestProjects
  );
  //----------------------------------------------------------------------------------//
  const bestClientReviews = useSelector(
    (state) => state.bestClientReviews.bestClientReviews
  );
  //----------------------------------------------------------------------------------//
  function loadPage() {
    dispatch(home());
  }
  //----------------------------------------------------------------------------------//
  useEffect(() => {
    //TODO: fetch home
    //if everythings is ok then dispatch loading:false,home:res.data
    //otherwise dispatch loading:false,home:{}
    loadPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Fragment>
      <Carousel />
      <InvestmentProperties />
      <ResidentialProperties />
      <MallsSection availableMalls={availableMalls} />
      <LatestProjects latestProjects={latestProjects} />
      <MobApp />
      <ClientReviews bestClientReviews={bestClientReviews} />
      <Contact />
    </Fragment>
  );
};

export default Home;
