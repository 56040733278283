import React, { Fragment } from "react";
import { useSelector } from "react-redux";
const RatingStars = ({ rating }) => {
  //------------------------------------------------------------------------------------------------//
  const english = useSelector((state) => state.english.active);
  //------------------------------------------------------------------------------------------------//
  return (
    <Fragment>
      <i
        className={rating >= 1 ? "fa fa-star text-warning me-1" : rating > 0 ? "fa fa-star-half-o text-warning me-1" : "fa fa-star-o me-1"}
        aria-hidden="true"
      ></i>
      <i
        className={rating >= 2 ? "fa fa-star text-warning me-1" : rating > 1 ? "fa fa-star-half-o text-warning me-1" : "fa fa-star-o me-1"}
        aria-hidden="true"
      ></i>
      <i
        className={rating >= 3 ? "fa fa-star text-warning me-1" : rating > 2 ? "fa fa-star-half-o text-warning me-1" : "fa fa-star-o me-1"}
        aria-hidden="true"
      ></i>
      <i
        className={rating >= 4 ? "fa fa-star text-warning me-1" : rating > 3 ? "fa fa-star-half-o text-warning me-1" : "fa fa-star-o me-1"}
        aria-hidden="true"
      ></i>
      <i
        className={rating >= 5 ? "fa fa-star text-warning me-1" : rating > 4 ? "fa fa-star-half-o text-warning me-1" : "fa fa-star-o me-1"}
        aria-hidden="true"
      ></i>{" "}
      {english ? rating : rating?.toLocaleString("ar-u-nu-arab")}
    </Fragment>
  );
};

export default RatingStars;
