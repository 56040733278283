import axios from "../axios.js";
import { toast } from "react-toastify";
//------------------------------//
export function getUserFromAccessToken(access) {
  return async (dispatch) => {
    try {
      const response = await axios.get(`/users/data/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access}`,
        },
      });
      const token = localStorage.getItem("capitalAccessToken");
      if (token) {
        dispatch({ type: "RENEW_ACCESS_TOKEN", payload: token });
        localStorage.removeItem("capitalAccessToken");
      }
      dispatch({
        type: "GET_USER_FROM_ACCESS_TOKEN",
        payload: response.data,
      });
    } catch (error) {
      console.log(error);
      if (error?.response?.data) toast.error(error?.response?.data?.message);
    }
  };
}
