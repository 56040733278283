import React, { Fragment, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Tab, Tabs } from "react-bootstrap";
import PersonalDetailsTab from "./SubComponents/personalDetailsTab/personalDetailsTab";
import Preferences from "./SubComponents/preferences/preferences";
import Security from "./SubComponents/security/security";
import profile from "../../assets/profile/profile.jpg";
import { toast } from "react-toastify";
import "./profile.css";
//-----------------------------------------------------------------------------------//
const Profile = () => {
  const navigate = useNavigate();
  //---------------------------------------------------------------//
  const [activeKey, setActiveKey] = useState("Personal Details");
  //---------------------------------------------------------------//
  const [editingName, setEditingName] = useState(false);
  //---------------------------------------------------------------//
  const [editingEmail, setEditingEmail] = useState(false);
  //---------------------------------------------------------------//
  const [editingPhone, setEditingPhone] = useState(false);
  //---------------------------------------------------------------//
  const [editingGender, setEditingGender] = useState(false);
  //---------------------------------------------------------------//
  const [editingCurrency, setEditingCurrency] = useState(false);
  //---------------------------------------------------------------//
  const [editingLanguage, setEditingLanguage] = useState(false);
  //---------------------------------------------------------------//
  const [editingAccessibility, setEditingAccessibility] = useState(false);
  //---------------------------------------------------------------//
  const [editingPassword, setEditingPassword] = useState(false);
  //---------------------------------------------------------------//
  const [deletingAccount, setDeletingAccount] = useState(false);
  //---------------------------------------------------------------//
  const english = useSelector((state) => state.english.active);
  //---------------------------------------------------------------//
  const user = useSelector((state) => state.user.user);
  //---------------------------------------------------------------//
  useEffect(() => {
    if (!user.id) {
      toast.warning("You must login first");
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //---------------------------------------------------------------//
  return (
    <Fragment>
      <div className="personal-details-section-wrapper mt-5">
        <div
          className={
            english
              ? "personal-details-section-inner"
              : "personal-details-section-inner-arabic"
          }
        >
          <div className="personal-details-left">
            <Tabs
              activeKey={activeKey}
              className={
                english
                  ? "nav-profile mb-2 text-center custom-tabs-wrapper"
                  : "nav-profile mb-2 text-center custom-tabs-wrapper-arabic"
              }
              onSelect={(k) => {
                setDeletingAccount(false);
                setEditingPassword(false);
                setEditingAccessibility(false);
                setEditingCurrency(false);
                setEditingLanguage(false);
                setEditingName(false);
                setEditingPassword(false);
                setEditingEmail(false);
                setEditingGender(false);
                setEditingPhone(false);
                setActiveKey(k);
              }}
            >
              <Tab
                eventKey="Personal Details"
                title={
                  <span>
                    {english && (
                      <>
                        {" "}
                        <i className="fa fa-user me-1"></i>Personal Details
                      </>
                    )}
                    {!english && (
                      <>
                        البيانات الشخصية
                        <i className="fa fa-user ms-1"></i>
                      </>
                    )}
                  </span>
                }
              >
                <PersonalDetailsTab
                  loggedUserData={user}
                  editingName={editingName}
                  setEditingName={setEditingName}
                  editingEmail={editingEmail}
                  setEditingEmail={setEditingEmail}
                  editingGender={editingGender}
                  setEditingGender={setEditingGender}
                  editingPhone={editingPhone}
                  setEditingPhone={setEditingPhone}
                ></PersonalDetailsTab>
              </Tab>
              <Tab
                eventKey="Preferences"
                title={
                  <span>
                    {english && (
                      <>
                        <i className="fa fa-cog me-1"></i>Preferences
                      </>
                    )}
                    {!english && (
                      <>
                        التفضيلات
                        <i className="fa fa-cog ms-1"></i>
                      </>
                    )}
                  </span>
                }
              >
                <Preferences
                  loggedUserData={user}
                  editingCurrency={editingCurrency}
                  setEditingCurrency={setEditingCurrency}
                  editingLanguage={editingLanguage}
                  setEditingLanguage={setEditingLanguage}
                  editingAccessibility={editingAccessibility}
                  setEditingAccessibility={setEditingAccessibility}
                ></Preferences>
              </Tab>
              <Tab
                eventKey="Security"
                title={
                  <span>
                    {english && (
                      <>
                        <i className="fa fa-lock me-1"></i>Security
                      </>
                    )}
                    {!english && (
                      <>
                        الأمن
                        <i className="fa fa-lock ms-1"></i>
                      </>
                    )}
                  </span>
                }
              >
                <Security
                  loggedUserData={user}
                  editingPassword={editingPassword}
                  setEditingPassword={setEditingPassword}
                  deletingAccount={deletingAccount}
                  setDeletingAccount={setDeletingAccount}
                ></Security>
              </Tab>
            </Tabs>
          </div>
          <div className="personal-details-right">
            <img src={profile} alt="profile"></img>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default Profile;
